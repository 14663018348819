/* tslint:disable */
/* eslint-disable */
/**
 * Games Api v2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32
 */
export interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32 {
    /**
     * 
     * @type {string}
     * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32
     */
    SortOrder?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32SortOrderEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32
     */
    PagingDirection?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32PagingDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32
     */
    Count?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32SortOrderEnum {
    Asc = 'Asc',
    Desc = 'Desc'
}
/**
    * @export
    * @enum {string}
    */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32PagingDirectionEnum {
    Forward = 'Forward',
    Backward = 'Backward'
}

/**
 * 
 * @export
 * @interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
 */
export interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64 {
    /**
     * 
     * @type {string}
     * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
     */
    SortOrder?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64SortOrderEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
     */
    PagingDirection?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64PagingDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
     */
    Count?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64SortOrderEnum {
    Asc = 'Asc',
    Desc = 'Desc'
}
/**
    * @export
    * @enum {string}
    */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64PagingDirectionEnum {
    Forward = 'Forward',
    Backward = 'Backward'
}

/**
 * 
 * @export
 * @interface RobloxWebResponsesGamesGameMediaItemResponseV2
 */
export interface RobloxWebResponsesGamesGameMediaItemResponseV2 {
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesGamesGameMediaItemResponseV2
     */
    assetTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesGamesGameMediaItemResponseV2
     */
    assetType?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesGamesGameMediaItemResponseV2
     */
    imageId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesGamesGameMediaItemResponseV2
     */
    videoHash?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesGamesGameMediaItemResponseV2
     */
    videoTitle?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxWebResponsesGamesGameMediaItemResponseV2
     */
    approved?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxWebResponsesGamesGameResponseV2
 */
export interface RobloxWebResponsesGamesGameResponseV2 {
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesGamesGameResponseV2
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesGamesGameResponseV2
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesGamesGameResponseV2
     */
    description?: string;
    /**
     * 
     * @type {RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformCoreCreatorType}
     * @memberof RobloxWebResponsesGamesGameResponseV2
     */
    creator?: RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformCoreCreatorType;
    /**
     * 
     * @type {RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformAssetsAssetType}
     * @memberof RobloxWebResponsesGamesGameResponseV2
     */
    rootPlace?: RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformAssetsAssetType;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesGamesGameResponseV2
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesGamesGameResponseV2
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesGamesGameResponseV2
     */
    placeVisits?: number;
}
/**
 * 
 * @export
 * @interface RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformAssetsAssetType
 */
export interface RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformAssetsAssetType {
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformAssetsAssetType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformAssetsAssetType
     */
    type?: RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformAssetsAssetTypeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformAssetsAssetType
     */
    name?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformAssetsAssetTypeTypeEnum {
    Image = 'Image',
    TShirt = 'TShirt',
    Audio = 'Audio',
    Mesh = 'Mesh',
    Lua = 'Lua',
    Html = 'HTML',
    Text = 'Text',
    Hat = 'Hat',
    Place = 'Place',
    Model = 'Model',
    Shirt = 'Shirt',
    Pants = 'Pants',
    Decal = 'Decal',
    Avatar = 'Avatar',
    Head = 'Head',
    Face = 'Face',
    Gear = 'Gear',
    Badge = 'Badge',
    GroupEmblem = 'GroupEmblem',
    Animation = 'Animation',
    Arms = 'Arms',
    Legs = 'Legs',
    Torso = 'Torso',
    RightArm = 'RightArm',
    LeftArm = 'LeftArm',
    LeftLeg = 'LeftLeg',
    RightLeg = 'RightLeg',
    Package = 'Package',
    YouTubeVideo = 'YouTubeVideo',
    GamePass = 'GamePass',
    App = 'App',
    Code = 'Code',
    Plugin = 'Plugin',
    SolidModel = 'SolidModel',
    MeshPart = 'MeshPart',
    HairAccessory = 'HairAccessory',
    FaceAccessory = 'FaceAccessory',
    NeckAccessory = 'NeckAccessory',
    ShoulderAccessory = 'ShoulderAccessory',
    FrontAccessory = 'FrontAccessory',
    BackAccessory = 'BackAccessory',
    WaistAccessory = 'WaistAccessory',
    ClimbAnimation = 'ClimbAnimation',
    DeathAnimation = 'DeathAnimation',
    FallAnimation = 'FallAnimation',
    IdleAnimation = 'IdleAnimation',
    JumpAnimation = 'JumpAnimation',
    RunAnimation = 'RunAnimation',
    SwimAnimation = 'SwimAnimation',
    WalkAnimation = 'WalkAnimation',
    PoseAnimation = 'PoseAnimation',
    LocalizationTableManifest = 'LocalizationTableManifest',
    LocalizationTableTranslation = 'LocalizationTableTranslation',
    EmoteAnimation = 'EmoteAnimation',
    Video = 'Video',
    TexturePack = 'TexturePack'
}

/**
 * 
 * @export
 * @interface RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformCoreCreatorType
 */
export interface RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformCoreCreatorType {
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformCoreCreatorType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformCoreCreatorType
     */
    type?: RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformCoreCreatorTypeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformCoreCreatorType
     */
    name?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxWebResponsesRelatedEntityTypeResponseRobloxPlatformCoreCreatorTypeTypeEnum {
    User = 'User',
    Group = 'Group'
}

/**
 * 
 * @export
 * @interface RobloxWebWebAPIExclusiveStartRequestSystemInt32
 */
export interface RobloxWebWebAPIExclusiveStartRequestSystemInt32 {
    /**
     * 
     * @type {RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32}
     * @memberof RobloxWebWebAPIExclusiveStartRequestSystemInt32
     */
    ExclusiveStartKeyInfo?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt32;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIExclusiveStartRequestSystemInt32
     */
    CursorRecipe?: string;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIExclusiveStartRequestSystemInt64
 */
export interface RobloxWebWebAPIExclusiveStartRequestSystemInt64 {
    /**
     * 
     * @type {RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64}
     * @memberof RobloxWebWebAPIExclusiveStartRequestSystemInt64
     */
    ExclusiveStartKeyInfo?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIExclusiveStartRequestSystemInt64
     */
    CursorRecipe?: string;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesGamesGameMediaItemResponseV2
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesGamesGameMediaItemResponseV2 {
    /**
     * 
     * @type {Array<RobloxWebResponsesGamesGameMediaItemResponseV2>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesGamesGameMediaItemResponseV2
     */
    data?: Array<RobloxWebResponsesGamesGameMediaItemResponseV2>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2
 */
export interface RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2 {
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2
     */
    previousPageCursor?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2
     */
    nextPageCursor?: string;
    /**
     * 
     * @type {Array<RobloxWebResponsesGamesGameResponseV2>}
     * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2
     */
    data?: Array<RobloxWebResponsesGamesGameResponseV2>;
}

/**
 * GamesApi - axios parameter creator
 * @export
 */
export const GamesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the game media data
         * @param {number} universeId The id of the universe we get media data from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GamesUniverseIdMediaGet: async (universeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'universeId' is not null or undefined
            assertParamExists('v2GamesUniverseIdMediaGet', 'universeId', universeId)
            const localVarPath = `/v2/games/{universeId}/media`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets games created by the specified group.
         * @param {number} groupId The group Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupsGroupIdGamesGet: async (groupId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('v2GroupsGroupIdGamesGet', 'groupId', groupId)
            const localVarPath = `/v2/groups/{groupId}/games`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessFilter !== undefined) {
                localVarQueryParameter['accessFilter'] = accessFilter;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets games created by the specified group. This will replace V1 after validation. Note that this can only used for public/all universes.
         * @param {number} groupId The group Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupsGroupIdGamesV2Get: async (groupId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('v2GroupsGroupIdGamesV2Get', 'groupId', groupId)
            const localVarPath = `/v2/groups/{groupId}/gamesV2`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessFilter !== undefined) {
                localVarQueryParameter['accessFilter'] = accessFilter;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets users favorite games.
         * @param {number} userId The user Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersUserIdFavoriteGamesGet: async (userId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50, cursor?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v2UsersUserIdFavoriteGamesGet', 'userId', userId)
            const localVarPath = `/v2/users/{userId}/favorite/games`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessFilter !== undefined) {
                localVarQueryParameter['accessFilter'] = accessFilter;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets games created by the specified user.
         * @param {number} userId The user Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersUserIdGamesGet: async (userId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50, cursor?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('v2UsersUserIdGamesGet', 'userId', userId)
            const localVarPath = `/v2/users/{userId}/games`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessFilter !== undefined) {
                localVarQueryParameter['accessFilter'] = accessFilter;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GamesApi - functional programming interface
 * @export
 */
export const GamesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GamesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the game media data
         * @param {number} universeId The id of the universe we get media data from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GamesUniverseIdMediaGet(universeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesGamesGameMediaItemResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GamesUniverseIdMediaGet(universeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets games created by the specified group.
         * @param {number} groupId The group Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GroupsGroupIdGamesGet(groupId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GroupsGroupIdGamesGet(groupId, accessFilter, sortOrder, limit, cursor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets games created by the specified group. This will replace V1 after validation. Note that this can only used for public/all universes.
         * @param {number} groupId The group Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2GroupsGroupIdGamesV2Get(groupId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2GroupsGroupIdGamesV2Get(groupId, accessFilter, sortOrder, limit, cursor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets users favorite games.
         * @param {number} userId The user Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersUserIdFavoriteGamesGet(userId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50, cursor?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2UsersUserIdFavoriteGamesGet(userId, accessFilter, sortOrder, limit, cursor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets games created by the specified user.
         * @param {number} userId The user Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UsersUserIdGamesGet(userId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50, cursor?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2UsersUserIdGamesGet(userId, accessFilter, sortOrder, limit, cursor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GamesApi - factory interface
 * @export
 */
export const GamesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GamesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the game media data
         * @param {number} universeId The id of the universe we get media data from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GamesUniverseIdMediaGet(universeId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesGamesGameMediaItemResponseV2> {
            return localVarFp.v2GamesUniverseIdMediaGet(universeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets games created by the specified group.
         * @param {number} groupId The group Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupsGroupIdGamesGet(groupId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2> {
            return localVarFp.v2GroupsGroupIdGamesGet(groupId, accessFilter, sortOrder, limit, cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets games created by the specified group. This will replace V1 after validation. Note that this can only used for public/all universes.
         * @param {number} groupId The group Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2GroupsGroupIdGamesV2Get(groupId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2> {
            return localVarFp.v2GroupsGroupIdGamesV2Get(groupId, accessFilter, sortOrder, limit, cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets users favorite games.
         * @param {number} userId The user Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersUserIdFavoriteGamesGet(userId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50, cursor?: string, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2> {
            return localVarFp.v2UsersUserIdFavoriteGamesGet(userId, accessFilter, sortOrder, limit, cursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets games created by the specified user.
         * @param {number} userId The user Id.
         * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UsersUserIdGamesGet(userId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50, cursor?: string, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2> {
            return localVarFp.v2UsersUserIdGamesGet(userId, accessFilter, sortOrder, limit, cursor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GamesApi - interface
 * @export
 * @interface GamesApi
 */
export interface GamesApiInterface {
    /**
     * 
     * @summary Get the game media data
     * @param {number} universeId The id of the universe we get media data from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v2GamesUniverseIdMediaGet(universeId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesGamesGameMediaItemResponseV2>;

    /**
     * 
     * @summary Gets games created by the specified group.
     * @param {number} groupId The group Id.
     * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v2GroupsGroupIdGamesGet(groupId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2>;

    /**
     * 
     * @summary Gets games created by the specified group. This will replace V1 after validation. Note that this can only used for public/all universes.
     * @param {number} groupId The group Id.
     * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v2GroupsGroupIdGamesV2Get(groupId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2>;

    /**
     * 
     * @summary Gets users favorite games.
     * @param {number} userId The user Id.
     * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v2UsersUserIdFavoriteGamesGet(userId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50, cursor?: string, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2>;

    /**
     * 
     * @summary Gets games created by the specified user.
     * @param {number} userId The user Id.
     * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v2UsersUserIdGamesGet(userId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50, cursor?: string, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameResponseV2>;

}

/**
 * GamesApi - object-oriented interface
 * @export
 * @class GamesApi
 * @extends {BaseAPI}
 */
export class GamesApi extends BaseAPI implements GamesApiInterface {
    /**
     * 
     * @summary Get the game media data
     * @param {number} universeId The id of the universe we get media data from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v2GamesUniverseIdMediaGet(universeId: number, options?: any) {
        return GamesApiFp(this.configuration).v2GamesUniverseIdMediaGet(universeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets games created by the specified group.
     * @param {number} groupId The group Id.
     * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v2GroupsGroupIdGamesGet(groupId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any) {
        return GamesApiFp(this.configuration).v2GroupsGroupIdGamesGet(groupId, accessFilter, sortOrder, limit, cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets games created by the specified group. This will replace V1 after validation. Note that this can only used for public/all universes.
     * @param {number} groupId The group Id.
     * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v2GroupsGroupIdGamesV2Get(groupId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any) {
        return GamesApiFp(this.configuration).v2GroupsGroupIdGamesV2Get(groupId, accessFilter, sortOrder, limit, cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets users favorite games.
     * @param {number} userId The user Id.
     * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v2UsersUserIdFavoriteGamesGet(userId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50, cursor?: string, options?: any) {
        return GamesApiFp(this.configuration).v2UsersUserIdFavoriteGamesGet(userId, accessFilter, sortOrder, limit, cursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets games created by the specified user.
     * @param {number} userId The user Id.
     * @param {'All' | 'Public' | 'Private'} [accessFilter] Filtering option via access level.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v2UsersUserIdGamesGet(userId: number, accessFilter?: 'All' | 'Public' | 'Private', sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50, cursor?: string, options?: any) {
        return GamesApiFp(this.configuration).v2UsersUserIdGamesGet(userId, accessFilter, sortOrder, limit, cursor, options).then((request) => request(this.axios, this.basePath));
    }
}


