/* tslint:disable */
/* eslint-disable */
/**
 * LocalizationTables Api v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface RobloxInGameContentTablesClientGameLocation
 */
export interface RobloxInGameContentTablesClientGameLocation {
    /**
     * 
     * @type {string}
     * @memberof RobloxInGameContentTablesClientGameLocation
     */
    path?: string;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiAutoScrapeEntry
 */
export interface RobloxLocalizationTablesApiAutoScrapeEntry {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiAutoScrapeEntry
     */
    context?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiAutoScrapeEntry
     */
    source?: string;
    /**
     * 
     * @type {RobloxLocalizationTablesApiAutoScrapeEntryMetadata}
     * @memberof RobloxLocalizationTablesApiAutoScrapeEntry
     */
    meta?: RobloxLocalizationTablesApiAutoScrapeEntryMetadata;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiAutoScrapeEntryMetadata
 */
export interface RobloxLocalizationTablesApiAutoScrapeEntryMetadata {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiAutoScrapeEntryMetadata
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxLocalizationTablesApiAutoScrapeEntryMetadata
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiAutoScrapeEntryMetadata
     */
    osPlatform?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiAutoScrapeEntryMetadata
     */
    sessionId?: string;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiCreateTableRequest
 */
export interface RobloxLocalizationTablesApiCreateTableRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiCreateTableRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiCreateTableRequest
     */
    ownerType?: RobloxLocalizationTablesApiCreateTableRequestOwnerTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RobloxLocalizationTablesApiCreateTableRequest
     */
    ownerId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxLocalizationTablesApiCreateTableRequestOwnerTypeEnum {
    User = 'User',
    Group = 'Group'
}

/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiCreateTableResponse
 */
export interface RobloxLocalizationTablesApiCreateTableResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiCreateTableResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxLocalizationTablesApiCreateTableResponse
     */
    assetId?: number;
}
/**
 * A model that contains an entry identifier and an associated cursor for paged lookups.
 * @export
 * @interface RobloxLocalizationTablesApiCursorEntryIdentifier
 */
export interface RobloxLocalizationTablesApiCursorEntryIdentifier {
    /**
     * The location to begin our query.
     * @type {string}
     * @memberof RobloxLocalizationTablesApiCursorEntryIdentifier
     */
    cursor?: string;
    /**
     * 
     * @type {RobloxLocalizationTablesApiEntryIdentifier}
     * @memberof RobloxLocalizationTablesApiCursorEntryIdentifier
     */
    identifier?: RobloxLocalizationTablesApiEntryIdentifier;
    /**
     * The translation history count to get.
     * @type {number}
     * @memberof RobloxLocalizationTablesApiCursorEntryIdentifier
     */
    count?: number;
    /**
     * In which order the data is sorted.
     * @type {string}
     * @memberof RobloxLocalizationTablesApiCursorEntryIdentifier
     */
    sortOrder?: RobloxLocalizationTablesApiCursorEntryIdentifierSortOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxLocalizationTablesApiCursorEntryIdentifierSortOrderEnum {
    Asc = 'Asc',
    Desc = 'Desc'
}

/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiEntry
 */
export interface RobloxLocalizationTablesApiEntry {
    /**
     * 
     * @type {RobloxLocalizationTablesApiEntryIdentifier}
     * @memberof RobloxLocalizationTablesApiEntry
     */
    identifier?: RobloxLocalizationTablesApiEntryIdentifier;
    /**
     * 
     * @type {RobloxLocalizationTablesApiEntryMetadata}
     * @memberof RobloxLocalizationTablesApiEntry
     */
    metadata?: RobloxLocalizationTablesApiEntryMetadata;
    /**
     * 
     * @type {Array<RobloxLocalizationTablesApiTranslation>}
     * @memberof RobloxLocalizationTablesApiEntry
     */
    translations?: Array<RobloxLocalizationTablesApiTranslation>;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiEntryIdentifier
 */
export interface RobloxLocalizationTablesApiEntryIdentifier {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiEntryIdentifier
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiEntryIdentifier
     */
    context?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiEntryIdentifier
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiEntryMetadata
 */
export interface RobloxLocalizationTablesApiEntryMetadata {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiEntryMetadata
     */
    example?: string;
    /**
     * 
     * @type {Array<RobloxInGameContentTablesClientGameLocation>}
     * @memberof RobloxLocalizationTablesApiEntryMetadata
     */
    gameLocations?: Array<RobloxInGameContentTablesClientGameLocation>;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiEntryOperationLimits
 */
export interface RobloxLocalizationTablesApiEntryOperationLimits {
    /**
     * Maximum character limit for entry context
     * @type {number}
     * @memberof RobloxLocalizationTablesApiEntryOperationLimits
     */
    maxContextLength?: number;
    /**
     * Maximum character limit for entry key
     * @type {number}
     * @memberof RobloxLocalizationTablesApiEntryOperationLimits
     */
    maxKeyLength?: number;
    /**
     * Maximum character limit for entry source text
     * @type {number}
     * @memberof RobloxLocalizationTablesApiEntryOperationLimits
     */
    maxSourceLength?: number;
    /**
     * Maximum character limit for entry example
     * @type {number}
     * @memberof RobloxLocalizationTablesApiEntryOperationLimits
     */
    maxExampleLength?: number;
    /**
     * Maximum character limit for game location path
     * @type {number}
     * @memberof RobloxLocalizationTablesApiEntryOperationLimits
     */
    maxGameLocationPathLength?: number;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiEntryTranslationHistoryPaged
 */
export interface RobloxLocalizationTablesApiEntryTranslationHistoryPaged {
    /**
     * 
     * @type {RobloxLocalizationTablesApiEntryIdentifier}
     * @memberof RobloxLocalizationTablesApiEntryTranslationHistoryPaged
     */
    identifier?: RobloxLocalizationTablesApiEntryIdentifier;
    /**
     * A batch of TranslationHistory for the given entry identifier.
     * @type {Array<RobloxLocalizationTablesApiTranslationHistory>}
     * @memberof RobloxLocalizationTablesApiEntryTranslationHistoryPaged
     */
    history?: Array<RobloxLocalizationTablesApiTranslationHistory>;
    /**
     * The cursor to send up on the next request if more history data is required.
     * @type {string}
     * @memberof RobloxLocalizationTablesApiEntryTranslationHistoryPaged
     */
    nextCursor?: string;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiError
 */
export interface RobloxLocalizationTablesApiError {
    /**
     * 
     * @type {number}
     * @memberof RobloxLocalizationTablesApiError
     */
    errorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiError
     */
    errorMessage?: string;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiFailedEntry
 */
export interface RobloxLocalizationTablesApiFailedEntry {
    /**
     * 
     * @type {RobloxLocalizationTablesApiError}
     * @memberof RobloxLocalizationTablesApiFailedEntry
     */
    error?: RobloxLocalizationTablesApiError;
    /**
     * 
     * @type {RobloxLocalizationTablesApiEntryIdentifier}
     * @memberof RobloxLocalizationTablesApiFailedEntry
     */
    identifier?: RobloxLocalizationTablesApiEntryIdentifier;
    /**
     * 
     * @type {RobloxLocalizationTablesApiEntryMetadata}
     * @memberof RobloxLocalizationTablesApiFailedEntry
     */
    metadata?: RobloxLocalizationTablesApiEntryMetadata;
    /**
     * 
     * @type {Array<RobloxLocalizationTablesApiTranslation>}
     * @memberof RobloxLocalizationTablesApiFailedEntry
     */
    translations?: Array<RobloxLocalizationTablesApiTranslation>;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiFailedEntryTranslationHistoryPaged
 */
export interface RobloxLocalizationTablesApiFailedEntryTranslationHistoryPaged {
    /**
     * 
     * @type {RobloxLocalizationTablesApiEntryIdentifier}
     * @memberof RobloxLocalizationTablesApiFailedEntryTranslationHistoryPaged
     */
    identifier?: RobloxLocalizationTablesApiEntryIdentifier;
    /**
     * 
     * @type {number}
     * @memberof RobloxLocalizationTablesApiFailedEntryTranslationHistoryPaged
     */
    count?: number;
    /**
     * 
     * @type {RobloxLocalizationTablesApiError}
     * @memberof RobloxLocalizationTablesApiFailedEntryTranslationHistoryPaged
     */
    error?: RobloxLocalizationTablesApiError;
}
/**
 * Response model for GetLanguageTranslationCountsForTable.
 * @export
 * @interface RobloxLocalizationTablesApiGetLanguageTranslationCountsForTableResponse
 */
export interface RobloxLocalizationTablesApiGetLanguageTranslationCountsForTableResponse {
    /**
     * Id of the table that the translation counts were retrieved for.
     * @type {string}
     * @memberof RobloxLocalizationTablesApiGetLanguageTranslationCountsForTableResponse
     */
    tableId?: string;
    /**
     * Id of the game that the translation counts were retrieved for.
     * @type {number}
     * @memberof RobloxLocalizationTablesApiGetLanguageTranslationCountsForTableResponse
     */
    gameId?: number;
    /**
     * The translation counts with relevant language information.
     * @type {Array<RobloxLocalizationTablesApiLanguageTranslationCount>}
     * @memberof RobloxLocalizationTablesApiGetLanguageTranslationCountsForTableResponse
     */
    languageTranslationCounts?: Array<RobloxLocalizationTablesApiLanguageTranslationCount>;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiGetLimitsResponse
 */
export interface RobloxLocalizationTablesApiGetLimitsResponse {
    /**
     * 
     * @type {RobloxLocalizationTablesApiEntryOperationLimits}
     * @memberof RobloxLocalizationTablesApiGetLimitsResponse
     */
    entryOperationLimits?: RobloxLocalizationTablesApiEntryOperationLimits;
    /**
     * 
     * @type {RobloxLocalizationTablesApiTableOperationLimits}
     * @memberof RobloxLocalizationTablesApiGetLimitsResponse
     */
    tableOperationLimits?: RobloxLocalizationTablesApiTableOperationLimits;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiGetTableEntriesPagedResponse
 */
export interface RobloxLocalizationTablesApiGetTableEntriesPagedResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiGetTableEntriesPagedResponse
     */
    previousPageCursor?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiGetTableEntriesPagedResponse
     */
    nextPageCursor?: string;
    /**
     * 
     * @type {Array<RobloxLocalizationTablesApiEntry>}
     * @memberof RobloxLocalizationTablesApiGetTableEntriesPagedResponse
     */
    data?: Array<RobloxLocalizationTablesApiEntry>;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest
 */
export interface RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest
     */
    locale?: string;
    /**
     * 
     * @type {Array<RobloxLocalizationTablesApiCursorEntryIdentifier>}
     * @memberof RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest
     */
    entries?: Array<RobloxLocalizationTablesApiCursorEntryIdentifier>;
}
/**
 * A response model for GetTableEntriesTranslationHistory.
 * @export
 * @interface RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryResponse
 */
export interface RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryResponse {
    /**
     * The entries\' tableId.
     * @type {string}
     * @memberof RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryResponse
     */
    tableId?: string;
    /**
     * The locale of the translations.
     * @type {string}
     * @memberof RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryResponse
     */
    locale?: string;
    /**
     * The entries with their identifier, translation history, and next cursor.
     * @type {Array<RobloxLocalizationTablesApiEntryTranslationHistoryPaged>}
     * @memberof RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryResponse
     */
    entries?: Array<RobloxLocalizationTablesApiEntryTranslationHistoryPaged>;
    /**
     * The failed entries.
     * @type {Array<RobloxLocalizationTablesApiFailedEntryTranslationHistoryPaged>}
     * @memberof RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryResponse
     */
    failedEntries?: Array<RobloxLocalizationTablesApiFailedEntryTranslationHistoryPaged>;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiGetTableEntryCountResponse
 */
export interface RobloxLocalizationTablesApiGetTableEntryCountResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiGetTableEntryCountResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxLocalizationTablesApiGetTableEntryCountResponse
     */
    entryCount?: number;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiGetTableResponse
 */
export interface RobloxLocalizationTablesApiGetTableResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiGetTableResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiGetTableResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiGetTableResponse
     */
    ownerType?: RobloxLocalizationTablesApiGetTableResponseOwnerTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RobloxLocalizationTablesApiGetTableResponse
     */
    ownerId?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxLocalizationTablesApiGetTableResponse
     */
    assetId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxLocalizationTablesApiGetTableResponseOwnerTypeEnum {
    User = 'User',
    Group = 'Group'
}

/**
 * An ingest content request to IngestAutoScrapedContentForGame.
 * @export
 * @interface RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest
 */
export interface RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest {
    /**
     * The entries of an ingest content request.
     * @type {Array<RobloxLocalizationTablesApiAutoScrapeEntry>}
     * @memberof RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest
     */
    entries?: Array<RobloxLocalizationTablesApiAutoScrapeEntry>;
    /**
     * 
     * @type {RobloxLocalizationTablesApiIngestContentMetadata}
     * @memberof RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest
     */
    metadata?: RobloxLocalizationTablesApiIngestContentMetadata;
}
/**
 * The metadata of an ingest content request.
 * @export
 * @interface RobloxLocalizationTablesApiIngestContentMetadata
 */
export interface RobloxLocalizationTablesApiIngestContentMetadata {
    /**
     * 
     * @type {RobloxLocalizationTablesApiIngestContentMetadataPlaceInformation}
     * @memberof RobloxLocalizationTablesApiIngestContentMetadata
     */
    placeInformation?: RobloxLocalizationTablesApiIngestContentMetadataPlaceInformation;
}
/**
 * The place information metadata of an ingest content request.
 * @export
 * @interface RobloxLocalizationTablesApiIngestContentMetadataPlaceInformation
 */
export interface RobloxLocalizationTablesApiIngestContentMetadataPlaceInformation {
    /**
     * The place id of an ingest content request.
     * @type {number}
     * @memberof RobloxLocalizationTablesApiIngestContentMetadataPlaceInformation
     */
    placeId?: number;
    /**
     * The place version number of an ingest content request.
     * @type {number}
     * @memberof RobloxLocalizationTablesApiIngestContentMetadataPlaceInformation
     */
    placeVersionNumber?: number;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiLanguage
 */
export interface RobloxLocalizationTablesApiLanguage {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiLanguage
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiLanguage
     */
    nativeName?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiLanguage
     */
    languageCode?: string;
}
/**
 * Model containing a translation count for a language and relevant language information.
 * @export
 * @interface RobloxLocalizationTablesApiLanguageTranslationCount
 */
export interface RobloxLocalizationTablesApiLanguageTranslationCount {
    /**
     * The locale of the language.
     * @type {string}
     * @memberof RobloxLocalizationTablesApiLanguageTranslationCount
     */
    locale?: string;
    /**
     * The translation count value.
     * @type {number}
     * @memberof RobloxLocalizationTablesApiLanguageTranslationCount
     */
    translationCount?: number;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiLocalizationTablesMetadataResponse
 */
export interface RobloxLocalizationTablesApiLocalizationTablesMetadataResponse {
    /**
     * Is bulk upload feature enabled
     * @type {boolean}
     * @memberof RobloxLocalizationTablesApiLocalizationTablesMetadataResponse
     */
    isBulkUploadFeatureEnabled?: boolean;
    /**
     * Is CSV download feature enabled
     * @type {boolean}
     * @memberof RobloxLocalizationTablesApiLocalizationTablesMetadataResponse
     */
    isCsvDownloadEnabled?: boolean;
    /**
     * Is access to translation metadata feature enabled
     * @type {boolean}
     * @memberof RobloxLocalizationTablesApiLocalizationTablesMetadataResponse
     */
    isAccessToTranslationMetaDataEnabled?: boolean;
    /**
     * Is access to translation metadata feature enabled
     * @type {boolean}
     * @memberof RobloxLocalizationTablesApiLocalizationTablesMetadataResponse
     */
    isTranslationManagementRedirectionEnabled?: boolean;
    /**
     * Is untranslated filter on UI is enabled
     * @type {boolean}
     * @memberof RobloxLocalizationTablesApiLocalizationTablesMetadataResponse
     */
    isUntranslatedFilterEnabled?: boolean;
    /**
     * Is filter for automatic translations on UI is enabled
     * @type {boolean}
     * @memberof RobloxLocalizationTablesApiLocalizationTablesMetadataResponse
     */
    isAutomaticTranslationFilterEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiPatchEntry
 */
export interface RobloxLocalizationTablesApiPatchEntry {
    /**
     * 
     * @type {RobloxLocalizationTablesApiEntryIdentifier}
     * @memberof RobloxLocalizationTablesApiPatchEntry
     */
    identifier?: RobloxLocalizationTablesApiEntryIdentifier;
    /**
     * 
     * @type {RobloxLocalizationTablesApiEntryMetadata}
     * @memberof RobloxLocalizationTablesApiPatchEntry
     */
    metadata?: RobloxLocalizationTablesApiEntryMetadata;
    /**
     * 
     * @type {Array<RobloxLocalizationTablesApiPatchTranslation>}
     * @memberof RobloxLocalizationTablesApiPatchEntry
     */
    translations?: Array<RobloxLocalizationTablesApiPatchTranslation>;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxLocalizationTablesApiPatchEntry
     */
    _delete?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiPatchTranslation
 */
export interface RobloxLocalizationTablesApiPatchTranslation {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiPatchTranslation
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiPatchTranslation
     */
    translationText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxLocalizationTablesApiPatchTranslation
     */
    _delete?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest
 */
export interface RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest {
    /**
     * The time range to remove entries from. Following ISO 8601 Durations format
     * @type {string}
     * @memberof RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest
     */
    maxAgeForFlush?: string;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiTableOperationLimits
 */
export interface RobloxLocalizationTablesApiTableOperationLimits {
    /**
     * Maximum number of entries for a patch request
     * @type {number}
     * @memberof RobloxLocalizationTablesApiTableOperationLimits
     */
    maxEntriesPerUpdate?: number;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiTranslation
 */
export interface RobloxLocalizationTablesApiTranslation {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiTranslation
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiTranslation
     */
    translationText?: string;
}
/**
 * A model containing a translation, translator, and translation time.
 * @export
 * @interface RobloxLocalizationTablesApiTranslationHistory
 */
export interface RobloxLocalizationTablesApiTranslationHistory {
    /**
     * The translation provided by the translator.
     * @type {string}
     * @memberof RobloxLocalizationTablesApiTranslationHistory
     */
    translationText?: string;
    /**
     * 
     * @type {RobloxLocalizationTablesApiTranslator}
     * @memberof RobloxLocalizationTablesApiTranslationHistory
     */
    translator?: RobloxLocalizationTablesApiTranslator;
    /**
     * The time the translation was provided.
     * @type {string}
     * @memberof RobloxLocalizationTablesApiTranslationHistory
     */
    created?: string;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiTranslator
 */
export interface RobloxLocalizationTablesApiTranslator {
    /**
     * 
     * @type {number}
     * @memberof RobloxLocalizationTablesApiTranslator
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiTranslator
     */
    agentType?: RobloxLocalizationTablesApiTranslatorAgentTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxLocalizationTablesApiTranslatorAgentTypeEnum {
    User = 'User',
    Automation = 'Automation'
}

/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiUpdateTableContentsRequest
 */
export interface RobloxLocalizationTablesApiUpdateTableContentsRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxLocalizationTablesApiUpdateTableContentsRequest
     */
    name?: string;
    /**
     * 
     * @type {Array<RobloxLocalizationTablesApiPatchEntry>}
     * @memberof RobloxLocalizationTablesApiUpdateTableContentsRequest
     */
    entries?: Array<RobloxLocalizationTablesApiPatchEntry>;
}
/**
 * 
 * @export
 * @interface RobloxLocalizationTablesApiUpdateTableContentsResponse
 */
export interface RobloxLocalizationTablesApiUpdateTableContentsResponse {
    /**
     * 
     * @type {Array<RobloxLocalizationTablesApiFailedEntry>}
     * @memberof RobloxLocalizationTablesApiUpdateTableContentsResponse
     */
    failedEntriesAndTranslations?: Array<RobloxLocalizationTablesApiFailedEntry>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxLocalizationTablesApiLanguage
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxLocalizationTablesApiLanguage {
    /**
     * 
     * @type {Array<RobloxLocalizationTablesApiLanguage>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxLocalizationTablesApiLanguage
     */
    data?: Array<RobloxLocalizationTablesApiLanguage>;
}

/**
 * AutoLocalizationTableApi - axios parameter creator
 * @export
 */
export const AutoLocalizationTableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generates localization asset of a game.
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutoLocalizationTableGamesGameIdAssetsGenerationRequestPost: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            assertParamExists('v1AutoLocalizationTableGamesGameIdAssetsGenerationRequestPost', 'gameId', gameId)
            const localVarPath = `/v1/auto-localization-table/games/{gameId}/assets-generation-request`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enqueues an event to flush the auto scraped entries which doesn\'t have translations.
         * @param {number} gameId The id of the game.
         * @param {RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutoLocalizationTableGamesGameIdAutoScrapeCleanupRequestPost: async (gameId: number, request: RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            assertParamExists('v1AutoLocalizationTableGamesGameIdAutoScrapeCleanupRequestPost', 'gameId', gameId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('v1AutoLocalizationTableGamesGameIdAutoScrapeCleanupRequestPost', 'request', request)
            const localVarPath = `/v1/auto-localization-table/games/{gameId}/auto-scrape-cleanup-request`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ingests entries for auto localization. Only available for game servers.
         * @param {number} gameId The game id.
         * @param {RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest} request The request body.
         * @param {string} [robloxGameId] The id of the game instance from RCC.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutoLocalizationTableGamesGameIdPatch: async (gameId: number, request: RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest, robloxGameId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            assertParamExists('v1AutoLocalizationTableGamesGameIdPatch', 'gameId', gameId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('v1AutoLocalizationTableGamesGameIdPatch', 'request', request)
            const localVarPath = `/v1/auto-localization-table/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robloxGameId !== undefined && robloxGameId !== null) {
                localVarHeaderParameter['Roblox-Game-Id'] = String(robloxGameId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutoLocalizationTableApi - functional programming interface
 * @export
 */
export const AutoLocalizationTableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AutoLocalizationTableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generates localization asset of a game.
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutoLocalizationTableGamesGameIdAssetsGenerationRequestPost(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AutoLocalizationTableGamesGameIdAssetsGenerationRequestPost(gameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enqueues an event to flush the auto scraped entries which doesn\'t have translations.
         * @param {number} gameId The id of the game.
         * @param {RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutoLocalizationTableGamesGameIdAutoScrapeCleanupRequestPost(gameId: number, request: RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AutoLocalizationTableGamesGameIdAutoScrapeCleanupRequestPost(gameId, request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ingests entries for auto localization. Only available for game servers.
         * @param {number} gameId The game id.
         * @param {RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest} request The request body.
         * @param {string} [robloxGameId] The id of the game instance from RCC.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutoLocalizationTableGamesGameIdPatch(gameId: number, request: RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest, robloxGameId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1AutoLocalizationTableGamesGameIdPatch(gameId, request, robloxGameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AutoLocalizationTableApi - factory interface
 * @export
 */
export const AutoLocalizationTableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AutoLocalizationTableApiFp(configuration)
    return {
        /**
         * 
         * @summary Generates localization asset of a game.
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutoLocalizationTableGamesGameIdAssetsGenerationRequestPost(gameId: number, options?: any): AxiosPromise<object> {
            return localVarFp.v1AutoLocalizationTableGamesGameIdAssetsGenerationRequestPost(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enqueues an event to flush the auto scraped entries which doesn\'t have translations.
         * @param {number} gameId The id of the game.
         * @param {RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutoLocalizationTableGamesGameIdAutoScrapeCleanupRequestPost(gameId: number, request: RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest, options?: any): AxiosPromise<object> {
            return localVarFp.v1AutoLocalizationTableGamesGameIdAutoScrapeCleanupRequestPost(gameId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ingests entries for auto localization. Only available for game servers.
         * @param {number} gameId The game id.
         * @param {RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest} request The request body.
         * @param {string} [robloxGameId] The id of the game instance from RCC.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutoLocalizationTableGamesGameIdPatch(gameId: number, request: RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest, robloxGameId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.v1AutoLocalizationTableGamesGameIdPatch(gameId, request, robloxGameId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutoLocalizationTableApi - interface
 * @export
 * @interface AutoLocalizationTableApi
 */
export interface AutoLocalizationTableApiInterface {
    /**
     * 
     * @summary Generates localization asset of a game.
     * @param {number} gameId The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoLocalizationTableApiInterface
     */
    v1AutoLocalizationTableGamesGameIdAssetsGenerationRequestPost(gameId: number, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Enqueues an event to flush the auto scraped entries which doesn\'t have translations.
     * @param {number} gameId The id of the game.
     * @param {RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoLocalizationTableApiInterface
     */
    v1AutoLocalizationTableGamesGameIdAutoScrapeCleanupRequestPost(gameId: number, request: RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Ingests entries for auto localization. Only available for game servers.
     * @param {number} gameId The game id.
     * @param {RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest} request The request body.
     * @param {string} [robloxGameId] The id of the game instance from RCC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoLocalizationTableApiInterface
     */
    v1AutoLocalizationTableGamesGameIdPatch(gameId: number, request: RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest, robloxGameId?: string, options?: any): AxiosPromise<object>;

}

/**
 * AutoLocalizationTableApi - object-oriented interface
 * @export
 * @class AutoLocalizationTableApi
 * @extends {BaseAPI}
 */
export class AutoLocalizationTableApi extends BaseAPI implements AutoLocalizationTableApiInterface {
    /**
     * 
     * @summary Generates localization asset of a game.
     * @param {number} gameId The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoLocalizationTableApi
     */
    public v1AutoLocalizationTableGamesGameIdAssetsGenerationRequestPost(gameId: number, options?: any) {
        return AutoLocalizationTableApiFp(this.configuration).v1AutoLocalizationTableGamesGameIdAssetsGenerationRequestPost(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enqueues an event to flush the auto scraped entries which doesn\'t have translations.
     * @param {number} gameId The id of the game.
     * @param {RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoLocalizationTableApi
     */
    public v1AutoLocalizationTableGamesGameIdAutoScrapeCleanupRequestPost(gameId: number, request: RobloxLocalizationTablesApiRaiseEventForAutoScrapedEntriesCleanupRequest, options?: any) {
        return AutoLocalizationTableApiFp(this.configuration).v1AutoLocalizationTableGamesGameIdAutoScrapeCleanupRequestPost(gameId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ingests entries for auto localization. Only available for game servers.
     * @param {number} gameId The game id.
     * @param {RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest} request The request body.
     * @param {string} [robloxGameId] The id of the game instance from RCC.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutoLocalizationTableApi
     */
    public v1AutoLocalizationTableGamesGameIdPatch(gameId: number, request: RobloxLocalizationTablesApiIngestAutoScrapedContentForGameRequest, robloxGameId?: string, options?: any) {
        return AutoLocalizationTableApiFp(this.configuration).v1AutoLocalizationTableGamesGameIdPatch(gameId, request, robloxGameId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocalizationTableApi - axios parameter creator
 * @export
 */
export const LocalizationTableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the supported language codes that can be used by localization tables.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableAvailableLanguagesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/localization-table/available-languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get limits for translation table entries operations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableLimitsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/localization-table/limits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metadata for localization UI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableMetadataGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/localization-table/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get table information by the assetId of the table.
         * @param {number} assetId The asset id associated with the table.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesAssetIdGet: async (assetId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('v1LocalizationTableTablesAssetIdGet', 'assetId', assetId)
            const localVarPath = `/v1/localization-table/tables/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Localization Table with the given data.
         * @param {RobloxLocalizationTablesApiCreateTableRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesPost: async (request: RobloxLocalizationTablesApiCreateTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('v1LocalizationTableTablesPost', 'request', request)
            const localVarPath = `/v1/localization-table/tables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a batch of entries for a table.
         * @param {string} tableId 
         * @param {string} [cursor] If null, there are no more entries in the table and you\&#39;ve reached the last page.
         * @param {number} [gameId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdEntriesGet: async (tableId: string, cursor?: string, gameId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('v1LocalizationTableTablesTableIdEntriesGet', 'tableId', tableId)
            const localVarPath = `/v1/localization-table/tables/{tableId}/entries`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (gameId !== undefined) {
                localVarQueryParameter['gameId'] = gameId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the translation history for each entry passed in.
         * @param {string} tableId The entries\&#39; tableId.
         * @param {RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest} request A request body containing all relevant data for entry history lookup.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdEntriesTranslationHistoryPost: async (tableId: string, request: RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest, gameId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('v1LocalizationTableTablesTableIdEntriesTranslationHistoryPost', 'tableId', tableId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('v1LocalizationTableTablesTableIdEntriesTranslationHistoryPost', 'request', request)
            const localVarPath = `/v1/localization-table/tables/{tableId}/entries/translation-history`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gameId !== undefined) {
                localVarQueryParameter['gameId'] = gameId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the number of entries in the specified table
         * @param {string} tableId The table id
         * @param {number} [gameId] The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdEntryCountGet: async (tableId: string, gameId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('v1LocalizationTableTablesTableIdEntryCountGet', 'tableId', tableId)
            const localVarPath = `/v1/localization-table/tables/{tableId}/entry-count`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gameId !== undefined) {
                localVarQueryParameter['gameId'] = gameId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get table information by the id of the table.
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdGet: async (tableId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('v1LocalizationTableTablesTableIdGet', 'tableId', tableId)
            const localVarPath = `/v1/localization-table/tables/{tableId}`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the language translation counts for the specified table.  The languages to retrieve must be provided.
         * @param {string} tableId The table id
         * @param {Array<string>} locales The locales corresponding to the language translation counts to retrieve. This will return 0 for the translation count of locales that do not exist for the given table.
         * @param {number} [gameId] The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdLanguageTranslationCountsGet: async (tableId: string, locales: Array<string>, gameId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('v1LocalizationTableTablesTableIdLanguageTranslationCountsGet', 'tableId', tableId)
            // verify required parameter 'locales' is not null or undefined
            assertParamExists('v1LocalizationTableTablesTableIdLanguageTranslationCountsGet', 'locales', locales)
            const localVarPath = `/v1/localization-table/tables/{tableId}/language-translation-counts`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (locales) {
                localVarQueryParameter['locales'] = locales;
            }

            if (gameId !== undefined) {
                localVarQueryParameter['gameId'] = gameId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the tables contents based on what is provided.
         * @param {string} tableId The table guid for the table to update.
         * @param {RobloxLocalizationTablesApiUpdateTableContentsRequest} request The metadata object is optional.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdPatch: async (tableId: string, request: RobloxLocalizationTablesApiUpdateTableContentsRequest, gameId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('v1LocalizationTableTablesTableIdPatch', 'tableId', tableId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('v1LocalizationTableTablesTableIdPatch', 'request', request)
            const localVarPath = `/v1/localization-table/tables/{tableId}`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gameId !== undefined) {
                localVarQueryParameter['gameId'] = gameId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalizationTableApi - functional programming interface
 * @export
 */
export const LocalizationTableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalizationTableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the supported language codes that can be used by localization tables.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableAvailableLanguagesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxLocalizationTablesApiLanguage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableAvailableLanguagesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get limits for translation table entries operations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableLimitsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxLocalizationTablesApiGetLimitsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableLimitsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get metadata for localization UI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableMetadataGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxLocalizationTablesApiLocalizationTablesMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableMetadataGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get table information by the assetId of the table.
         * @param {number} assetId The asset id associated with the table.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableTablesAssetIdGet(assetId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxLocalizationTablesApiGetTableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableTablesAssetIdGet(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Localization Table with the given data.
         * @param {RobloxLocalizationTablesApiCreateTableRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableTablesPost(request: RobloxLocalizationTablesApiCreateTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxLocalizationTablesApiCreateTableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableTablesPost(request, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a batch of entries for a table.
         * @param {string} tableId 
         * @param {string} [cursor] If null, there are no more entries in the table and you\&#39;ve reached the last page.
         * @param {number} [gameId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableTablesTableIdEntriesGet(tableId: string, cursor?: string, gameId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxLocalizationTablesApiGetTableEntriesPagedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableTablesTableIdEntriesGet(tableId, cursor, gameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the translation history for each entry passed in.
         * @param {string} tableId The entries\&#39; tableId.
         * @param {RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest} request A request body containing all relevant data for entry history lookup.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableTablesTableIdEntriesTranslationHistoryPost(tableId: string, request: RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest, gameId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableTablesTableIdEntriesTranslationHistoryPost(tableId, request, gameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the number of entries in the specified table
         * @param {string} tableId The table id
         * @param {number} [gameId] The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableTablesTableIdEntryCountGet(tableId: string, gameId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxLocalizationTablesApiGetTableEntryCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableTablesTableIdEntryCountGet(tableId, gameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get table information by the id of the table.
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableTablesTableIdGet(tableId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxLocalizationTablesApiGetTableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableTablesTableIdGet(tableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the language translation counts for the specified table.  The languages to retrieve must be provided.
         * @param {string} tableId The table id
         * @param {Array<string>} locales The locales corresponding to the language translation counts to retrieve. This will return 0 for the translation count of locales that do not exist for the given table.
         * @param {number} [gameId] The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableTablesTableIdLanguageTranslationCountsGet(tableId: string, locales: Array<string>, gameId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxLocalizationTablesApiGetLanguageTranslationCountsForTableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableTablesTableIdLanguageTranslationCountsGet(tableId, locales, gameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the tables contents based on what is provided.
         * @param {string} tableId The table guid for the table to update.
         * @param {RobloxLocalizationTablesApiUpdateTableContentsRequest} request The metadata object is optional.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationTableTablesTableIdPatch(tableId: string, request: RobloxLocalizationTablesApiUpdateTableContentsRequest, gameId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxLocalizationTablesApiUpdateTableContentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1LocalizationTableTablesTableIdPatch(tableId, request, gameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocalizationTableApi - factory interface
 * @export
 */
export const LocalizationTableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalizationTableApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the supported language codes that can be used by localization tables.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableAvailableLanguagesGet(options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxLocalizationTablesApiLanguage> {
            return localVarFp.v1LocalizationTableAvailableLanguagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get limits for translation table entries operations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableLimitsGet(options?: any): AxiosPromise<RobloxLocalizationTablesApiGetLimitsResponse> {
            return localVarFp.v1LocalizationTableLimitsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metadata for localization UI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableMetadataGet(options?: any): AxiosPromise<RobloxLocalizationTablesApiLocalizationTablesMetadataResponse> {
            return localVarFp.v1LocalizationTableMetadataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get table information by the assetId of the table.
         * @param {number} assetId The asset id associated with the table.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesAssetIdGet(assetId: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetTableResponse> {
            return localVarFp.v1LocalizationTableTablesAssetIdGet(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Localization Table with the given data.
         * @param {RobloxLocalizationTablesApiCreateTableRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesPost(request: RobloxLocalizationTablesApiCreateTableRequest, options?: any): AxiosPromise<RobloxLocalizationTablesApiCreateTableResponse> {
            return localVarFp.v1LocalizationTableTablesPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a batch of entries for a table.
         * @param {string} tableId 
         * @param {string} [cursor] If null, there are no more entries in the table and you\&#39;ve reached the last page.
         * @param {number} [gameId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdEntriesGet(tableId: string, cursor?: string, gameId?: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetTableEntriesPagedResponse> {
            return localVarFp.v1LocalizationTableTablesTableIdEntriesGet(tableId, cursor, gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the translation history for each entry passed in.
         * @param {string} tableId The entries\&#39; tableId.
         * @param {RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest} request A request body containing all relevant data for entry history lookup.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdEntriesTranslationHistoryPost(tableId: string, request: RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest, gameId?: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryResponse> {
            return localVarFp.v1LocalizationTableTablesTableIdEntriesTranslationHistoryPost(tableId, request, gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the number of entries in the specified table
         * @param {string} tableId The table id
         * @param {number} [gameId] The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdEntryCountGet(tableId: string, gameId?: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetTableEntryCountResponse> {
            return localVarFp.v1LocalizationTableTablesTableIdEntryCountGet(tableId, gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get table information by the id of the table.
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdGet(tableId: string, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetTableResponse> {
            return localVarFp.v1LocalizationTableTablesTableIdGet(tableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the language translation counts for the specified table.  The languages to retrieve must be provided.
         * @param {string} tableId The table id
         * @param {Array<string>} locales The locales corresponding to the language translation counts to retrieve. This will return 0 for the translation count of locales that do not exist for the given table.
         * @param {number} [gameId] The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdLanguageTranslationCountsGet(tableId: string, locales: Array<string>, gameId?: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetLanguageTranslationCountsForTableResponse> {
            return localVarFp.v1LocalizationTableTablesTableIdLanguageTranslationCountsGet(tableId, locales, gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the tables contents based on what is provided.
         * @param {string} tableId The table guid for the table to update.
         * @param {RobloxLocalizationTablesApiUpdateTableContentsRequest} request The metadata object is optional.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationTableTablesTableIdPatch(tableId: string, request: RobloxLocalizationTablesApiUpdateTableContentsRequest, gameId?: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiUpdateTableContentsResponse> {
            return localVarFp.v1LocalizationTableTablesTableIdPatch(tableId, request, gameId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalizationTableApi - interface
 * @export
 * @interface LocalizationTableApi
 */
export interface LocalizationTableApiInterface {
    /**
     * 
     * @summary Gets the supported language codes that can be used by localization tables.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableAvailableLanguagesGet(options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxLocalizationTablesApiLanguage>;

    /**
     * 
     * @summary Get limits for translation table entries operations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableLimitsGet(options?: any): AxiosPromise<RobloxLocalizationTablesApiGetLimitsResponse>;

    /**
     * 
     * @summary Get metadata for localization UI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableMetadataGet(options?: any): AxiosPromise<RobloxLocalizationTablesApiLocalizationTablesMetadataResponse>;

    /**
     * 
     * @summary Get table information by the assetId of the table.
     * @param {number} assetId The asset id associated with the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableTablesAssetIdGet(assetId: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetTableResponse>;

    /**
     * 
     * @summary Creates a Localization Table with the given data.
     * @param {RobloxLocalizationTablesApiCreateTableRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableTablesPost(request: RobloxLocalizationTablesApiCreateTableRequest, options?: any): AxiosPromise<RobloxLocalizationTablesApiCreateTableResponse>;

    /**
     * 
     * @summary Gets a batch of entries for a table.
     * @param {string} tableId 
     * @param {string} [cursor] If null, there are no more entries in the table and you\&#39;ve reached the last page.
     * @param {number} [gameId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableTablesTableIdEntriesGet(tableId: string, cursor?: string, gameId?: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetTableEntriesPagedResponse>;

    /**
     * 
     * @summary Gets the translation history for each entry passed in.
     * @param {string} tableId The entries\&#39; tableId.
     * @param {RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest} request A request body containing all relevant data for entry history lookup.
     * @param {number} [gameId] The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableTablesTableIdEntriesTranslationHistoryPost(tableId: string, request: RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest, gameId?: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryResponse>;

    /**
     * 
     * @summary Gets the number of entries in the specified table
     * @param {string} tableId The table id
     * @param {number} [gameId] The game id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableTablesTableIdEntryCountGet(tableId: string, gameId?: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetTableEntryCountResponse>;

    /**
     * 
     * @summary Get table information by the id of the table.
     * @param {string} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableTablesTableIdGet(tableId: string, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetTableResponse>;

    /**
     * 
     * @summary Gets the language translation counts for the specified table.  The languages to retrieve must be provided.
     * @param {string} tableId The table id
     * @param {Array<string>} locales The locales corresponding to the language translation counts to retrieve. This will return 0 for the translation count of locales that do not exist for the given table.
     * @param {number} [gameId] The game id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableTablesTableIdLanguageTranslationCountsGet(tableId: string, locales: Array<string>, gameId?: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiGetLanguageTranslationCountsForTableResponse>;

    /**
     * 
     * @summary Updates the tables contents based on what is provided.
     * @param {string} tableId The table guid for the table to update.
     * @param {RobloxLocalizationTablesApiUpdateTableContentsRequest} request The metadata object is optional.
     * @param {number} [gameId] The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationTableTablesTableIdPatch(tableId: string, request: RobloxLocalizationTablesApiUpdateTableContentsRequest, gameId?: number, options?: any): AxiosPromise<RobloxLocalizationTablesApiUpdateTableContentsResponse>;

}

/**
 * LocalizationTableApi - object-oriented interface
 * @export
 * @class LocalizationTableApi
 * @extends {BaseAPI}
 */
export class LocalizationTableApi extends BaseAPI implements LocalizationTableApiInterface {
    /**
     * 
     * @summary Gets the supported language codes that can be used by localization tables.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableAvailableLanguagesGet(options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableAvailableLanguagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get limits for translation table entries operations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableLimitsGet(options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableLimitsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metadata for localization UI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableMetadataGet(options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableMetadataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get table information by the assetId of the table.
     * @param {number} assetId The asset id associated with the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableTablesAssetIdGet(assetId: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableTablesAssetIdGet(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Localization Table with the given data.
     * @param {RobloxLocalizationTablesApiCreateTableRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableTablesPost(request: RobloxLocalizationTablesApiCreateTableRequest, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableTablesPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a batch of entries for a table.
     * @param {string} tableId 
     * @param {string} [cursor] If null, there are no more entries in the table and you\&#39;ve reached the last page.
     * @param {number} [gameId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableTablesTableIdEntriesGet(tableId: string, cursor?: string, gameId?: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableTablesTableIdEntriesGet(tableId, cursor, gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the translation history for each entry passed in.
     * @param {string} tableId The entries\&#39; tableId.
     * @param {RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest} request A request body containing all relevant data for entry history lookup.
     * @param {number} [gameId] The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableTablesTableIdEntriesTranslationHistoryPost(tableId: string, request: RobloxLocalizationTablesApiGetTableEntriesTranslationHistoryRequest, gameId?: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableTablesTableIdEntriesTranslationHistoryPost(tableId, request, gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the number of entries in the specified table
     * @param {string} tableId The table id
     * @param {number} [gameId] The game id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableTablesTableIdEntryCountGet(tableId: string, gameId?: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableTablesTableIdEntryCountGet(tableId, gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get table information by the id of the table.
     * @param {string} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableTablesTableIdGet(tableId: string, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableTablesTableIdGet(tableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the language translation counts for the specified table.  The languages to retrieve must be provided.
     * @param {string} tableId The table id
     * @param {Array<string>} locales The locales corresponding to the language translation counts to retrieve. This will return 0 for the translation count of locales that do not exist for the given table.
     * @param {number} [gameId] The game id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableTablesTableIdLanguageTranslationCountsGet(tableId: string, locales: Array<string>, gameId?: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableTablesTableIdLanguageTranslationCountsGet(tableId, locales, gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the tables contents based on what is provided.
     * @param {string} tableId The table guid for the table to update.
     * @param {RobloxLocalizationTablesApiUpdateTableContentsRequest} request The metadata object is optional.
     * @param {number} [gameId] The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationTableTablesTableIdPatch(tableId: string, request: RobloxLocalizationTablesApiUpdateTableContentsRequest, gameId?: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationTableTablesTableIdPatch(tableId, request, gameId, options).then((request) => request(this.axios, this.basePath));
    }
}


