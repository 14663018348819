// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * GameInternationalization Api v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import { httpClient as globalAxios } from '../../../http/http';
import { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest
 */
export interface RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiLocalizationTableGameAssociation>}
     * @memberof RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest
     */
    tables?: Array<RobloxGameInternationalizationApiLocalizationTableGameAssociation>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiAssociateLocalizationTablesToGameResponse
 */
export interface RobloxGameInternationalizationApiAssociateLocalizationTablesToGameResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiAssociateLocalizationTablesToGameResponse
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiAutoLocalizationMetadataResponse
 */
export interface RobloxGameInternationalizationApiAutoLocalizationMetadataResponse {
    /**
     * Is React Implementation of AutoLocalization Settings Enabled
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiAutoLocalizationMetadataResponse
     */
    isReactVersionEnabledForAutoLocalizationSettings?: boolean;
    /**
     * Is Tabbed UI Enabled for Configure Localization Page
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiAutoLocalizationMetadataResponse
     */
    isTabbedUIEnabledForConfigureLocalizationPage?: boolean;
    /**
     * Is Toggle UI Enabled for Automatic Translations
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiAutoLocalizationMetadataResponse
     */
    isAutomaticTranslationToggleUIEnabled?: boolean;
    /**
     * Is Quota UI Enabled for Automatic Translations
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiAutoLocalizationMetadataResponse
     */
    isAutomaticTranslationQuotaUIEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiAutomaticTranslationStatusTargetLanguage
 */
export interface RobloxGameInternationalizationApiAutomaticTranslationStatusTargetLanguage {
    /**
     * The language code.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiAutomaticTranslationStatusTargetLanguage
     */
    languageCode?: string;
    /**
     * Indicates whether or not automatic translation is allowed for the target language.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiAutomaticTranslationStatusTargetLanguage
     */
    isAutomaticTranslationAllowed?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiAutoscrapeEntry
 */
export interface RobloxGameInternationalizationApiAutoscrapeEntry {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiAutoscrapeEntry
     */
    context?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiAutoscrapeEntry
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiCreateTableRequest
 */
export interface RobloxGameInternationalizationApiCreateTableRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiCreateTableRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiCreateTableRequest
     */
    ownerType?: RobloxGameInternationalizationApiCreateTableRequestOwnerTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiCreateTableRequest
     */
    ownerId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiCreateTableRequestOwnerTypeEnum {
    User = 'User',
    Group = 'Group'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiCreateTableResponse
 */
export interface RobloxGameInternationalizationApiCreateTableResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiCreateTableResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiCreateTableResponse
     */
    assetId?: number;
}
/**
 * A model that contains an entry identifier and an associated cursor for paged lookups.
 * @export
 * @interface RobloxGameInternationalizationApiCursorEntryIdentifier
 */
export interface RobloxGameInternationalizationApiCursorEntryIdentifier {
    /**
     * The location to begin our query.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiCursorEntryIdentifier
     */
    cursor?: string;
    /**
     * 
     * @type {RobloxGameInternationalizationApiEntryIdentifier}
     * @memberof RobloxGameInternationalizationApiCursorEntryIdentifier
     */
    identifier?: RobloxGameInternationalizationApiEntryIdentifier;
    /**
     * The translation history count to get.
     * @type {number}
     * @memberof RobloxGameInternationalizationApiCursorEntryIdentifier
     */
    count?: number;
    /**
     * In which order the data is sorted.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiCursorEntryIdentifier
     */
    sortOrder?: RobloxGameInternationalizationApiCursorEntryIdentifierSortOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiCursorEntryIdentifierSortOrderEnum {
    Asc = 'Asc',
    Desc = 'Desc'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiEditAutomaticTranslationStatusForGameAndLanguageResponse
 */
export interface RobloxGameInternationalizationApiEditAutomaticTranslationStatusForGameAndLanguageResponse {
    /**
     * The game id.
     * @type {number}
     * @memberof RobloxGameInternationalizationApiEditAutomaticTranslationStatusForGameAndLanguageResponse
     */
    gameId?: number;
    /**
     * The language code.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiEditAutomaticTranslationStatusForGameAndLanguageResponse
     */
    languageCode?: string;
    /**
     * Indicates whether or not automatic translation is currently enabled for the game and language.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiEditAutomaticTranslationStatusForGameAndLanguageResponse
     */
    isAutomaticTranslationEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiEntry
 */
export interface RobloxGameInternationalizationApiEntry {
    /**
     * 
     * @type {RobloxGameInternationalizationApiEntryIdentifier}
     * @memberof RobloxGameInternationalizationApiEntry
     */
    identifier?: RobloxGameInternationalizationApiEntryIdentifier;
    /**
     * 
     * @type {RobloxGameInternationalizationApiEntryMetadata}
     * @memberof RobloxGameInternationalizationApiEntry
     */
    metadata?: RobloxGameInternationalizationApiEntryMetadata;
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiTranslation>}
     * @memberof RobloxGameInternationalizationApiEntry
     */
    translations?: Array<RobloxGameInternationalizationApiTranslation>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiEntryIdentifier
 */
export interface RobloxGameInternationalizationApiEntryIdentifier {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiEntryIdentifier
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiEntryIdentifier
     */
    context?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiEntryIdentifier
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiEntryMetadata
 */
export interface RobloxGameInternationalizationApiEntryMetadata {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiEntryMetadata
     */
    example?: string;
    /**
     * 
     * @type {Array<RobloxInGameContentTablesClientGameLocation>}
     * @memberof RobloxGameInternationalizationApiEntryMetadata
     */
    gameLocations?: Array<RobloxInGameContentTablesClientGameLocation>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiEntryTranslationHistoryPaged
 */
export interface RobloxGameInternationalizationApiEntryTranslationHistoryPaged {
    /**
     * 
     * @type {RobloxGameInternationalizationApiEntryIdentifier}
     * @memberof RobloxGameInternationalizationApiEntryTranslationHistoryPaged
     */
    identifier?: RobloxGameInternationalizationApiEntryIdentifier;
    /**
     * A batch of TranslationHistory for the given entry identifier.
     * @type {Array<RobloxGameInternationalizationApiTranslationHistory>}
     * @memberof RobloxGameInternationalizationApiEntryTranslationHistoryPaged
     */
    history?: Array<RobloxGameInternationalizationApiTranslationHistory>;
    /**
     * The cursor to send up on the next request if more history data is required.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiEntryTranslationHistoryPaged
     */
    nextCursor?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiError
 */
export interface RobloxGameInternationalizationApiError {
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiError
     */
    errorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiError
     */
    errorMessage?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiFailedEntry
 */
export interface RobloxGameInternationalizationApiFailedEntry {
    /**
     * 
     * @type {RobloxGameInternationalizationApiError}
     * @memberof RobloxGameInternationalizationApiFailedEntry
     */
    error?: RobloxGameInternationalizationApiError;
    /**
     * 
     * @type {RobloxGameInternationalizationApiEntryIdentifier}
     * @memberof RobloxGameInternationalizationApiFailedEntry
     */
    identifier?: RobloxGameInternationalizationApiEntryIdentifier;
    /**
     * 
     * @type {RobloxGameInternationalizationApiEntryMetadata}
     * @memberof RobloxGameInternationalizationApiFailedEntry
     */
    metadata?: RobloxGameInternationalizationApiEntryMetadata;
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiTranslation>}
     * @memberof RobloxGameInternationalizationApiFailedEntry
     */
    translations?: Array<RobloxGameInternationalizationApiTranslation>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiFailedEntryTranslationHistoryPaged
 */
export interface RobloxGameInternationalizationApiFailedEntryTranslationHistoryPaged {
    /**
     * 
     * @type {RobloxGameInternationalizationApiEntryIdentifier}
     * @memberof RobloxGameInternationalizationApiFailedEntryTranslationHistoryPaged
     */
    identifier?: RobloxGameInternationalizationApiEntryIdentifier;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiFailedEntryTranslationHistoryPaged
     */
    count?: number;
    /**
     * 
     * @type {RobloxGameInternationalizationApiError}
     * @memberof RobloxGameInternationalizationApiFailedEntryTranslationHistoryPaged
     */
    error?: RobloxGameInternationalizationApiError;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiFailedNameDescription
 */
export interface RobloxGameInternationalizationApiFailedNameDescription {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiFailedNameDescription
     */
    languageCode?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiFailedNameDescription
     */
    errorCode?: number;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGameAutolocalizationInformationResponse
 */
export interface RobloxGameInternationalizationApiGameAutolocalizationInformationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGameAutolocalizationInformationResponse
     */
    isAutolocalizationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGameAutolocalizationInformationResponse
     */
    shouldUseLocalizationTable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGameAutolocalizationInformationResponse
     */
    autoLocalizationTableId?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiGameAutolocalizationInformationResponse
     */
    assetId?: number;
}
/**
 * A response model for getting the automatic translation allowed status of target languages.
 * @export
 * @interface RobloxGameInternationalizationApiGetAllowedAutomaticTranslationStatusForLanguagesResponse
 */
export interface RobloxGameInternationalizationApiGetAllowedAutomaticTranslationStatusForLanguagesResponse {
    /**
     * The source language.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetAllowedAutomaticTranslationStatusForLanguagesResponse
     */
    sourceLanguage?: string;
    /**
     * The target languages with the automatic translation allowed status.  The status basically says if automatic translation can be enabled for the source and target.
     * @type {Array<RobloxGameInternationalizationApiAutomaticTranslationStatusTargetLanguage>}
     * @memberof RobloxGameInternationalizationApiGetAllowedAutomaticTranslationStatusForLanguagesResponse
     */
    targetLanguages?: Array<RobloxGameInternationalizationApiAutomaticTranslationStatusTargetLanguage>;
}
/**
 * A response model for getting the automatic translation allowed status of a game.
 * @export
 * @interface RobloxGameInternationalizationApiGetAutomaticTranslationFeatureStatusForGameResponse
 */
export interface RobloxGameInternationalizationApiGetAutomaticTranslationFeatureStatusForGameResponse {
    /**
     * The game id.
     * @type {number}
     * @memberof RobloxGameInternationalizationApiGetAutomaticTranslationFeatureStatusForGameResponse
     */
    gameId?: number;
    /**
     * Indicates whether or not automatic translation is allowed for the game.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGetAutomaticTranslationFeatureStatusForGameResponse
     */
    isAutomaticTranslationAllowed?: boolean;
    /**
     * Indicates whether or not automatic translation switches are enabled for the game.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGetAutomaticTranslationFeatureStatusForGameResponse
     */
    isAutomaticTranslationSwitchesUIEnabled?: boolean;
}
/**
 * A response model for getting the automatic translation quota info of a game.
 * @export
 * @interface RobloxGameInternationalizationApiGetAutomaticTranslationQuotaForGameResponse
 */
export interface RobloxGameInternationalizationApiGetAutomaticTranslationQuotaForGameResponse {
    /**
     * 
     * @type {RobloxGameInternationalizationApiMonthlyQuotaModel}
     * @memberof RobloxGameInternationalizationApiGetAutomaticTranslationQuotaForGameResponse
     */
    monthlyQuota?: RobloxGameInternationalizationApiMonthlyQuotaModel;
    /**
     * 
     * @type {RobloxGameInternationalizationApiQuotaModel}
     * @memberof RobloxGameInternationalizationApiGetAutomaticTranslationQuotaForGameResponse
     */
    bankQuota?: RobloxGameInternationalizationApiQuotaModel;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGetBadgeIconResponse
 */
export interface RobloxGameInternationalizationApiGetBadgeIconResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetBadgeIconResponse
     */
    imageId?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetBadgeIconResponse
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetBadgeIconResponse
     */
    state?: RobloxGameInternationalizationApiGetBadgeIconResponseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetBadgeIconResponse
     */
    languageCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiGetBadgeIconResponseStateEnum {
    Approved = 'Approved',
    PendingReview = 'PendingReview',
    UnAvailable = 'UnAvailable',
    Rejected = 'Rejected',
    Error = 'Error'
}

/**
 * A response model for getting developer product icon
 * @export
 * @interface RobloxGameInternationalizationApiGetDeveloperProductIconResponse
 */
export interface RobloxGameInternationalizationApiGetDeveloperProductIconResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetDeveloperProductIconResponse
     */
    imageId?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetDeveloperProductIconResponse
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetDeveloperProductIconResponse
     */
    state?: RobloxGameInternationalizationApiGetDeveloperProductIconResponseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetDeveloperProductIconResponse
     */
    languageCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiGetDeveloperProductIconResponseStateEnum {
    Approved = 'Approved',
    PendingReview = 'PendingReview',
    UnAvailable = 'UnAvailable',
    Rejected = 'Rejected',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGetGameIconResponse
 */
export interface RobloxGameInternationalizationApiGetGameIconResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetGameIconResponse
     */
    imageId?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetGameIconResponse
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetGameIconResponse
     */
    state?: RobloxGameInternationalizationApiGetGameIconResponseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetGameIconResponse
     */
    languageCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiGetGameIconResponseStateEnum {
    Approved = 'Approved',
    PendingReview = 'PendingReview',
    UnAvailable = 'UnAvailable',
    Rejected = 'Rejected',
    Error = 'Error'
}

/**
 * A response model for getting game pass icon
 * @export
 * @interface RobloxGameInternationalizationApiGetGamePassIconResponse
 */
export interface RobloxGameInternationalizationApiGetGamePassIconResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetGamePassIconResponse
     */
    imageId?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetGamePassIconResponse
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetGamePassIconResponse
     */
    state?: RobloxGameInternationalizationApiGetGamePassIconResponseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetGamePassIconResponse
     */
    languageCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiGetGamePassIconResponseStateEnum {
    Approved = 'Approved',
    PendingReview = 'PendingReview',
    UnAvailable = 'UnAvailable',
    Rejected = 'Rejected',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGetGameThumbnailsResponse
 */
export interface RobloxGameInternationalizationApiGetGameThumbnailsResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetGameThumbnailsResponse
     */
    languageCode?: string;
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiMediaAssetResponse>}
     * @memberof RobloxGameInternationalizationApiGetGameThumbnailsResponse
     */
    mediaAssets?: Array<RobloxGameInternationalizationApiMediaAssetResponse>;
}
/**
 * A model containing request for getting history of name or description.
 * @export
 * @interface RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest
 */
export interface RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest
     */
    requestType?: RobloxGameInternationalizationApiGetNameDescriptionHistoryRequestRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest
     */
    cursor?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest
     */
    sortOrder?: RobloxGameInternationalizationApiGetNameDescriptionHistoryRequestSortOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiGetNameDescriptionHistoryRequestRequestTypeEnum {
    Name = 'Name',
    Description = 'Description'
}
/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiGetNameDescriptionHistoryRequestSortOrderEnum {
    Asc = 'Asc',
    Desc = 'Desc'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGetNameDescriptionHistoryResponse
 */
export interface RobloxGameInternationalizationApiGetNameDescriptionHistoryResponse {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiTranslationHistory>}
     * @memberof RobloxGameInternationalizationApiGetNameDescriptionHistoryResponse
     */
    history?: Array<RobloxGameInternationalizationApiTranslationHistory>;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetNameDescriptionHistoryResponse
     */
    lastEvaluatedId?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGetPlayerPoliciesResponse
 */
export interface RobloxGameInternationalizationApiGetPlayerPoliciesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGetPlayerPoliciesResponse
     */
    isSubjectToChinaPolicies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGetPlayerPoliciesResponse
     */
    arePaidRandomItemsRestricted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGetPlayerPoliciesResponse
     */
    isPaidItemTradingAllowed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RobloxGameInternationalizationApiGetPlayerPoliciesResponse
     */
    allowedExternalLinkReferences?: Array<string>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGetTableEntriesPagedResponse
 */
export interface RobloxGameInternationalizationApiGetTableEntriesPagedResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTableEntriesPagedResponse
     */
    previousPageCursor?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTableEntriesPagedResponse
     */
    nextPageCursor?: string;
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiEntry>}
     * @memberof RobloxGameInternationalizationApiGetTableEntriesPagedResponse
     */
    data?: Array<RobloxGameInternationalizationApiEntry>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest
 */
export interface RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest
     */
    locale?: string;
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiCursorEntryIdentifier>}
     * @memberof RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest
     */
    entries?: Array<RobloxGameInternationalizationApiCursorEntryIdentifier>;
}
/**
 * A response model for GetTableEntriesTranslationHistory.
 * @export
 * @interface RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryResponse
 */
export interface RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryResponse {
    /**
     * The entries\' tableId.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryResponse
     */
    tableId?: string;
    /**
     * The locale of the translations.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryResponse
     */
    locale?: string;
    /**
     * The entries with their identifier, translation history, and next cursor.
     * @type {Array<RobloxGameInternationalizationApiEntryTranslationHistoryPaged>}
     * @memberof RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryResponse
     */
    entries?: Array<RobloxGameInternationalizationApiEntryTranslationHistoryPaged>;
    /**
     * The failed entries.
     * @type {Array<RobloxGameInternationalizationApiFailedEntryTranslationHistoryPaged>}
     * @memberof RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryResponse
     */
    failedEntries?: Array<RobloxGameInternationalizationApiFailedEntryTranslationHistoryPaged>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGetTableEntryCountResponse
 */
export interface RobloxGameInternationalizationApiGetTableEntryCountResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTableEntryCountResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiGetTableEntryCountResponse
     */
    entryCount?: number;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGetTableResponse
 */
export interface RobloxGameInternationalizationApiGetTableResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTableResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTableResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTableResponse
     */
    ownerType?: RobloxGameInternationalizationApiGetTableResponseOwnerTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiGetTableResponse
     */
    ownerId?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiGetTableResponse
     */
    assetId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiGetTableResponseOwnerTypeEnum {
    User = 'User',
    Group = 'Group'
}

/**
 * A response model that contains game info for getting a translation count.
 * @export
 * @interface RobloxGameInternationalizationApiGetTranslationCountsForGameResponse
 */
export interface RobloxGameInternationalizationApiGetTranslationCountsForGameResponse {
    /**
     * The game id.
     * @type {number}
     * @memberof RobloxGameInternationalizationApiGetTranslationCountsForGameResponse
     */
    gameId?: number;
    /**
     * The list of languages/locales of the game.
     * @type {Array<RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponse>}
     * @memberof RobloxGameInternationalizationApiGetTranslationCountsForGameResponse
     */
    languagesOrLocales?: Array<RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponse>;
}
/**
 * A response model for getting translation counts for a language or locale.
 * @export
 * @interface RobloxGameInternationalizationApiGetTranslationCountsForLanguageOrLocaleResponse
 */
export interface RobloxGameInternationalizationApiGetTranslationCountsForLanguageOrLocaleResponse {
    /**
     * The code of the language or locale.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTranslationCountsForLanguageOrLocaleResponse
     */
    languageOrLocaleCode?: string;
    /**
     * An indicator that says whether the passed in language/locale code represents a language or locale.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiGetTranslationCountsForLanguageOrLocaleResponse
     */
    languageOrLocaleType?: RobloxGameInternationalizationApiGetTranslationCountsForLanguageOrLocaleResponseLanguageOrLocaleTypeEnum;
    /**
     * Info for the requested games, including their translation counts for the specified language or locale.
     * @type {Array<RobloxGameInternationalizationApiTranslationCountGameInfoResponse>}
     * @memberof RobloxGameInternationalizationApiGetTranslationCountsForLanguageOrLocaleResponse
     */
    games?: Array<RobloxGameInternationalizationApiTranslationCountGameInfoResponse>;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiGetTranslationCountsForLanguageOrLocaleResponseLanguageOrLocaleTypeEnum {
    Language = 'Language',
    Locale = 'Locale'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiGetUiConfigurationsResponse
 */
export interface RobloxGameInternationalizationApiGetUiConfigurationsResponse {
    /**
     * Game products translation management page is enabled or not.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGetUiConfigurationsResponse
     */
    isGameProductsEnabled?: boolean;
    /**
     * Badge Icon translation management is enabled or not.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGetUiConfigurationsResponse
     */
    isBadgeIconEnabled?: boolean;
    /**
     * Game pass translation management is enabled or not.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGetUiConfigurationsResponse
     */
    isGamePassEnabled?: boolean;
    /**
     * Developer product translation management is enabled or not.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiGetUiConfigurationsResponse
     */
    isDeveloperProductEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest
 */
export interface RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiAutoscrapeEntry>}
     * @memberof RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest
     */
    entries?: Array<RobloxGameInternationalizationApiAutoscrapeEntry>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiLanguage
 */
export interface RobloxGameInternationalizationApiLanguage {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiLanguage
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiLanguage
     */
    nativeName?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiLanguage
     */
    languageCode?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiLanguageOrLocale
 */
export interface RobloxGameInternationalizationApiLanguageOrLocale {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiLanguageOrLocale
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiLanguageOrLocale
     */
    languageCodeType?: RobloxGameInternationalizationApiLanguageOrLocaleLanguageCodeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiLanguageOrLocale
     */
    languageCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiLanguageOrLocaleLanguageCodeTypeEnum {
    Language = 'Language',
    Locale = 'Locale'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiLanguageOrLocaleSettings
 */
export interface RobloxGameInternationalizationApiLanguageOrLocaleSettings {
    /**
     * The language code type.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiLanguageOrLocaleSettings
     */
    languageCodeType?: RobloxGameInternationalizationApiLanguageOrLocaleSettingsLanguageCodeTypeEnum;
    /**
     * The language code.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiLanguageOrLocaleSettings
     */
    languageCode?: string;
    /**
     * Indicates whether or not automatic translation is currently enabled for the game and language.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiLanguageOrLocaleSettings
     */
    isAutomaticTranslationEnabled?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiLanguageOrLocaleSettingsLanguageCodeTypeEnum {
    Language = 'Language',
    Locale = 'Locale'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiLocalizationTableGameAssociation
 */
export interface RobloxGameInternationalizationApiLocalizationTableGameAssociation {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiLocalizationTableGameAssociation
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiLocalizationTableGameAssociation
     */
    dissociate?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiMediaAssetResponse
 */
export interface RobloxGameInternationalizationApiMediaAssetResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiMediaAssetResponse
     */
    mediaAssetId?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiMediaAssetResponse
     */
    state?: RobloxGameInternationalizationApiMediaAssetResponseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiMediaAssetResponse
     */
    mediaAssetUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiMediaAssetResponseStateEnum {
    Approved = 'Approved',
    PendingReview = 'PendingReview',
    UnAvailable = 'UnAvailable',
    Rejected = 'Rejected',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiModelsResponseGameNameDescriptionMetadataResponse
 */
export interface RobloxGameInternationalizationApiModelsResponseGameNameDescriptionMetadataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiModelsResponseGameNameDescriptionMetadataResponse
     */
    isNameDescriptionMigrationEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiModelsResponseUploadImageForGameThumbnailResponse
 */
export interface RobloxGameInternationalizationApiModelsResponseUploadImageForGameThumbnailResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiModelsResponseUploadImageForGameThumbnailResponse
     */
    mediaAssetId?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiMonthlyQuotaModel
 */
export interface RobloxGameInternationalizationApiMonthlyQuotaModel {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiMonthlyQuotaModel
     */
    previousRefreshDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiMonthlyQuotaModel
     */
    nextRefreshDate?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiMonthlyQuotaModel
     */
    remaining?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiMonthlyQuotaModel
     */
    capacity?: number;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiNameDescription
 */
export interface RobloxGameInternationalizationApiNameDescription {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiNameDescription
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiNameDescription
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiNameDescription
     */
    languageCode?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiPatchEntry
 */
export interface RobloxGameInternationalizationApiPatchEntry {
    /**
     * 
     * @type {RobloxGameInternationalizationApiEntryIdentifier}
     * @memberof RobloxGameInternationalizationApiPatchEntry
     */
    identifier?: RobloxGameInternationalizationApiEntryIdentifier;
    /**
     * 
     * @type {RobloxGameInternationalizationApiEntryMetadata}
     * @memberof RobloxGameInternationalizationApiPatchEntry
     */
    metadata?: RobloxGameInternationalizationApiEntryMetadata;
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiPatchTranslation>}
     * @memberof RobloxGameInternationalizationApiPatchEntry
     */
    translations?: Array<RobloxGameInternationalizationApiPatchTranslation>;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiPatchEntry
     */
    _delete?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiPatchLanguage
 */
export interface RobloxGameInternationalizationApiPatchLanguage {
    /**
     * The language code type.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiPatchLanguage
     */
    languageCodeType?: RobloxGameInternationalizationApiPatchLanguageLanguageCodeTypeEnum;
    /**
     * The language code for the language to edit.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiPatchLanguage
     */
    languageCode?: string;
    /**
     * An optional field used to indicate whether a language should be deleted from this game\'s list of supported languages.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiPatchLanguage
     */
    _delete?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiPatchLanguageLanguageCodeTypeEnum {
    Language = 'Language',
    Locale = 'Locale'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiPatchTranslation
 */
export interface RobloxGameInternationalizationApiPatchTranslation {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiPatchTranslation
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiPatchTranslation
     */
    translationText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiPatchTranslation
     */
    _delete?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiQuotaModel
 */
export interface RobloxGameInternationalizationApiQuotaModel {
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiQuotaModel
     */
    remaining?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiQuotaModel
     */
    capacity?: number;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest
 */
export interface RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest {
    /**
     * The time range to remove entries from. Following ISO 8601 Durations format
     * @type {string}
     * @memberof RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest
     */
    maxAgeForFlush?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest
 */
export interface RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest {
    /**
     * The inclusive start dateTime of report in UTC
     * @type {string}
     * @memberof RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest
     */
    startDateTime?: string;
    /**
     * The exclusive end dateTime of report in UTC
     * @type {string}
     * @memberof RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest
     */
    endDateTime?: string;
    /**
     * The report type
     * @type {string}
     * @memberof RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest
     */
    reportType?: RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequestReportTypeEnum;
    /**
     * The report subject target id
     * @type {number}
     * @memberof RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest
     */
    reportSubjectTargetId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequestReportTypeEnum {
    GameTranslationStatus = 'GameTranslationStatus',
    GameTranslationStatusForTranslatorGroup = 'GameTranslationStatusForTranslatorGroup',
    GameTranslationStatusForTranslator = 'GameTranslationStatusForTranslator',
    Test = 'Test'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiRequestTranslationAnalyticsReportResponse
 */
export interface RobloxGameInternationalizationApiRequestTranslationAnalyticsReportResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiRequestTranslationAnalyticsReportResponse
     */
    reportGenerationStatus?: RobloxGameInternationalizationApiRequestTranslationAnalyticsReportResponseReportGenerationStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiRequestTranslationAnalyticsReportResponseReportGenerationStatusEnum {
    InProgress = 'inProgress',
    Ready = 'ready',
    Unavailable = 'unavailable'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest
 */
export interface RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest
     */
    isAutolocalizationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest
     */
    shouldUseLocalizationTable?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest
 */
export interface RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest
     */
    tableId?: string;
}
/**
 * A request model for sorting of image Ids for game thumbnails
 * @export
 * @interface RobloxGameInternationalizationApiSortImageIdsRequest
 */
export interface RobloxGameInternationalizationApiSortImageIdsRequest {
    /**
     * List of thumbnail ids in desired order.
     * @type {Array<number>}
     * @memberof RobloxGameInternationalizationApiSortImageIdsRequest
     */
    mediaAssetIds?: Array<number>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse
 */
export interface RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse
     */
    isFeatureEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse
     */
    areAllLanguagesEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse
     */
    minimumUniverseIdForFeature?: number;
    /**
     * Is translation progress for human translations enabled on UI
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse
     */
    isHumanTranslationProgressUIEnabled?: boolean;
    /**
     * Is translation progress for automatic translations enabled on UI
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse
     */
    isAutomaticTranslationProgressUIEnabled?: boolean;
    /**
     * Is Language Locales UI Enabled for Supported Languages
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse
     */
    isSupportedLanguagesChildLocalesUIEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiTranslation
 */
export interface RobloxGameInternationalizationApiTranslation {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslation
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslation
     */
    translationText?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiTranslationAnalyticsMetadataResponse
 */
export interface RobloxGameInternationalizationApiTranslationAnalyticsMetadataResponse {
    /**
     * Is Translation Analytics feature enabled on UI
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiTranslationAnalyticsMetadataResponse
     */
    isFeatureEnabledOnUI?: boolean;
    /**
     * Number of seconds to poll the server for report availability
     * @type {number}
     * @memberof RobloxGameInternationalizationApiTranslationAnalyticsMetadataResponse
     */
    reportRequestPollingIntervalSeconds?: number;
    /**
     * Date-Time since the Analytics Reports can be downloaded
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationAnalyticsMetadataResponse
     */
    minimumDateTimeForAnalyticsReport?: string;
}
/**
 * A model that contains the count value of a translation count and relevant metadata.
 * @export
 * @interface RobloxGameInternationalizationApiTranslationCount
 */
export interface RobloxGameInternationalizationApiTranslationCount {
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiTranslationCount
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationCount
     */
    translationStatus?: RobloxGameInternationalizationApiTranslationCountTranslationStatusEnum;
    /**
     * Gets or sets the type of the translator associated with the count, or null if the count is not associated with a specific type of translator.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationCount
     */
    translatorType?: RobloxGameInternationalizationApiTranslationCountTranslatorTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiTranslationCountTranslationStatusEnum {
    Approved = 'Approved'
}
/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiTranslationCountTranslatorTypeEnum {
    Automation = 'Automation',
    User = 'User'
}

/**
 * A response model that contains category info for getting a translation count.
 * @export
 * @interface RobloxGameInternationalizationApiTranslationCountCategoryInfoResponse
 */
export interface RobloxGameInternationalizationApiTranslationCountCategoryInfoResponse {
    /**
     * The category of the translation counts.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationCountCategoryInfoResponse
     */
    category?: RobloxGameInternationalizationApiTranslationCountCategoryInfoResponseCategoryEnum;
    /**
     * The translation counts.
     * @type {Array<RobloxGameInternationalizationApiTranslationCount>}
     * @memberof RobloxGameInternationalizationApiTranslationCountCategoryInfoResponse
     */
    translationCounts?: Array<RobloxGameInternationalizationApiTranslationCount>;
    /**
     * The total number of translatable items.  The translation count percentage can be calculated by doing translationCounts / totalTranslatableItemCount.
     * @type {number}
     * @memberof RobloxGameInternationalizationApiTranslationCountCategoryInfoResponse
     */
    totalTranslatableItemCount?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiTranslationCountCategoryInfoResponseCategoryEnum {
    InGameContent = 'InGameContent'
}

/**
 * A response model that contains game info for getting a translation count.
 * @export
 * @interface RobloxGameInternationalizationApiTranslationCountGameInfoResponse
 */
export interface RobloxGameInternationalizationApiTranslationCountGameInfoResponse {
    /**
     * The game id.
     * @type {number}
     * @memberof RobloxGameInternationalizationApiTranslationCountGameInfoResponse
     */
    gameId?: number;
    /**
     * The status of the response for the requested game.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationCountGameInfoResponse
     */
    status?: RobloxGameInternationalizationApiTranslationCountGameInfoResponseStatusEnum;
    /**
     * Categories of translation counts.
     * @type {Array<RobloxGameInternationalizationApiTranslationCountCategoryInfoResponse>}
     * @memberof RobloxGameInternationalizationApiTranslationCountGameInfoResponse
     */
    categories?: Array<RobloxGameInternationalizationApiTranslationCountCategoryInfoResponse>;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiTranslationCountGameInfoResponseStatusEnum {
    LanguageOrLocaleSupportedForGame = 'LanguageOrLocaleSupportedForGame',
    LanguageOrLocaleNotSupportedForGame = 'LanguageOrLocaleNotSupportedForGame',
    LanguageOrLocaleIsSource = 'LanguageOrLocaleIsSource',
    InsufficientPermission = 'InsufficientPermission',
    GameDoesNotExist = 'GameDoesNotExist',
    GameDoesNotHaveTable = 'GameDoesNotHaveTable',
    UnknownError = 'UnknownError'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponse
 */
export interface RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponse {
    /**
     * The status of the response for the requested game.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponse
     */
    status?: RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponseStatusEnum;
    /**
     * Categories of translation counts.
     * @type {Array<RobloxGameInternationalizationApiTranslationCountCategoryInfoResponse>}
     * @memberof RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponse
     */
    categories?: Array<RobloxGameInternationalizationApiTranslationCountCategoryInfoResponse>;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponse
     */
    languageCodeType?: RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponseLanguageCodeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponse
     */
    languageCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponseStatusEnum {
    Success = 'Success',
    LanguageOrLocaleNotSupportedForGame = 'LanguageOrLocaleNotSupportedForGame'
}
/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiTranslationCountLanguageOrLocaleResponseLanguageCodeTypeEnum {
    Language = 'Language',
    Locale = 'Locale'
}

/**
 * A model containing a translation, translator, and translation time.
 * @export
 * @interface RobloxGameInternationalizationApiTranslationHistory
 */
export interface RobloxGameInternationalizationApiTranslationHistory {
    /**
     * The translation provided by the translator.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationHistory
     */
    translationText?: string;
    /**
     * 
     * @type {RobloxGameInternationalizationApiTranslator}
     * @memberof RobloxGameInternationalizationApiTranslationHistory
     */
    translator?: RobloxGameInternationalizationApiTranslator;
    /**
     * The time the translation was provided.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslationHistory
     */
    created?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiTranslator
 */
export interface RobloxGameInternationalizationApiTranslator {
    /**
     * 
     * @type {number}
     * @memberof RobloxGameInternationalizationApiTranslator
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiTranslator
     */
    agentType?: RobloxGameInternationalizationApiTranslatorAgentTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGameInternationalizationApiTranslatorAgentTypeEnum {
    User = 'User',
    Automation = 'Automation'
}

/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings
 */
export interface RobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings {
    /**
     * The language code.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings
     */
    languageCode?: string;
    /**
     * Indicates whether or not automatic translation is currently enabled for Game and Place Information for a game and language.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings
     */
    isUniverseDisplayInfoAutomaticTranslationEnabled?: boolean;
}
/**
 * A request model for updating badge description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest
 */
export interface RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest {
    /**
     * Badge description
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest
     */
    description?: string;
}
/**
 * A response model for updating badge description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateBadgeDescriptionResponse
 */
export interface RobloxGameInternationalizationApiUpdateBadgeDescriptionResponse {
    /**
     * Badge description saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateBadgeDescriptionResponse
     */
    description?: string;
}
/**
 * A request model for updating badge name and description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest
 */
export interface RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest {
    /**
     * Badge name
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest
     */
    name?: string;
    /**
     * Badge description
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest
     */
    description?: string;
}
/**
 * A response model for updating badge name and description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateBadgeNameDescriptionResponse
 */
export interface RobloxGameInternationalizationApiUpdateBadgeNameDescriptionResponse {
    /**
     * Badge name saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateBadgeNameDescriptionResponse
     */
    name?: string;
    /**
     * Badge description saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateBadgeNameDescriptionResponse
     */
    description?: string;
}
/**
 * A request model for updating badge name
 * @export
 * @interface RobloxGameInternationalizationApiUpdateBadgeNameRequest
 */
export interface RobloxGameInternationalizationApiUpdateBadgeNameRequest {
    /**
     * Badge name
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateBadgeNameRequest
     */
    name?: string;
}
/**
 * A response model for updating badge name
 * @export
 * @interface RobloxGameInternationalizationApiUpdateBadgeNameResponse
 */
export interface RobloxGameInternationalizationApiUpdateBadgeNameResponse {
    /**
     * Badge name saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateBadgeNameResponse
     */
    name?: string;
}
/**
 * A request model for updating developer product description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest
 */
export interface RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest {
    /**
     * Developer product description
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest
     */
    description?: string;
}
/**
 * A response model for updating developer product description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionResponse
 */
export interface RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionResponse {
    /**
     * Developer product description saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionResponse
     */
    description?: string;
}
/**
 * A request model for updating developer product name and description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest
 */
export interface RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest {
    /**
     * Developer product name
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest
     */
    name?: string;
    /**
     * Developer product description
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest
     */
    description?: string;
}
/**
 * A response model for updating developer product name and description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionResponse
 */
export interface RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionResponse {
    /**
     * Developer product name saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionResponse
     */
    name?: string;
    /**
     * Developer product description saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionResponse
     */
    description?: string;
}
/**
 * A request model for updating developer product name
 * @export
 * @interface RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest
 */
export interface RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest {
    /**
     * Developer product name
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest
     */
    name?: string;
}
/**
 * A response model for updating developer product name
 * @export
 * @interface RobloxGameInternationalizationApiUpdateDeveloperProductNameResponse
 */
export interface RobloxGameInternationalizationApiUpdateDeveloperProductNameResponse {
    /**
     * Developer product name saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateDeveloperProductNameResponse
     */
    name?: string;
}
/**
 * A request model for updating game pass description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest
 */
export interface RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest {
    /**
     * Game pass description
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest
     */
    description?: string;
}
/**
 * A response model for updating game pass description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateGamePassDescriptionResponse
 */
export interface RobloxGameInternationalizationApiUpdateGamePassDescriptionResponse {
    /**
     * Game pass description saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateGamePassDescriptionResponse
     */
    description?: string;
}
/**
 * A request model for updating game pass name and description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest
 */
export interface RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest {
    /**
     * Game pass name
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest
     */
    name?: string;
    /**
     * Game pass description
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest
     */
    description?: string;
}
/**
 * A response model for updating game pass name and description
 * @export
 * @interface RobloxGameInternationalizationApiUpdateGamePassNameDescriptionResponse
 */
export interface RobloxGameInternationalizationApiUpdateGamePassNameDescriptionResponse {
    /**
     * Game pass name saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateGamePassNameDescriptionResponse
     */
    name?: string;
    /**
     * Game pass description saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateGamePassNameDescriptionResponse
     */
    description?: string;
}
/**
 * A request model for updating game pass name
 * @export
 * @interface RobloxGameInternationalizationApiUpdateGamePassNameRequest
 */
export interface RobloxGameInternationalizationApiUpdateGamePassNameRequest {
    /**
     * Game pass name
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateGamePassNameRequest
     */
    name?: string;
}
/**
 * A response model for updating game pass name
 * @export
 * @interface RobloxGameInternationalizationApiUpdateGamePassNameResponse
 */
export interface RobloxGameInternationalizationApiUpdateGamePassNameResponse {
    /**
     * Game pass name saved
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateGamePassNameResponse
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiUpdateNameDescriptionsRequest
 */
export interface RobloxGameInternationalizationApiUpdateNameDescriptionsRequest {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiNameDescription>}
     * @memberof RobloxGameInternationalizationApiUpdateNameDescriptionsRequest
     */
    data?: Array<RobloxGameInternationalizationApiNameDescription>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiUpdateNameDescriptionsResponse
 */
export interface RobloxGameInternationalizationApiUpdateNameDescriptionsResponse {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiNameDescription>}
     * @memberof RobloxGameInternationalizationApiUpdateNameDescriptionsResponse
     */
    successOperations?: Array<RobloxGameInternationalizationApiNameDescription>;
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiFailedNameDescription>}
     * @memberof RobloxGameInternationalizationApiUpdateNameDescriptionsResponse
     */
    failedOperations?: Array<RobloxGameInternationalizationApiFailedNameDescription>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiUpdateTableContentsRequest
 */
export interface RobloxGameInternationalizationApiUpdateTableContentsRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateTableContentsRequest
     */
    name?: string;
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiPatchEntry>}
     * @memberof RobloxGameInternationalizationApiUpdateTableContentsRequest
     */
    entries?: Array<RobloxGameInternationalizationApiPatchEntry>;
}
/**
 * 
 * @export
 * @interface RobloxGameInternationalizationApiUpdateTableContentsResponse
 */
export interface RobloxGameInternationalizationApiUpdateTableContentsResponse {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiFailedEntry>}
     * @memberof RobloxGameInternationalizationApiUpdateTableContentsResponse
     */
    failedEntriesAndTranslations?: Array<RobloxGameInternationalizationApiFailedEntry>;
}
/**
 * A response model for getting the automatic translation allowed status for game information.
 * @export
 * @interface RobloxGameInternationalizationApiUpdateUniverseDisplayInfoAutomaticTranslationSettingsResponse
 */
export interface RobloxGameInternationalizationApiUpdateUniverseDisplayInfoAutomaticTranslationSettingsResponse {
    /**
     * The game id.
     * @type {number}
     * @memberof RobloxGameInternationalizationApiUpdateUniverseDisplayInfoAutomaticTranslationSettingsResponse
     */
    gameId?: number;
    /**
     * The language code.
     * @type {string}
     * @memberof RobloxGameInternationalizationApiUpdateUniverseDisplayInfoAutomaticTranslationSettingsResponse
     */
    languageCode?: string;
    /**
     * Indicates universeDisplayInformation automatic translation is enabled.
     * @type {boolean}
     * @memberof RobloxGameInternationalizationApiUpdateUniverseDisplayInfoAutomaticTranslationSettingsResponse
     */
    isUniverseDisplayInfoAutomaticTranslationEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxInGameContentTablesClientGameLocation
 */
export interface RobloxInGameContentTablesClientGameLocation {
    /**
     * 
     * @type {string}
     * @memberof RobloxInGameContentTablesClientGameLocation
     */
    path?: string;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetBadgeIconResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetBadgeIconResponse {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiGetBadgeIconResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetBadgeIconResponse
     */
    data?: Array<RobloxGameInternationalizationApiGetBadgeIconResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetDeveloperProductIconResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetDeveloperProductIconResponse {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiGetDeveloperProductIconResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetDeveloperProductIconResponse
     */
    data?: Array<RobloxGameInternationalizationApiGetDeveloperProductIconResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameIconResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameIconResponse {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiGetGameIconResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameIconResponse
     */
    data?: Array<RobloxGameInternationalizationApiGetGameIconResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGamePassIconResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGamePassIconResponse {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiGetGamePassIconResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGamePassIconResponse
     */
    data?: Array<RobloxGameInternationalizationApiGetGamePassIconResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameThumbnailsResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameThumbnailsResponse {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiGetGameThumbnailsResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameThumbnailsResponse
     */
    data?: Array<RobloxGameInternationalizationApiGetGameThumbnailsResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguage
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguage {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiLanguage>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguage
     */
    data?: Array<RobloxGameInternationalizationApiLanguage>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocale
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocale {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiLanguageOrLocale>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocale
     */
    data?: Array<RobloxGameInternationalizationApiLanguageOrLocale>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocaleSettings
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocaleSettings {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiLanguageOrLocaleSettings>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocaleSettings
     */
    data?: Array<RobloxGameInternationalizationApiLanguageOrLocaleSettings>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiNameDescription>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription
     */
    data?: Array<RobloxGameInternationalizationApiNameDescription>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings {
    /**
     * 
     * @type {Array<RobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings
     */
    data?: Array<RobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings>;
}

/**
 * AutolocalizationApi - axios parameter creator
 * @export
 */
export const AutolocalizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} gameId 
         * @param {RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdAutolocalizationtablePatch: async (gameId: number, request: RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1AutolocalizationGamesGameIdAutolocalizationtablePatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1AutolocalizationGamesGameIdAutolocalizationtablePatch.');
            }
            const localVarPath = `/v1/autolocalization/games/{gameId}/autolocalizationtable`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdAutolocalizationtablePost: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1AutolocalizationGamesGameIdAutolocalizationtablePost.');
            }
            const localVarPath = `/v1/autolocalization/games/{gameId}/autolocalizationtable`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enqueues an event to Flush the autoscraped entries which doesnt have translations
         * @param {number} gameId The id of the game.
         * @param {RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdAutoscrapeCleanupRequestPost: async (gameId: number, request: RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1AutolocalizationGamesGameIdAutoscrapeCleanupRequestPost.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1AutolocalizationGamesGameIdAutoscrapeCleanupRequestPost.');
            }
            const localVarPath = `/v1/autolocalization/games/{gameId}/autoscrape-cleanup-request`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdGet: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1AutolocalizationGamesGameIdGet.');
            }
            const localVarPath = `/v1/autolocalization/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ingests entries for auto localization.
         * @param {number} gameId The game id.
         * @param {RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest} request The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdPatch: async (gameId: number, request: RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1AutolocalizationGamesGameIdPatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1AutolocalizationGamesGameIdPatch.');
            }
            const localVarPath = `/v1/autolocalization/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets a game\'s auto-localization related settings
         * @param {number} gameId The id of the game.
         * @param {RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest} request The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdSettingsPatch: async (gameId: number, request: RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1AutolocalizationGamesGameIdSettingsPatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1AutolocalizationGamesGameIdSettingsPatch.');
            }
            const localVarPath = `/v1/autolocalization/games/{gameId}/settings`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Metadata for AutoLocalization Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationMetadataGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/autolocalization/metadata`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutolocalizationApi - functional programming interface
 * @export
 */
export const AutolocalizationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} gameId 
         * @param {RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutolocalizationGamesGameIdAutolocalizationtablePatch(gameId: number, request: RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await AutolocalizationApiAxiosParamCreator(configuration).v1AutolocalizationGamesGameIdAutolocalizationtablePatch(gameId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutolocalizationGamesGameIdAutolocalizationtablePost(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGameAutolocalizationInformationResponse>> {
            const localVarAxiosArgs = await AutolocalizationApiAxiosParamCreator(configuration).v1AutolocalizationGamesGameIdAutolocalizationtablePost(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Enqueues an event to Flush the autoscraped entries which doesnt have translations
         * @param {number} gameId The id of the game.
         * @param {RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutolocalizationGamesGameIdAutoscrapeCleanupRequestPost(gameId: number, request: RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await AutolocalizationApiAxiosParamCreator(configuration).v1AutolocalizationGamesGameIdAutoscrapeCleanupRequestPost(gameId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutolocalizationGamesGameIdGet(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGameAutolocalizationInformationResponse>> {
            const localVarAxiosArgs = await AutolocalizationApiAxiosParamCreator(configuration).v1AutolocalizationGamesGameIdGet(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Ingests entries for auto localization.
         * @param {number} gameId The game id.
         * @param {RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest} request The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutolocalizationGamesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await AutolocalizationApiAxiosParamCreator(configuration).v1AutolocalizationGamesGameIdPatch(gameId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets a game\'s auto-localization related settings
         * @param {number} gameId The id of the game.
         * @param {RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest} request The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutolocalizationGamesGameIdSettingsPatch(gameId: number, request: RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await AutolocalizationApiAxiosParamCreator(configuration).v1AutolocalizationGamesGameIdSettingsPatch(gameId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Metadata for AutoLocalization Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutolocalizationMetadataGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiAutoLocalizationMetadataResponse>> {
            const localVarAxiosArgs = await AutolocalizationApiAxiosParamCreator(configuration).v1AutolocalizationMetadataGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AutolocalizationApi - factory interface
 * @export
 */
export const AutolocalizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} gameId 
         * @param {RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdAutolocalizationtablePatch(gameId: number, request: RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest, options?: any): AxiosPromise<object> {
            return AutolocalizationApiFp(configuration).v1AutolocalizationGamesGameIdAutolocalizationtablePatch(gameId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdAutolocalizationtablePost(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGameAutolocalizationInformationResponse> {
            return AutolocalizationApiFp(configuration).v1AutolocalizationGamesGameIdAutolocalizationtablePost(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enqueues an event to Flush the autoscraped entries which doesnt have translations
         * @param {number} gameId The id of the game.
         * @param {RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdAutoscrapeCleanupRequestPost(gameId: number, request: RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest, options?: any): AxiosPromise<object> {
            return AutolocalizationApiFp(configuration).v1AutolocalizationGamesGameIdAutoscrapeCleanupRequestPost(gameId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdGet(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGameAutolocalizationInformationResponse> {
            return AutolocalizationApiFp(configuration).v1AutolocalizationGamesGameIdGet(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ingests entries for auto localization.
         * @param {number} gameId The game id.
         * @param {RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest} request The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest, options?: any): AxiosPromise<object> {
            return AutolocalizationApiFp(configuration).v1AutolocalizationGamesGameIdPatch(gameId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets a game\'s auto-localization related settings
         * @param {number} gameId The id of the game.
         * @param {RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest} request The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationGamesGameIdSettingsPatch(gameId: number, request: RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest, options?: any): AxiosPromise<object> {
            return AutolocalizationApiFp(configuration).v1AutolocalizationGamesGameIdSettingsPatch(gameId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Metadata for AutoLocalization Configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutolocalizationMetadataGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiAutoLocalizationMetadataResponse> {
            return AutolocalizationApiFp(configuration).v1AutolocalizationMetadataGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutolocalizationApi - interface
 * @export
 * @interface AutolocalizationApi
 */
export interface AutolocalizationApiInterface {
    /**
     * 
     * @param {number} gameId 
     * @param {RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApiInterface
     */
    v1AutolocalizationGamesGameIdAutolocalizationtablePatch(gameId: number, request: RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {number} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApiInterface
     */
    v1AutolocalizationGamesGameIdAutolocalizationtablePost(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGameAutolocalizationInformationResponse>;

    /**
     * 
     * @summary Enqueues an event to Flush the autoscraped entries which doesnt have translations
     * @param {number} gameId The id of the game.
     * @param {RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApiInterface
     */
    v1AutolocalizationGamesGameIdAutoscrapeCleanupRequestPost(gameId: number, request: RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {number} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApiInterface
     */
    v1AutolocalizationGamesGameIdGet(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGameAutolocalizationInformationResponse>;

    /**
     * 
     * @summary Ingests entries for auto localization.
     * @param {number} gameId The game id.
     * @param {RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest} request The request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApiInterface
     */
    v1AutolocalizationGamesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Sets a game\'s auto-localization related settings
     * @param {number} gameId The id of the game.
     * @param {RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest} request The request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApiInterface
     */
    v1AutolocalizationGamesGameIdSettingsPatch(gameId: number, request: RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Metadata for AutoLocalization Configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApiInterface
     */
    v1AutolocalizationMetadataGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiAutoLocalizationMetadataResponse>;

}

/**
 * AutolocalizationApi - object-oriented interface
 * @export
 * @class AutolocalizationApi
 * @extends {BaseAPI}
 */
export class AutolocalizationApi extends BaseAPI implements AutolocalizationApiInterface {
    /**
     * 
     * @param {number} gameId 
     * @param {RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApi
     */
    public v1AutolocalizationGamesGameIdAutolocalizationtablePatch(gameId: number, request: RobloxGameInternationalizationApiSetAutolocalizationTableForGameRequest, options?: any) {
        return AutolocalizationApiFp(this.configuration).v1AutolocalizationGamesGameIdAutolocalizationtablePatch(gameId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApi
     */
    public v1AutolocalizationGamesGameIdAutolocalizationtablePost(gameId: number, options?: any) {
        return AutolocalizationApiFp(this.configuration).v1AutolocalizationGamesGameIdAutolocalizationtablePost(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enqueues an event to Flush the autoscraped entries which doesnt have translations
     * @param {number} gameId The id of the game.
     * @param {RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApi
     */
    public v1AutolocalizationGamesGameIdAutoscrapeCleanupRequestPost(gameId: number, request: RobloxGameInternationalizationApiRaiseEventForAutoScrapedEntriesCleanupRequest, options?: any) {
        return AutolocalizationApiFp(this.configuration).v1AutolocalizationGamesGameIdAutoscrapeCleanupRequestPost(gameId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApi
     */
    public v1AutolocalizationGamesGameIdGet(gameId: number, options?: any) {
        return AutolocalizationApiFp(this.configuration).v1AutolocalizationGamesGameIdGet(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ingests entries for auto localization.
     * @param {number} gameId The game id.
     * @param {RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest} request The request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApi
     */
    public v1AutolocalizationGamesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiIngestAutoScrapedContentForGameRequest, options?: any) {
        return AutolocalizationApiFp(this.configuration).v1AutolocalizationGamesGameIdPatch(gameId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets a game\'s auto-localization related settings
     * @param {number} gameId The id of the game.
     * @param {RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest} request The request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApi
     */
    public v1AutolocalizationGamesGameIdSettingsPatch(gameId: number, request: RobloxGameInternationalizationApiSetAutolocalizationSettingsForGameRequest, options?: any) {
        return AutolocalizationApiFp(this.configuration).v1AutolocalizationGamesGameIdSettingsPatch(gameId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Metadata for AutoLocalization Configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutolocalizationApi
     */
    public v1AutolocalizationMetadataGet(options?: any) {
        return AutolocalizationApiFp(this.configuration).v1AutolocalizationMetadataGet(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * AutomaticTranslationApi - axios parameter creator
 * @export
 */
export const AutomaticTranslationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Checks if automatic translation can be enabled for a game.  The user must still have proper permissions for the game to get this info.
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutomaticTranslationGamesGameIdFeatureStatusGet: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1AutomaticTranslationGamesGameIdFeatureStatusGet.');
            }
            const localVarPath = `/v1/automatic-translation/games/{gameId}/feature-status`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the automatic translation quota info for a game.  The user must still have proper permissions for the game to get this info.
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutomaticTranslationGamesGameIdQuotaGet: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1AutomaticTranslationGamesGameIdQuotaGet.');
            }
            const localVarPath = `/v1/automatic-translation/games/{gameId}/quota`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the requested target languages are allowed for automatic translation based on the source language.  If there are no requested target languages, then all allowed target languages for the source language will be returned.
         * @param {string} languageCode The source language.
         * @param {Array<string>} [targetLanguages] Optional target languages. If not passed in, all allowed target languages for the source language will be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutomaticTranslationLanguagesLanguageCodeTargetLanguagesGet: async (languageCode: string, targetLanguages?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1AutomaticTranslationLanguagesLanguageCodeTargetLanguagesGet.');
            }
            const localVarPath = `/v1/automatic-translation/languages/{languageCode}/target-languages`
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (targetLanguages) {
                localVarQueryParameter['targetLanguages'] = targetLanguages;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutomaticTranslationApi - functional programming interface
 * @export
 */
export const AutomaticTranslationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Checks if automatic translation can be enabled for a game.  The user must still have proper permissions for the game to get this info.
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutomaticTranslationGamesGameIdFeatureStatusGet(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetAutomaticTranslationFeatureStatusForGameResponse>> {
            const localVarAxiosArgs = await AutomaticTranslationApiAxiosParamCreator(configuration).v1AutomaticTranslationGamesGameIdFeatureStatusGet(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns the automatic translation quota info for a game.  The user must still have proper permissions for the game to get this info.
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutomaticTranslationGamesGameIdQuotaGet(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetAutomaticTranslationQuotaForGameResponse>> {
            const localVarAxiosArgs = await AutomaticTranslationApiAxiosParamCreator(configuration).v1AutomaticTranslationGamesGameIdQuotaGet(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Checks if the requested target languages are allowed for automatic translation based on the source language.  If there are no requested target languages, then all allowed target languages for the source language will be returned.
         * @param {string} languageCode The source language.
         * @param {Array<string>} [targetLanguages] Optional target languages. If not passed in, all allowed target languages for the source language will be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1AutomaticTranslationLanguagesLanguageCodeTargetLanguagesGet(languageCode: string, targetLanguages?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetAllowedAutomaticTranslationStatusForLanguagesResponse>> {
            const localVarAxiosArgs = await AutomaticTranslationApiAxiosParamCreator(configuration).v1AutomaticTranslationLanguagesLanguageCodeTargetLanguagesGet(languageCode, targetLanguages, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AutomaticTranslationApi - factory interface
 * @export
 */
export const AutomaticTranslationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Checks if automatic translation can be enabled for a game.  The user must still have proper permissions for the game to get this info.
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutomaticTranslationGamesGameIdFeatureStatusGet(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetAutomaticTranslationFeatureStatusForGameResponse> {
            return AutomaticTranslationApiFp(configuration).v1AutomaticTranslationGamesGameIdFeatureStatusGet(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the automatic translation quota info for a game.  The user must still have proper permissions for the game to get this info.
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutomaticTranslationGamesGameIdQuotaGet(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetAutomaticTranslationQuotaForGameResponse> {
            return AutomaticTranslationApiFp(configuration).v1AutomaticTranslationGamesGameIdQuotaGet(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks if the requested target languages are allowed for automatic translation based on the source language.  If there are no requested target languages, then all allowed target languages for the source language will be returned.
         * @param {string} languageCode The source language.
         * @param {Array<string>} [targetLanguages] Optional target languages. If not passed in, all allowed target languages for the source language will be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1AutomaticTranslationLanguagesLanguageCodeTargetLanguagesGet(languageCode: string, targetLanguages?: Array<string>, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetAllowedAutomaticTranslationStatusForLanguagesResponse> {
            return AutomaticTranslationApiFp(configuration).v1AutomaticTranslationLanguagesLanguageCodeTargetLanguagesGet(languageCode, targetLanguages, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutomaticTranslationApi - interface
 * @export
 * @interface AutomaticTranslationApi
 */
export interface AutomaticTranslationApiInterface {
    /**
     * 
     * @summary Checks if automatic translation can be enabled for a game.  The user must still have proper permissions for the game to get this info.
     * @param {number} gameId The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomaticTranslationApiInterface
     */
    v1AutomaticTranslationGamesGameIdFeatureStatusGet(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetAutomaticTranslationFeatureStatusForGameResponse>;

    /**
     * 
     * @summary Returns the automatic translation quota info for a game.  The user must still have proper permissions for the game to get this info.
     * @param {number} gameId The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomaticTranslationApiInterface
     */
    v1AutomaticTranslationGamesGameIdQuotaGet(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetAutomaticTranslationQuotaForGameResponse>;

    /**
     * 
     * @summary Checks if the requested target languages are allowed for automatic translation based on the source language.  If there are no requested target languages, then all allowed target languages for the source language will be returned.
     * @param {string} languageCode The source language.
     * @param {Array<string>} [targetLanguages] Optional target languages. If not passed in, all allowed target languages for the source language will be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomaticTranslationApiInterface
     */
    v1AutomaticTranslationLanguagesLanguageCodeTargetLanguagesGet(languageCode: string, targetLanguages?: Array<string>, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetAllowedAutomaticTranslationStatusForLanguagesResponse>;

}

/**
 * AutomaticTranslationApi - object-oriented interface
 * @export
 * @class AutomaticTranslationApi
 * @extends {BaseAPI}
 */
export class AutomaticTranslationApi extends BaseAPI implements AutomaticTranslationApiInterface {
    /**
     * 
     * @summary Checks if automatic translation can be enabled for a game.  The user must still have proper permissions for the game to get this info.
     * @param {number} gameId The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomaticTranslationApi
     */
    public v1AutomaticTranslationGamesGameIdFeatureStatusGet(gameId: number, options?: any) {
        return AutomaticTranslationApiFp(this.configuration).v1AutomaticTranslationGamesGameIdFeatureStatusGet(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the automatic translation quota info for a game.  The user must still have proper permissions for the game to get this info.
     * @param {number} gameId The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomaticTranslationApi
     */
    public v1AutomaticTranslationGamesGameIdQuotaGet(gameId: number, options?: any) {
        return AutomaticTranslationApiFp(this.configuration).v1AutomaticTranslationGamesGameIdQuotaGet(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks if the requested target languages are allowed for automatic translation based on the source language.  If there are no requested target languages, then all allowed target languages for the source language will be returned.
     * @param {string} languageCode The source language.
     * @param {Array<string>} [targetLanguages] Optional target languages. If not passed in, all allowed target languages for the source language will be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutomaticTranslationApi
     */
    public v1AutomaticTranslationLanguagesLanguageCodeTargetLanguagesGet(languageCode: string, targetLanguages?: Array<string>, options?: any) {
        return AutomaticTranslationApiFp(this.configuration).v1AutomaticTranslationLanguagesLanguageCodeTargetLanguagesGet(languageCode, targetLanguages, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * BadgeApi - axios parameter creator
 * @export
 */
export const BadgeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update localized description of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the description to update
         * @param {RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch: async (badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch.');
            }
            const localVarPath = `/v1/badges/{badgeId}/description/language-codes/{languageCode}`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all icons for a badge
         * @param {number} badgeId The id of the badge
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdIconsGet: async (badgeId: number, width?: number, height?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling v1BadgesBadgeIdIconsGet.');
            }
            const localVarPath = `/v1/badges/{badgeId}/icons`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a localized icon from a badge
         * @param {number} badgeId The id of the badge
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdIconsLanguageCodesLanguageCodeDelete: async (badgeId: number, languageCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling v1BadgesBadgeIdIconsLanguageCodesLanguageCodeDelete.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1BadgesBadgeIdIconsLanguageCodesLanguageCodeDelete.');
            }
            const localVarPath = `/v1/badges/{badgeId}/icons/language-codes/{languageCode}`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a badge\'s icon
         * @param {number} badgeId The id of the badge
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdIconsLanguageCodesLanguageCodePost: async (badgeId: number, languageCode: string, requestFiles?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling v1BadgesBadgeIdIconsLanguageCodesLanguageCodePost.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1BadgesBadgeIdIconsLanguageCodesLanguageCodePost.');
            }
            const localVarPath = `/v1/badges/{badgeId}/icons/language-codes/{languageCode}`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (requestFiles !== undefined) { 
                localVarFormParams.append('request.files', requestFiles as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} badgeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdNameDescriptionGet: async (badgeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling v1BadgesBadgeIdNameDescriptionGet.');
            }
            const localVarPath = `/v1/badges/{badgeId}/name-description`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete localized name and description of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the name and description to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodeDelete: async (badgeId: number, languageCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodeDelete.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodeDelete.');
            }
            const localVarPath = `/v1/badges/{badgeId}/name-description/language-codes/{languageCode}`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update localized name and description of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the name and description to Update
         * @param {RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch: async (badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch.');
            }
            const localVarPath = `/v1/badges/{badgeId}/name-description/language-codes/{languageCode}`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update localized name of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the name to update
         * @param {RobloxGameInternationalizationApiUpdateBadgeNameRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch: async (badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeNameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'badgeId' is not null or undefined
            if (badgeId === null || badgeId === undefined) {
                throw new RequiredError('badgeId','Required parameter badgeId was null or undefined when calling v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch.');
            }
            const localVarPath = `/v1/badges/{badgeId}/name/language-codes/{languageCode}`
                .replace(`{${"badgeId"}}`, encodeURIComponent(String(badgeId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BadgeApi - functional programming interface
 * @export
 */
export const BadgeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update localized description of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the description to update
         * @param {RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateBadgeDescriptionResponse>> {
            const localVarAxiosArgs = await BadgeApiAxiosParamCreator(configuration).v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch(badgeId, languageCode, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all icons for a badge
         * @param {number} badgeId The id of the badge
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BadgesBadgeIdIconsGet(badgeId: number, width?: number, height?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetBadgeIconResponse>> {
            const localVarAxiosArgs = await BadgeApiAxiosParamCreator(configuration).v1BadgesBadgeIdIconsGet(badgeId, width, height, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a localized icon from a badge
         * @param {number} badgeId The id of the badge
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BadgesBadgeIdIconsLanguageCodesLanguageCodeDelete(badgeId: number, languageCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await BadgeApiAxiosParamCreator(configuration).v1BadgesBadgeIdIconsLanguageCodesLanguageCodeDelete(badgeId, languageCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a badge\'s icon
         * @param {number} badgeId The id of the badge
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BadgesBadgeIdIconsLanguageCodesLanguageCodePost(badgeId: number, languageCode: string, requestFiles?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await BadgeApiAxiosParamCreator(configuration).v1BadgesBadgeIdIconsLanguageCodesLanguageCodePost(badgeId, languageCode, requestFiles, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} badgeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BadgesBadgeIdNameDescriptionGet(badgeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription>> {
            const localVarAxiosArgs = await BadgeApiAxiosParamCreator(configuration).v1BadgesBadgeIdNameDescriptionGet(badgeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete localized name and description of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the name and description to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodeDelete(badgeId: number, languageCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await BadgeApiAxiosParamCreator(configuration).v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodeDelete(badgeId, languageCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update localized name and description of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the name and description to Update
         * @param {RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateBadgeNameDescriptionResponse>> {
            const localVarAxiosArgs = await BadgeApiAxiosParamCreator(configuration).v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch(badgeId, languageCode, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update localized name of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the name to update
         * @param {RobloxGameInternationalizationApiUpdateBadgeNameRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeNameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateBadgeNameResponse>> {
            const localVarAxiosArgs = await BadgeApiAxiosParamCreator(configuration).v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch(badgeId, languageCode, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BadgeApi - factory interface
 * @export
 */
export const BadgeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Update localized description of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the description to update
         * @param {RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateBadgeDescriptionResponse> {
            return BadgeApiFp(configuration).v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch(badgeId, languageCode, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all icons for a badge
         * @param {number} badgeId The id of the badge
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdIconsGet(badgeId: number, width?: number, height?: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetBadgeIconResponse> {
            return BadgeApiFp(configuration).v1BadgesBadgeIdIconsGet(badgeId, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a localized icon from a badge
         * @param {number} badgeId The id of the badge
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdIconsLanguageCodesLanguageCodeDelete(badgeId: number, languageCode: string, options?: any): AxiosPromise<object> {
            return BadgeApiFp(configuration).v1BadgesBadgeIdIconsLanguageCodesLanguageCodeDelete(badgeId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a badge\'s icon
         * @param {number} badgeId The id of the badge
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdIconsLanguageCodesLanguageCodePost(badgeId: number, languageCode: string, requestFiles?: any, options?: any): AxiosPromise<object> {
            return BadgeApiFp(configuration).v1BadgesBadgeIdIconsLanguageCodesLanguageCodePost(badgeId, languageCode, requestFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} badgeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdNameDescriptionGet(badgeId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription> {
            return BadgeApiFp(configuration).v1BadgesBadgeIdNameDescriptionGet(badgeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete localized name and description of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the name and description to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodeDelete(badgeId: number, languageCode: string, options?: any): AxiosPromise<object> {
            return BadgeApiFp(configuration).v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodeDelete(badgeId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update localized name and description of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the name and description to Update
         * @param {RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateBadgeNameDescriptionResponse> {
            return BadgeApiFp(configuration).v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch(badgeId, languageCode, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update localized name of a badge
         * @param {number} badgeId The badge id
         * @param {string} languageCode The language code of the name to update
         * @param {RobloxGameInternationalizationApiUpdateBadgeNameRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeNameRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateBadgeNameResponse> {
            return BadgeApiFp(configuration).v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch(badgeId, languageCode, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BadgeApi - interface
 * @export
 * @interface BadgeApi
 */
export interface BadgeApiInterface {
    /**
     * 
     * @summary Update localized description of a badge
     * @param {number} badgeId The badge id
     * @param {string} languageCode The language code of the description to update
     * @param {RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateBadgeDescriptionResponse>;

    /**
     * 
     * @summary Get all icons for a badge
     * @param {number} badgeId The id of the badge
     * @param {number} [width] The width of the icon to request
     * @param {number} [height] The height of the icon to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    v1BadgesBadgeIdIconsGet(badgeId: number, width?: number, height?: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetBadgeIconResponse>;

    /**
     * 
     * @summary Delete a localized icon from a badge
     * @param {number} badgeId The id of the badge
     * @param {string} languageCode The language code of the localized icon to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    v1BadgesBadgeIdIconsLanguageCodesLanguageCodeDelete(badgeId: number, languageCode: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Update a badge\'s icon
     * @param {number} badgeId The id of the badge
     * @param {string} languageCode The language code of this icon to update
     * @param {any} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    v1BadgesBadgeIdIconsLanguageCodesLanguageCodePost(badgeId: number, languageCode: string, requestFiles?: any, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {number} badgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    v1BadgesBadgeIdNameDescriptionGet(badgeId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription>;

    /**
     * 
     * @summary Delete localized name and description of a badge
     * @param {number} badgeId The badge id
     * @param {string} languageCode The language code of the name and description to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodeDelete(badgeId: number, languageCode: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Update localized name and description of a badge
     * @param {number} badgeId The badge id
     * @param {string} languageCode The language code of the name and description to Update
     * @param {RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateBadgeNameDescriptionResponse>;

    /**
     * 
     * @summary Update localized name of a badge
     * @param {number} badgeId The badge id
     * @param {string} languageCode The language code of the name to update
     * @param {RobloxGameInternationalizationApiUpdateBadgeNameRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApiInterface
     */
    v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeNameRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateBadgeNameResponse>;

}

/**
 * BadgeApi - object-oriented interface
 * @export
 * @class BadgeApi
 * @extends {BaseAPI}
 */
export class BadgeApi extends BaseAPI implements BadgeApiInterface {
    /**
     * 
     * @summary Update localized description of a badge
     * @param {number} badgeId The badge id
     * @param {string} languageCode The language code of the description to update
     * @param {RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeDescriptionRequest, options?: any) {
        return BadgeApiFp(this.configuration).v1BadgesBadgeIdDescriptionLanguageCodesLanguageCodePatch(badgeId, languageCode, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all icons for a badge
     * @param {number} badgeId The id of the badge
     * @param {number} [width] The width of the icon to request
     * @param {number} [height] The height of the icon to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public v1BadgesBadgeIdIconsGet(badgeId: number, width?: number, height?: number, options?: any) {
        return BadgeApiFp(this.configuration).v1BadgesBadgeIdIconsGet(badgeId, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a localized icon from a badge
     * @param {number} badgeId The id of the badge
     * @param {string} languageCode The language code of the localized icon to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public v1BadgesBadgeIdIconsLanguageCodesLanguageCodeDelete(badgeId: number, languageCode: string, options?: any) {
        return BadgeApiFp(this.configuration).v1BadgesBadgeIdIconsLanguageCodesLanguageCodeDelete(badgeId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a badge\'s icon
     * @param {number} badgeId The id of the badge
     * @param {string} languageCode The language code of this icon to update
     * @param {any} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public v1BadgesBadgeIdIconsLanguageCodesLanguageCodePost(badgeId: number, languageCode: string, requestFiles?: any, options?: any) {
        return BadgeApiFp(this.configuration).v1BadgesBadgeIdIconsLanguageCodesLanguageCodePost(badgeId, languageCode, requestFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} badgeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public v1BadgesBadgeIdNameDescriptionGet(badgeId: number, options?: any) {
        return BadgeApiFp(this.configuration).v1BadgesBadgeIdNameDescriptionGet(badgeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete localized name and description of a badge
     * @param {number} badgeId The badge id
     * @param {string} languageCode The language code of the name and description to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodeDelete(badgeId: number, languageCode: string, options?: any) {
        return BadgeApiFp(this.configuration).v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodeDelete(badgeId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update localized name and description of a badge
     * @param {number} badgeId The badge id
     * @param {string} languageCode The language code of the name and description to Update
     * @param {RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeNameDescriptionRequest, options?: any) {
        return BadgeApiFp(this.configuration).v1BadgesBadgeIdNameDescriptionLanguageCodesLanguageCodePatch(badgeId, languageCode, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update localized name of a badge
     * @param {number} badgeId The badge id
     * @param {string} languageCode The language code of the name to update
     * @param {RobloxGameInternationalizationApiUpdateBadgeNameRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BadgeApi
     */
    public v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch(badgeId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateBadgeNameRequest, options?: any) {
        return BadgeApiFp(this.configuration).v1BadgesBadgeIdNameLanguageCodesLanguageCodePatch(badgeId, languageCode, request, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * DeveloperProductApi - axios parameter creator
 * @export
 */
export const DeveloperProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update localized description of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the description to update
         * @param {RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch: async (developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerProductId' is not null or undefined
            if (developerProductId === null || developerProductId === undefined) {
                throw new RequiredError('developerProductId','Required parameter developerProductId was null or undefined when calling v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch.');
            }
            const localVarPath = `/v1/developer-products/{developerProductId}/description/language-codes/{languageCode}`
                .replace(`{${"developerProductId"}}`, encodeURIComponent(String(developerProductId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all icons for a developer product
         * @param {number} developerProductId The id of the developer product
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdIconsGet: async (developerProductId: number, width?: number, height?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerProductId' is not null or undefined
            if (developerProductId === null || developerProductId === undefined) {
                throw new RequiredError('developerProductId','Required parameter developerProductId was null or undefined when calling v1DeveloperProductsDeveloperProductIdIconsGet.');
            }
            const localVarPath = `/v1/developer-products/{developerProductId}/icons`
                .replace(`{${"developerProductId"}}`, encodeURIComponent(String(developerProductId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a localized icon from a developer product
         * @param {number} developerProductId The id of the developer product
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodeDelete: async (developerProductId: number, languageCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerProductId' is not null or undefined
            if (developerProductId === null || developerProductId === undefined) {
                throw new RequiredError('developerProductId','Required parameter developerProductId was null or undefined when calling v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodeDelete.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodeDelete.');
            }
            const localVarPath = `/v1/developer-products/{developerProductId}/icons/language-codes/{languageCode}`
                .replace(`{${"developerProductId"}}`, encodeURIComponent(String(developerProductId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a developer product\'s icon
         * @param {number} developerProductId The id of the developer product
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodePost: async (developerProductId: number, languageCode: string, requestFiles?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerProductId' is not null or undefined
            if (developerProductId === null || developerProductId === undefined) {
                throw new RequiredError('developerProductId','Required parameter developerProductId was null or undefined when calling v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodePost.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodePost.');
            }
            const localVarPath = `/v1/developer-products/{developerProductId}/icons/language-codes/{languageCode}`
                .replace(`{${"developerProductId"}}`, encodeURIComponent(String(developerProductId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (requestFiles !== undefined) { 
                localVarFormParams.append('request.files', requestFiles as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all names and descriptions of a developer product
         * @param {number} developerProductId The developer product Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdNameDescriptionGet: async (developerProductId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerProductId' is not null or undefined
            if (developerProductId === null || developerProductId === undefined) {
                throw new RequiredError('developerProductId','Required parameter developerProductId was null or undefined when calling v1DeveloperProductsDeveloperProductIdNameDescriptionGet.');
            }
            const localVarPath = `/v1/developer-products/{developerProductId}/name-description`
                .replace(`{${"developerProductId"}}`, encodeURIComponent(String(developerProductId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete localized name and description of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the name and description to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodeDelete: async (developerProductId: number, languageCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerProductId' is not null or undefined
            if (developerProductId === null || developerProductId === undefined) {
                throw new RequiredError('developerProductId','Required parameter developerProductId was null or undefined when calling v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodeDelete.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodeDelete.');
            }
            const localVarPath = `/v1/developer-products/{developerProductId}/name-description/language-codes/{languageCode}`
                .replace(`{${"developerProductId"}}`, encodeURIComponent(String(developerProductId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update localized name and description of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the name and description to Update
         * @param {RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch: async (developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerProductId' is not null or undefined
            if (developerProductId === null || developerProductId === undefined) {
                throw new RequiredError('developerProductId','Required parameter developerProductId was null or undefined when calling v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch.');
            }
            const localVarPath = `/v1/developer-products/{developerProductId}/name-description/language-codes/{languageCode}`
                .replace(`{${"developerProductId"}}`, encodeURIComponent(String(developerProductId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update localized name of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the name to update
         * @param {RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch: async (developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'developerProductId' is not null or undefined
            if (developerProductId === null || developerProductId === undefined) {
                throw new RequiredError('developerProductId','Required parameter developerProductId was null or undefined when calling v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch.');
            }
            const localVarPath = `/v1/developer-products/{developerProductId}/name/language-codes/{languageCode}`
                .replace(`{${"developerProductId"}}`, encodeURIComponent(String(developerProductId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeveloperProductApi - functional programming interface
 * @export
 */
export const DeveloperProductApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update localized description of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the description to update
         * @param {RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionResponse>> {
            const localVarAxiosArgs = await DeveloperProductApiAxiosParamCreator(configuration).v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch(developerProductId, languageCode, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all icons for a developer product
         * @param {number} developerProductId The id of the developer product
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeveloperProductsDeveloperProductIdIconsGet(developerProductId: number, width?: number, height?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetDeveloperProductIconResponse>> {
            const localVarAxiosArgs = await DeveloperProductApiAxiosParamCreator(configuration).v1DeveloperProductsDeveloperProductIdIconsGet(developerProductId, width, height, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a localized icon from a developer product
         * @param {number} developerProductId The id of the developer product
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodeDelete(developerProductId: number, languageCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DeveloperProductApiAxiosParamCreator(configuration).v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodeDelete(developerProductId, languageCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a developer product\'s icon
         * @param {number} developerProductId The id of the developer product
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodePost(developerProductId: number, languageCode: string, requestFiles?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DeveloperProductApiAxiosParamCreator(configuration).v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodePost(developerProductId, languageCode, requestFiles, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all names and descriptions of a developer product
         * @param {number} developerProductId The developer product Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeveloperProductsDeveloperProductIdNameDescriptionGet(developerProductId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription>> {
            const localVarAxiosArgs = await DeveloperProductApiAxiosParamCreator(configuration).v1DeveloperProductsDeveloperProductIdNameDescriptionGet(developerProductId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete localized name and description of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the name and description to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodeDelete(developerProductId: number, languageCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DeveloperProductApiAxiosParamCreator(configuration).v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodeDelete(developerProductId, languageCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update localized name and description of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the name and description to Update
         * @param {RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionResponse>> {
            const localVarAxiosArgs = await DeveloperProductApiAxiosParamCreator(configuration).v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch(developerProductId, languageCode, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update localized name of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the name to update
         * @param {RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateDeveloperProductNameResponse>> {
            const localVarAxiosArgs = await DeveloperProductApiAxiosParamCreator(configuration).v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch(developerProductId, languageCode, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DeveloperProductApi - factory interface
 * @export
 */
export const DeveloperProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Update localized description of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the description to update
         * @param {RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionResponse> {
            return DeveloperProductApiFp(configuration).v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch(developerProductId, languageCode, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all icons for a developer product
         * @param {number} developerProductId The id of the developer product
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdIconsGet(developerProductId: number, width?: number, height?: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetDeveloperProductIconResponse> {
            return DeveloperProductApiFp(configuration).v1DeveloperProductsDeveloperProductIdIconsGet(developerProductId, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a localized icon from a developer product
         * @param {number} developerProductId The id of the developer product
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodeDelete(developerProductId: number, languageCode: string, options?: any): AxiosPromise<object> {
            return DeveloperProductApiFp(configuration).v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodeDelete(developerProductId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a developer product\'s icon
         * @param {number} developerProductId The id of the developer product
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodePost(developerProductId: number, languageCode: string, requestFiles?: any, options?: any): AxiosPromise<object> {
            return DeveloperProductApiFp(configuration).v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodePost(developerProductId, languageCode, requestFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all names and descriptions of a developer product
         * @param {number} developerProductId The developer product Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdNameDescriptionGet(developerProductId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription> {
            return DeveloperProductApiFp(configuration).v1DeveloperProductsDeveloperProductIdNameDescriptionGet(developerProductId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete localized name and description of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the name and description to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodeDelete(developerProductId: number, languageCode: string, options?: any): AxiosPromise<object> {
            return DeveloperProductApiFp(configuration).v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodeDelete(developerProductId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update localized name and description of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the name and description to Update
         * @param {RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionResponse> {
            return DeveloperProductApiFp(configuration).v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch(developerProductId, languageCode, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update localized name of a developer product
         * @param {number} developerProductId The developer product id
         * @param {string} languageCode The language code of the name to update
         * @param {RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateDeveloperProductNameResponse> {
            return DeveloperProductApiFp(configuration).v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch(developerProductId, languageCode, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeveloperProductApi - interface
 * @export
 * @interface DeveloperProductApi
 */
export interface DeveloperProductApiInterface {
    /**
     * 
     * @summary Update localized description of a developer product
     * @param {number} developerProductId The developer product id
     * @param {string} languageCode The language code of the description to update
     * @param {RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApiInterface
     */
    v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionResponse>;

    /**
     * 
     * @summary Get all icons for a developer product
     * @param {number} developerProductId The id of the developer product
     * @param {number} [width] The width of the icon to request
     * @param {number} [height] The height of the icon to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApiInterface
     */
    v1DeveloperProductsDeveloperProductIdIconsGet(developerProductId: number, width?: number, height?: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetDeveloperProductIconResponse>;

    /**
     * 
     * @summary Delete a localized icon from a developer product
     * @param {number} developerProductId The id of the developer product
     * @param {string} languageCode The language code of the localized icon to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApiInterface
     */
    v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodeDelete(developerProductId: number, languageCode: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Update a developer product\'s icon
     * @param {number} developerProductId The id of the developer product
     * @param {string} languageCode The language code of this icon to update
     * @param {any} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApiInterface
     */
    v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodePost(developerProductId: number, languageCode: string, requestFiles?: any, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Get all names and descriptions of a developer product
     * @param {number} developerProductId The developer product Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApiInterface
     */
    v1DeveloperProductsDeveloperProductIdNameDescriptionGet(developerProductId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription>;

    /**
     * 
     * @summary Delete localized name and description of a developer product
     * @param {number} developerProductId The developer product id
     * @param {string} languageCode The language code of the name and description to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApiInterface
     */
    v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodeDelete(developerProductId: number, languageCode: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Update localized name and description of a developer product
     * @param {number} developerProductId The developer product id
     * @param {string} languageCode The language code of the name and description to Update
     * @param {RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApiInterface
     */
    v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionResponse>;

    /**
     * 
     * @summary Update localized name of a developer product
     * @param {number} developerProductId The developer product id
     * @param {string} languageCode The language code of the name to update
     * @param {RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApiInterface
     */
    v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateDeveloperProductNameResponse>;

}

/**
 * DeveloperProductApi - object-oriented interface
 * @export
 * @class DeveloperProductApi
 * @extends {BaseAPI}
 */
export class DeveloperProductApi extends BaseAPI implements DeveloperProductApiInterface {
    /**
     * 
     * @summary Update localized description of a developer product
     * @param {number} developerProductId The developer product id
     * @param {string} languageCode The language code of the description to update
     * @param {RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApi
     */
    public v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductDescriptionRequest, options?: any) {
        return DeveloperProductApiFp(this.configuration).v1DeveloperProductsDeveloperProductIdDescriptionLanguageCodesLanguageCodePatch(developerProductId, languageCode, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all icons for a developer product
     * @param {number} developerProductId The id of the developer product
     * @param {number} [width] The width of the icon to request
     * @param {number} [height] The height of the icon to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApi
     */
    public v1DeveloperProductsDeveloperProductIdIconsGet(developerProductId: number, width?: number, height?: number, options?: any) {
        return DeveloperProductApiFp(this.configuration).v1DeveloperProductsDeveloperProductIdIconsGet(developerProductId, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a localized icon from a developer product
     * @param {number} developerProductId The id of the developer product
     * @param {string} languageCode The language code of the localized icon to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApi
     */
    public v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodeDelete(developerProductId: number, languageCode: string, options?: any) {
        return DeveloperProductApiFp(this.configuration).v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodeDelete(developerProductId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a developer product\'s icon
     * @param {number} developerProductId The id of the developer product
     * @param {string} languageCode The language code of this icon to update
     * @param {any} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApi
     */
    public v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodePost(developerProductId: number, languageCode: string, requestFiles?: any, options?: any) {
        return DeveloperProductApiFp(this.configuration).v1DeveloperProductsDeveloperProductIdIconsLanguageCodesLanguageCodePost(developerProductId, languageCode, requestFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all names and descriptions of a developer product
     * @param {number} developerProductId The developer product Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApi
     */
    public v1DeveloperProductsDeveloperProductIdNameDescriptionGet(developerProductId: number, options?: any) {
        return DeveloperProductApiFp(this.configuration).v1DeveloperProductsDeveloperProductIdNameDescriptionGet(developerProductId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete localized name and description of a developer product
     * @param {number} developerProductId The developer product id
     * @param {string} languageCode The language code of the name and description to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApi
     */
    public v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodeDelete(developerProductId: number, languageCode: string, options?: any) {
        return DeveloperProductApiFp(this.configuration).v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodeDelete(developerProductId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update localized name and description of a developer product
     * @param {number} developerProductId The developer product id
     * @param {string} languageCode The language code of the name and description to Update
     * @param {RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApi
     */
    public v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductNameDescriptionRequest, options?: any) {
        return DeveloperProductApiFp(this.configuration).v1DeveloperProductsDeveloperProductIdNameDescriptionLanguageCodesLanguageCodePatch(developerProductId, languageCode, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update localized name of a developer product
     * @param {number} developerProductId The developer product id
     * @param {string} languageCode The language code of the name to update
     * @param {RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperProductApi
     */
    public v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch(developerProductId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateDeveloperProductNameRequest, options?: any) {
        return DeveloperProductApiFp(this.configuration).v1DeveloperProductsDeveloperProductIdNameLanguageCodesLanguageCodePatch(developerProductId, languageCode, request, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * GameIconApi - axios parameter creator
 * @export
 */
export const GameIconApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all icons for a game
         * @param {number} gameId The id of the game
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameIconGamesGameIdGet: async (gameId: number, width?: number, height?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1GameIconGamesGameIdGet.');
            }
            const localVarPath = `/v1/game-icon/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a localized icon from a game
         * @param {number} gameId The id of the game
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameIconGamesGameIdLanguageCodesLanguageCodeDelete: async (gameId: number, languageCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1GameIconGamesGameIdLanguageCodesLanguageCodeDelete.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GameIconGamesGameIdLanguageCodesLanguageCodeDelete.');
            }
            const localVarPath = `/v1/game-icon/games/{gameId}/language-codes/{languageCode}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a game\'s icon
         * @param {number} gameId The id of the game
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameIconGamesGameIdLanguageCodesLanguageCodePost: async (gameId: number, languageCode: string, requestFiles?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1GameIconGamesGameIdLanguageCodesLanguageCodePost.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GameIconGamesGameIdLanguageCodesLanguageCodePost.');
            }
            const localVarPath = `/v1/game-icon/games/{gameId}/language-codes/{languageCode}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (requestFiles !== undefined) { 
                localVarFormParams.append('request.files', requestFiles as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GameIconApi - functional programming interface
 * @export
 */
export const GameIconApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all icons for a game
         * @param {number} gameId The id of the game
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GameIconGamesGameIdGet(gameId: number, width?: number, height?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameIconResponse>> {
            const localVarAxiosArgs = await GameIconApiAxiosParamCreator(configuration).v1GameIconGamesGameIdGet(gameId, width, height, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a localized icon from a game
         * @param {number} gameId The id of the game
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GameIconGamesGameIdLanguageCodesLanguageCodeDelete(gameId: number, languageCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await GameIconApiAxiosParamCreator(configuration).v1GameIconGamesGameIdLanguageCodesLanguageCodeDelete(gameId, languageCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a game\'s icon
         * @param {number} gameId The id of the game
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GameIconGamesGameIdLanguageCodesLanguageCodePost(gameId: number, languageCode: string, requestFiles?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await GameIconApiAxiosParamCreator(configuration).v1GameIconGamesGameIdLanguageCodesLanguageCodePost(gameId, languageCode, requestFiles, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GameIconApi - factory interface
 * @export
 */
export const GameIconApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get all icons for a game
         * @param {number} gameId The id of the game
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameIconGamesGameIdGet(gameId: number, width?: number, height?: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameIconResponse> {
            return GameIconApiFp(configuration).v1GameIconGamesGameIdGet(gameId, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a localized icon from a game
         * @param {number} gameId The id of the game
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameIconGamesGameIdLanguageCodesLanguageCodeDelete(gameId: number, languageCode: string, options?: any): AxiosPromise<object> {
            return GameIconApiFp(configuration).v1GameIconGamesGameIdLanguageCodesLanguageCodeDelete(gameId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a game\'s icon
         * @param {number} gameId The id of the game
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameIconGamesGameIdLanguageCodesLanguageCodePost(gameId: number, languageCode: string, requestFiles?: any, options?: any): AxiosPromise<object> {
            return GameIconApiFp(configuration).v1GameIconGamesGameIdLanguageCodesLanguageCodePost(gameId, languageCode, requestFiles, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GameIconApi - interface
 * @export
 * @interface GameIconApi
 */
export interface GameIconApiInterface {
    /**
     * 
     * @summary Get all icons for a game
     * @param {number} gameId The id of the game
     * @param {number} [width] The width of the icon to request
     * @param {number} [height] The height of the icon to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameIconApiInterface
     */
    v1GameIconGamesGameIdGet(gameId: number, width?: number, height?: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameIconResponse>;

    /**
     * 
     * @summary Delete a localized icon from a game
     * @param {number} gameId The id of the game
     * @param {string} languageCode The language code of the localized icon to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameIconApiInterface
     */
    v1GameIconGamesGameIdLanguageCodesLanguageCodeDelete(gameId: number, languageCode: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Update a game\'s icon
     * @param {number} gameId The id of the game
     * @param {string} languageCode The language code of this icon to update
     * @param {any} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameIconApiInterface
     */
    v1GameIconGamesGameIdLanguageCodesLanguageCodePost(gameId: number, languageCode: string, requestFiles?: any, options?: any): AxiosPromise<object>;

}

/**
 * GameIconApi - object-oriented interface
 * @export
 * @class GameIconApi
 * @extends {BaseAPI}
 */
export class GameIconApi extends BaseAPI implements GameIconApiInterface {
    /**
     * 
     * @summary Get all icons for a game
     * @param {number} gameId The id of the game
     * @param {number} [width] The width of the icon to request
     * @param {number} [height] The height of the icon to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameIconApi
     */
    public v1GameIconGamesGameIdGet(gameId: number, width?: number, height?: number, options?: any) {
        return GameIconApiFp(this.configuration).v1GameIconGamesGameIdGet(gameId, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a localized icon from a game
     * @param {number} gameId The id of the game
     * @param {string} languageCode The language code of the localized icon to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameIconApi
     */
    public v1GameIconGamesGameIdLanguageCodesLanguageCodeDelete(gameId: number, languageCode: string, options?: any) {
        return GameIconApiFp(this.configuration).v1GameIconGamesGameIdLanguageCodesLanguageCodeDelete(gameId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a game\'s icon
     * @param {number} gameId The id of the game
     * @param {string} languageCode The language code of this icon to update
     * @param {any} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameIconApi
     */
    public v1GameIconGamesGameIdLanguageCodesLanguageCodePost(gameId: number, languageCode: string, requestFiles?: any, options?: any) {
        return GameIconApiFp(this.configuration).v1GameIconGamesGameIdLanguageCodesLanguageCodePost(gameId, languageCode, requestFiles, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * GameLocalizationStatusApi - axios parameter creator
 * @export
 */
export const GameLocalizationStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the language translation counts for all languages of a game
         * @param {number} gameId GameID of the game to get translation counts for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameLocalizationStatusGameIdTranslationCountsGet: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1GameLocalizationStatusGameIdTranslationCountsGet.');
            }
            const localVarPath = `/v1/game-localization-status/{gameId}/translation-counts`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the language translation counts for the specified table.  The languages to retrieve must be provided.
         * @param {Array<number>} gameIds List of game ids to retrieve translation counts for.
         * @param {string} languageOrLocaleCode The code for the language or locale.
         * @param {'Language' | 'Locale'} languageOrLocaleType Indicates whether the languageOrLocaleCode represents a language or locale.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet: async (gameIds: Array<number>, languageOrLocaleCode: string, languageOrLocaleType: 'Language' | 'Locale', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameIds' is not null or undefined
            if (gameIds === null || gameIds === undefined) {
                throw new RequiredError('gameIds','Required parameter gameIds was null or undefined when calling v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet.');
            }
            // verify required parameter 'languageOrLocaleCode' is not null or undefined
            if (languageOrLocaleCode === null || languageOrLocaleCode === undefined) {
                throw new RequiredError('languageOrLocaleCode','Required parameter languageOrLocaleCode was null or undefined when calling v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet.');
            }
            // verify required parameter 'languageOrLocaleType' is not null or undefined
            if (languageOrLocaleType === null || languageOrLocaleType === undefined) {
                throw new RequiredError('languageOrLocaleType','Required parameter languageOrLocaleType was null or undefined when calling v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet.');
            }
            const localVarPath = `/v1/game-localization-status/translation-counts-for-language-or-locale`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gameIds) {
                localVarQueryParameter['gameIds'] = gameIds;
            }

            if (languageOrLocaleCode !== undefined) {
                localVarQueryParameter['languageOrLocaleCode'] = languageOrLocaleCode;
            }

            if (languageOrLocaleType !== undefined) {
                localVarQueryParameter['languageOrLocaleType'] = languageOrLocaleType;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GameLocalizationStatusApi - functional programming interface
 * @export
 */
export const GameLocalizationStatusApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the language translation counts for all languages of a game
         * @param {number} gameId GameID of the game to get translation counts for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GameLocalizationStatusGameIdTranslationCountsGet(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetTranslationCountsForGameResponse>> {
            const localVarAxiosArgs = await GameLocalizationStatusApiAxiosParamCreator(configuration).v1GameLocalizationStatusGameIdTranslationCountsGet(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the language translation counts for the specified table.  The languages to retrieve must be provided.
         * @param {Array<number>} gameIds List of game ids to retrieve translation counts for.
         * @param {string} languageOrLocaleCode The code for the language or locale.
         * @param {'Language' | 'Locale'} languageOrLocaleType Indicates whether the languageOrLocaleCode represents a language or locale.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet(gameIds: Array<number>, languageOrLocaleCode: string, languageOrLocaleType: 'Language' | 'Locale', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetTranslationCountsForLanguageOrLocaleResponse>> {
            const localVarAxiosArgs = await GameLocalizationStatusApiAxiosParamCreator(configuration).v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet(gameIds, languageOrLocaleCode, languageOrLocaleType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GameLocalizationStatusApi - factory interface
 * @export
 */
export const GameLocalizationStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets the language translation counts for all languages of a game
         * @param {number} gameId GameID of the game to get translation counts for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameLocalizationStatusGameIdTranslationCountsGet(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTranslationCountsForGameResponse> {
            return GameLocalizationStatusApiFp(configuration).v1GameLocalizationStatusGameIdTranslationCountsGet(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the language translation counts for the specified table.  The languages to retrieve must be provided.
         * @param {Array<number>} gameIds List of game ids to retrieve translation counts for.
         * @param {string} languageOrLocaleCode The code for the language or locale.
         * @param {'Language' | 'Locale'} languageOrLocaleType Indicates whether the languageOrLocaleCode represents a language or locale.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet(gameIds: Array<number>, languageOrLocaleCode: string, languageOrLocaleType: 'Language' | 'Locale', options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTranslationCountsForLanguageOrLocaleResponse> {
            return GameLocalizationStatusApiFp(configuration).v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet(gameIds, languageOrLocaleCode, languageOrLocaleType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GameLocalizationStatusApi - interface
 * @export
 * @interface GameLocalizationStatusApi
 */
export interface GameLocalizationStatusApiInterface {
    /**
     * 
     * @summary Gets the language translation counts for all languages of a game
     * @param {number} gameId GameID of the game to get translation counts for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameLocalizationStatusApiInterface
     */
    v1GameLocalizationStatusGameIdTranslationCountsGet(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTranslationCountsForGameResponse>;

    /**
     * 
     * @summary Gets the language translation counts for the specified table.  The languages to retrieve must be provided.
     * @param {Array<number>} gameIds List of game ids to retrieve translation counts for.
     * @param {string} languageOrLocaleCode The code for the language or locale.
     * @param {'Language' | 'Locale'} languageOrLocaleType Indicates whether the languageOrLocaleCode represents a language or locale.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameLocalizationStatusApiInterface
     */
    v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet(gameIds: Array<number>, languageOrLocaleCode: string, languageOrLocaleType: 'Language' | 'Locale', options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTranslationCountsForLanguageOrLocaleResponse>;

}

/**
 * GameLocalizationStatusApi - object-oriented interface
 * @export
 * @class GameLocalizationStatusApi
 * @extends {BaseAPI}
 */
export class GameLocalizationStatusApi extends BaseAPI implements GameLocalizationStatusApiInterface {
    /**
     * 
     * @summary Gets the language translation counts for all languages of a game
     * @param {number} gameId GameID of the game to get translation counts for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameLocalizationStatusApi
     */
    public v1GameLocalizationStatusGameIdTranslationCountsGet(gameId: number, options?: any) {
        return GameLocalizationStatusApiFp(this.configuration).v1GameLocalizationStatusGameIdTranslationCountsGet(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the language translation counts for the specified table.  The languages to retrieve must be provided.
     * @param {Array<number>} gameIds List of game ids to retrieve translation counts for.
     * @param {string} languageOrLocaleCode The code for the language or locale.
     * @param {'Language' | 'Locale'} languageOrLocaleType Indicates whether the languageOrLocaleCode represents a language or locale.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameLocalizationStatusApi
     */
    public v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet(gameIds: Array<number>, languageOrLocaleCode: string, languageOrLocaleType: 'Language' | 'Locale', options?: any) {
        return GameLocalizationStatusApiFp(this.configuration).v1GameLocalizationStatusTranslationCountsForLanguageOrLocaleGet(gameIds, languageOrLocaleCode, languageOrLocaleType, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * GamePassApi - axios parameter creator
 * @export
 */
export const GamePassApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update localized description of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of description to update
         * @param {RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch: async (gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamePassId' is not null or undefined
            if (gamePassId === null || gamePassId === undefined) {
                throw new RequiredError('gamePassId','Required parameter gamePassId was null or undefined when calling v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch.');
            }
            const localVarPath = `/v1/game-passes/{gamePassId}/description/language-codes/{languageCode}`
                .replace(`{${"gamePassId"}}`, encodeURIComponent(String(gamePassId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all icons for a game pass
         * @param {number} gamePassId The game pass id
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdIconsGet: async (gamePassId: number, width?: number, height?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamePassId' is not null or undefined
            if (gamePassId === null || gamePassId === undefined) {
                throw new RequiredError('gamePassId','Required parameter gamePassId was null or undefined when calling v1GamePassesGamePassIdIconsGet.');
            }
            const localVarPath = `/v1/game-passes/{gamePassId}/icons`
                .replace(`{${"gamePassId"}}`, encodeURIComponent(String(gamePassId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a localized icon from a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdIconsLanguageCodesLanguageCodeDelete: async (gamePassId: number, languageCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamePassId' is not null or undefined
            if (gamePassId === null || gamePassId === undefined) {
                throw new RequiredError('gamePassId','Required parameter gamePassId was null or undefined when calling v1GamePassesGamePassIdIconsLanguageCodesLanguageCodeDelete.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GamePassesGamePassIdIconsLanguageCodesLanguageCodeDelete.');
            }
            const localVarPath = `/v1/game-passes/{gamePassId}/icons/language-codes/{languageCode}`
                .replace(`{${"gamePassId"}}`, encodeURIComponent(String(gamePassId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a game pass\'s icon
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdIconsLanguageCodesLanguageCodePost: async (gamePassId: number, languageCode: string, requestFiles?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamePassId' is not null or undefined
            if (gamePassId === null || gamePassId === undefined) {
                throw new RequiredError('gamePassId','Required parameter gamePassId was null or undefined when calling v1GamePassesGamePassIdIconsLanguageCodesLanguageCodePost.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GamePassesGamePassIdIconsLanguageCodesLanguageCodePost.');
            }
            const localVarPath = `/v1/game-passes/{gamePassId}/icons/language-codes/{languageCode}`
                .replace(`{${"gamePassId"}}`, encodeURIComponent(String(gamePassId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (requestFiles !== undefined) { 
                localVarFormParams.append('request.files', requestFiles as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all names and descriptions of a game pass
         * @param {number} gamePassId The game pass Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdNameDescriptionGet: async (gamePassId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamePassId' is not null or undefined
            if (gamePassId === null || gamePassId === undefined) {
                throw new RequiredError('gamePassId','Required parameter gamePassId was null or undefined when calling v1GamePassesGamePassIdNameDescriptionGet.');
            }
            const localVarPath = `/v1/game-passes/{gamePassId}/name-description`
                .replace(`{${"gamePassId"}}`, encodeURIComponent(String(gamePassId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete localized name and description of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the name and description to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodeDelete: async (gamePassId: number, languageCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamePassId' is not null or undefined
            if (gamePassId === null || gamePassId === undefined) {
                throw new RequiredError('gamePassId','Required parameter gamePassId was null or undefined when calling v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodeDelete.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodeDelete.');
            }
            const localVarPath = `/v1/game-passes/{gamePassId}/name-description/language-codes/{languageCode}`
                .replace(`{${"gamePassId"}}`, encodeURIComponent(String(gamePassId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update localized name and description of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the name/description to update
         * @param {RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch: async (gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamePassId' is not null or undefined
            if (gamePassId === null || gamePassId === undefined) {
                throw new RequiredError('gamePassId','Required parameter gamePassId was null or undefined when calling v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch.');
            }
            const localVarPath = `/v1/game-passes/{gamePassId}/name-description/language-codes/{languageCode}`
                .replace(`{${"gamePassId"}}`, encodeURIComponent(String(gamePassId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update localized name of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the name to update
         * @param {RobloxGameInternationalizationApiUpdateGamePassNameRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch: async (gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassNameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamePassId' is not null or undefined
            if (gamePassId === null || gamePassId === undefined) {
                throw new RequiredError('gamePassId','Required parameter gamePassId was null or undefined when calling v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch.');
            }
            const localVarPath = `/v1/game-passes/{gamePassId}/name/language-codes/{languageCode}`
                .replace(`{${"gamePassId"}}`, encodeURIComponent(String(gamePassId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GamePassApi - functional programming interface
 * @export
 */
export const GamePassApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update localized description of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of description to update
         * @param {RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateGamePassDescriptionResponse>> {
            const localVarAxiosArgs = await GamePassApiAxiosParamCreator(configuration).v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch(gamePassId, languageCode, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all icons for a game pass
         * @param {number} gamePassId The game pass id
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamePassesGamePassIdIconsGet(gamePassId: number, width?: number, height?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGamePassIconResponse>> {
            const localVarAxiosArgs = await GamePassApiAxiosParamCreator(configuration).v1GamePassesGamePassIdIconsGet(gamePassId, width, height, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a localized icon from a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamePassesGamePassIdIconsLanguageCodesLanguageCodeDelete(gamePassId: number, languageCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await GamePassApiAxiosParamCreator(configuration).v1GamePassesGamePassIdIconsLanguageCodesLanguageCodeDelete(gamePassId, languageCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a game pass\'s icon
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamePassesGamePassIdIconsLanguageCodesLanguageCodePost(gamePassId: number, languageCode: string, requestFiles?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await GamePassApiAxiosParamCreator(configuration).v1GamePassesGamePassIdIconsLanguageCodesLanguageCodePost(gamePassId, languageCode, requestFiles, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all names and descriptions of a game pass
         * @param {number} gamePassId The game pass Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamePassesGamePassIdNameDescriptionGet(gamePassId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription>> {
            const localVarAxiosArgs = await GamePassApiAxiosParamCreator(configuration).v1GamePassesGamePassIdNameDescriptionGet(gamePassId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete localized name and description of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the name and description to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodeDelete(gamePassId: number, languageCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await GamePassApiAxiosParamCreator(configuration).v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodeDelete(gamePassId, languageCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update localized name and description of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the name/description to update
         * @param {RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateGamePassNameDescriptionResponse>> {
            const localVarAxiosArgs = await GamePassApiAxiosParamCreator(configuration).v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch(gamePassId, languageCode, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update localized name of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the name to update
         * @param {RobloxGameInternationalizationApiUpdateGamePassNameRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassNameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateGamePassNameResponse>> {
            const localVarAxiosArgs = await GamePassApiAxiosParamCreator(configuration).v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch(gamePassId, languageCode, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GamePassApi - factory interface
 * @export
 */
export const GamePassApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Update localized description of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of description to update
         * @param {RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateGamePassDescriptionResponse> {
            return GamePassApiFp(configuration).v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch(gamePassId, languageCode, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all icons for a game pass
         * @param {number} gamePassId The game pass id
         * @param {number} [width] The width of the icon to request
         * @param {number} [height] The height of the icon to request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdIconsGet(gamePassId: number, width?: number, height?: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGamePassIconResponse> {
            return GamePassApiFp(configuration).v1GamePassesGamePassIdIconsGet(gamePassId, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a localized icon from a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the localized icon to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdIconsLanguageCodesLanguageCodeDelete(gamePassId: number, languageCode: string, options?: any): AxiosPromise<object> {
            return GamePassApiFp(configuration).v1GamePassesGamePassIdIconsLanguageCodesLanguageCodeDelete(gamePassId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a game pass\'s icon
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of this icon to update
         * @param {any} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdIconsLanguageCodesLanguageCodePost(gamePassId: number, languageCode: string, requestFiles?: any, options?: any): AxiosPromise<object> {
            return GamePassApiFp(configuration).v1GamePassesGamePassIdIconsLanguageCodesLanguageCodePost(gamePassId, languageCode, requestFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all names and descriptions of a game pass
         * @param {number} gamePassId The game pass Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdNameDescriptionGet(gamePassId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription> {
            return GamePassApiFp(configuration).v1GamePassesGamePassIdNameDescriptionGet(gamePassId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete localized name and description of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the name and description to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodeDelete(gamePassId: number, languageCode: string, options?: any): AxiosPromise<object> {
            return GamePassApiFp(configuration).v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodeDelete(gamePassId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update localized name and description of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the name/description to update
         * @param {RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateGamePassNameDescriptionResponse> {
            return GamePassApiFp(configuration).v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch(gamePassId, languageCode, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update localized name of a game pass
         * @param {number} gamePassId The game pass id
         * @param {string} languageCode The language code of the name to update
         * @param {RobloxGameInternationalizationApiUpdateGamePassNameRequest} request The request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassNameRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateGamePassNameResponse> {
            return GamePassApiFp(configuration).v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch(gamePassId, languageCode, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GamePassApi - interface
 * @export
 * @interface GamePassApi
 */
export interface GamePassApiInterface {
    /**
     * 
     * @summary Update localized description of a game pass
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of description to update
     * @param {RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApiInterface
     */
    v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateGamePassDescriptionResponse>;

    /**
     * 
     * @summary Get all icons for a game pass
     * @param {number} gamePassId The game pass id
     * @param {number} [width] The width of the icon to request
     * @param {number} [height] The height of the icon to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApiInterface
     */
    v1GamePassesGamePassIdIconsGet(gamePassId: number, width?: number, height?: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGamePassIconResponse>;

    /**
     * 
     * @summary Delete a localized icon from a game pass
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of the localized icon to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApiInterface
     */
    v1GamePassesGamePassIdIconsLanguageCodesLanguageCodeDelete(gamePassId: number, languageCode: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Update a game pass\'s icon
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of this icon to update
     * @param {any} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApiInterface
     */
    v1GamePassesGamePassIdIconsLanguageCodesLanguageCodePost(gamePassId: number, languageCode: string, requestFiles?: any, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Get all names and descriptions of a game pass
     * @param {number} gamePassId The game pass Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApiInterface
     */
    v1GamePassesGamePassIdNameDescriptionGet(gamePassId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription>;

    /**
     * 
     * @summary Delete localized name and description of a game pass
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of the name and description to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApiInterface
     */
    v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodeDelete(gamePassId: number, languageCode: string, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Update localized name and description of a game pass
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of the name/description to update
     * @param {RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApiInterface
     */
    v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateGamePassNameDescriptionResponse>;

    /**
     * 
     * @summary Update localized name of a game pass
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of the name to update
     * @param {RobloxGameInternationalizationApiUpdateGamePassNameRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApiInterface
     */
    v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassNameRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateGamePassNameResponse>;

}

/**
 * GamePassApi - object-oriented interface
 * @export
 * @class GamePassApi
 * @extends {BaseAPI}
 */
export class GamePassApi extends BaseAPI implements GamePassApiInterface {
    /**
     * 
     * @summary Update localized description of a game pass
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of description to update
     * @param {RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApi
     */
    public v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassDescriptionRequest, options?: any) {
        return GamePassApiFp(this.configuration).v1GamePassesGamePassIdDescriptionLanguageCodesLanguageCodePatch(gamePassId, languageCode, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all icons for a game pass
     * @param {number} gamePassId The game pass id
     * @param {number} [width] The width of the icon to request
     * @param {number} [height] The height of the icon to request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApi
     */
    public v1GamePassesGamePassIdIconsGet(gamePassId: number, width?: number, height?: number, options?: any) {
        return GamePassApiFp(this.configuration).v1GamePassesGamePassIdIconsGet(gamePassId, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a localized icon from a game pass
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of the localized icon to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApi
     */
    public v1GamePassesGamePassIdIconsLanguageCodesLanguageCodeDelete(gamePassId: number, languageCode: string, options?: any) {
        return GamePassApiFp(this.configuration).v1GamePassesGamePassIdIconsLanguageCodesLanguageCodeDelete(gamePassId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a game pass\'s icon
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of this icon to update
     * @param {any} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApi
     */
    public v1GamePassesGamePassIdIconsLanguageCodesLanguageCodePost(gamePassId: number, languageCode: string, requestFiles?: any, options?: any) {
        return GamePassApiFp(this.configuration).v1GamePassesGamePassIdIconsLanguageCodesLanguageCodePost(gamePassId, languageCode, requestFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all names and descriptions of a game pass
     * @param {number} gamePassId The game pass Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApi
     */
    public v1GamePassesGamePassIdNameDescriptionGet(gamePassId: number, options?: any) {
        return GamePassApiFp(this.configuration).v1GamePassesGamePassIdNameDescriptionGet(gamePassId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete localized name and description of a game pass
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of the name and description to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApi
     */
    public v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodeDelete(gamePassId: number, languageCode: string, options?: any) {
        return GamePassApiFp(this.configuration).v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodeDelete(gamePassId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update localized name and description of a game pass
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of the name/description to update
     * @param {RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApi
     */
    public v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassNameDescriptionRequest, options?: any) {
        return GamePassApiFp(this.configuration).v1GamePassesGamePassIdNameDescriptionLanguageCodesLanguageCodePatch(gamePassId, languageCode, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update localized name of a game pass
     * @param {number} gamePassId The game pass id
     * @param {string} languageCode The language code of the name to update
     * @param {RobloxGameInternationalizationApiUpdateGamePassNameRequest} request The request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassApi
     */
    public v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch(gamePassId: number, languageCode: string, request: RobloxGameInternationalizationApiUpdateGamePassNameRequest, options?: any) {
        return GamePassApiFp(this.configuration).v1GamePassesGamePassIdNameLanguageCodesLanguageCodePatch(gamePassId, languageCode, request, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * GameThumbnailsApi - axios parameter creator
 * @export
 */
export const GameThumbnailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the localized image ids in all languages for a game.
         * @param {number} gameId The game identifier.
         * @param {number} [width] The width.
         * @param {number} [height] The height.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameThumbnailsGamesGameIdImagesGet: async (gameId: number, width?: number, height?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1GameThumbnailsGamesGameIdImagesGet.');
            }
            const localVarPath = `/v1/game-thumbnails/games/{gameId}/images`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads the game thumbnail.
         * @param {number} gameId The game identifier.
         * @param {string} languageCode The language code.
         * @param {any} [gameThumbnailRequestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagePost: async (gameId: number, languageCode: string, gameThumbnailRequestFiles?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagePost.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagePost.');
            }
            const localVarPath = `/v1/game-thumbnails/games/{gameId}/language-codes/{languageCode}/image`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();


            if (gameThumbnailRequestFiles !== undefined) { 
                localVarFormParams.append('gameThumbnailRequest.files', gameThumbnailRequestFiles as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes the game thumbnail.
         * @param {number} gameId The game identifier.
         * @param {string} languageCode The language code.
         * @param {number} imageId The image identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete: async (gameId: number, languageCode: string, imageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete.');
            }
            // verify required parameter 'imageId' is not null or undefined
            if (imageId === null || imageId === undefined) {
                throw new RequiredError('imageId','Required parameter imageId was null or undefined when calling v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete.');
            }
            const localVarPath = `/v1/game-thumbnails/games/{gameId}/language-codes/{languageCode}/images/{imageId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Orders the specified image Ids for the game thumbnails.
         * @param {number} gameId The game identifier.
         * @param {string} languageCode The language code.
         * @param {RobloxGameInternationalizationApiSortImageIdsRequest} request The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost: async (gameId: number, languageCode: string, request: RobloxGameInternationalizationApiSortImageIdsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost.');
            }
            const localVarPath = `/v1/game-thumbnails/games/{gameId}/language-codes/{languageCode}/images/order`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GameThumbnailsApi - functional programming interface
 * @export
 */
export const GameThumbnailsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the localized image ids in all languages for a game.
         * @param {number} gameId The game identifier.
         * @param {number} [width] The width.
         * @param {number} [height] The height.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GameThumbnailsGamesGameIdImagesGet(gameId: number, width?: number, height?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameThumbnailsResponse>> {
            const localVarAxiosArgs = await GameThumbnailsApiAxiosParamCreator(configuration).v1GameThumbnailsGamesGameIdImagesGet(gameId, width, height, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Uploads the game thumbnail.
         * @param {number} gameId The game identifier.
         * @param {string} languageCode The language code.
         * @param {any} [gameThumbnailRequestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagePost(gameId: number, languageCode: string, gameThumbnailRequestFiles?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiModelsResponseUploadImageForGameThumbnailResponse>> {
            const localVarAxiosArgs = await GameThumbnailsApiAxiosParamCreator(configuration).v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagePost(gameId, languageCode, gameThumbnailRequestFiles, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes the game thumbnail.
         * @param {number} gameId The game identifier.
         * @param {string} languageCode The language code.
         * @param {number} imageId The image identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete(gameId: number, languageCode: string, imageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await GameThumbnailsApiAxiosParamCreator(configuration).v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete(gameId, languageCode, imageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Orders the specified image Ids for the game thumbnails.
         * @param {number} gameId The game identifier.
         * @param {string} languageCode The language code.
         * @param {RobloxGameInternationalizationApiSortImageIdsRequest} request The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost(gameId: number, languageCode: string, request: RobloxGameInternationalizationApiSortImageIdsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await GameThumbnailsApiAxiosParamCreator(configuration).v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost(gameId, languageCode, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GameThumbnailsApi - factory interface
 * @export
 */
export const GameThumbnailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get the localized image ids in all languages for a game.
         * @param {number} gameId The game identifier.
         * @param {number} [width] The width.
         * @param {number} [height] The height.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameThumbnailsGamesGameIdImagesGet(gameId: number, width?: number, height?: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameThumbnailsResponse> {
            return GameThumbnailsApiFp(configuration).v1GameThumbnailsGamesGameIdImagesGet(gameId, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads the game thumbnail.
         * @param {number} gameId The game identifier.
         * @param {string} languageCode The language code.
         * @param {any} [gameThumbnailRequestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagePost(gameId: number, languageCode: string, gameThumbnailRequestFiles?: any, options?: any): AxiosPromise<RobloxGameInternationalizationApiModelsResponseUploadImageForGameThumbnailResponse> {
            return GameThumbnailsApiFp(configuration).v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagePost(gameId, languageCode, gameThumbnailRequestFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes the game thumbnail.
         * @param {number} gameId The game identifier.
         * @param {string} languageCode The language code.
         * @param {number} imageId The image identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete(gameId: number, languageCode: string, imageId: number, options?: any): AxiosPromise<object> {
            return GameThumbnailsApiFp(configuration).v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete(gameId, languageCode, imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Orders the specified image Ids for the game thumbnails.
         * @param {number} gameId The game identifier.
         * @param {string} languageCode The language code.
         * @param {RobloxGameInternationalizationApiSortImageIdsRequest} request The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost(gameId: number, languageCode: string, request: RobloxGameInternationalizationApiSortImageIdsRequest, options?: any): AxiosPromise<object> {
            return GameThumbnailsApiFp(configuration).v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost(gameId, languageCode, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GameThumbnailsApi - interface
 * @export
 * @interface GameThumbnailsApi
 */
export interface GameThumbnailsApiInterface {
    /**
     * 
     * @summary Get the localized image ids in all languages for a game.
     * @param {number} gameId The game identifier.
     * @param {number} [width] The width.
     * @param {number} [height] The height.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameThumbnailsApiInterface
     */
    v1GameThumbnailsGamesGameIdImagesGet(gameId: number, width?: number, height?: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiGetGameThumbnailsResponse>;

    /**
     * 
     * @summary Uploads the game thumbnail.
     * @param {number} gameId The game identifier.
     * @param {string} languageCode The language code.
     * @param {any} [gameThumbnailRequestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameThumbnailsApiInterface
     */
    v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagePost(gameId: number, languageCode: string, gameThumbnailRequestFiles?: any, options?: any): AxiosPromise<RobloxGameInternationalizationApiModelsResponseUploadImageForGameThumbnailResponse>;

    /**
     * 
     * @summary Deletes the game thumbnail.
     * @param {number} gameId The game identifier.
     * @param {string} languageCode The language code.
     * @param {number} imageId The image identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameThumbnailsApiInterface
     */
    v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete(gameId: number, languageCode: string, imageId: number, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Orders the specified image Ids for the game thumbnails.
     * @param {number} gameId The game identifier.
     * @param {string} languageCode The language code.
     * @param {RobloxGameInternationalizationApiSortImageIdsRequest} request The request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameThumbnailsApiInterface
     */
    v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost(gameId: number, languageCode: string, request: RobloxGameInternationalizationApiSortImageIdsRequest, options?: any): AxiosPromise<object>;

}

/**
 * GameThumbnailsApi - object-oriented interface
 * @export
 * @class GameThumbnailsApi
 * @extends {BaseAPI}
 */
export class GameThumbnailsApi extends BaseAPI implements GameThumbnailsApiInterface {
    /**
     * 
     * @summary Get the localized image ids in all languages for a game.
     * @param {number} gameId The game identifier.
     * @param {number} [width] The width.
     * @param {number} [height] The height.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameThumbnailsApi
     */
    public v1GameThumbnailsGamesGameIdImagesGet(gameId: number, width?: number, height?: number, options?: any) {
        return GameThumbnailsApiFp(this.configuration).v1GameThumbnailsGamesGameIdImagesGet(gameId, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads the game thumbnail.
     * @param {number} gameId The game identifier.
     * @param {string} languageCode The language code.
     * @param {any} [gameThumbnailRequestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameThumbnailsApi
     */
    public v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagePost(gameId: number, languageCode: string, gameThumbnailRequestFiles?: any, options?: any) {
        return GameThumbnailsApiFp(this.configuration).v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagePost(gameId, languageCode, gameThumbnailRequestFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes the game thumbnail.
     * @param {number} gameId The game identifier.
     * @param {string} languageCode The language code.
     * @param {number} imageId The image identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameThumbnailsApi
     */
    public v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete(gameId: number, languageCode: string, imageId: number, options?: any) {
        return GameThumbnailsApiFp(this.configuration).v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesImageIdDelete(gameId, languageCode, imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Orders the specified image Ids for the game thumbnails.
     * @param {number} gameId The game identifier.
     * @param {string} languageCode The language code.
     * @param {RobloxGameInternationalizationApiSortImageIdsRequest} request The request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GameThumbnailsApi
     */
    public v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost(gameId: number, languageCode: string, request: RobloxGameInternationalizationApiSortImageIdsRequest, options?: any) {
        return GameThumbnailsApiFp(this.configuration).v1GameThumbnailsGamesGameIdLanguageCodesLanguageCodeImagesOrderPost(gameId, languageCode, request, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * LocalizationTableApi - axios parameter creator
 * @export
 */
export const LocalizationTableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the supported language codes that can be used by localization tables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableAvailableLanguagesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/localizationtable/available-languages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates localization asset of a game
         * @param {number} gameId The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableGamesGameIdAssetsGenerationRequestPost: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1LocalizationtableGamesGameIdAssetsGenerationRequestPost.');
            }
            const localVarPath = `/v1/localizationtable/games/{gameId}/assets-generation-request`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} gameId 
         * @param {RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableGametablesGameIdPatch: async (gameId: number, request: RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1LocalizationtableGametablesGameIdPatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1LocalizationtableGametablesGameIdPatch.');
            }
            const localVarPath = `/v1/localizationtable/gametables/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesGet: async (assetId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            if (assetId === null || assetId === undefined) {
                throw new RequiredError('assetId','Required parameter assetId was null or undefined when calling v1LocalizationtableTablesGet.');
            }
            const localVarPath = `/v1/localizationtable/tables`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetId !== undefined) {
                localVarQueryParameter['assetId'] = assetId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RobloxGameInternationalizationApiCreateTableRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesPost: async (request: RobloxGameInternationalizationApiCreateTableRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1LocalizationtableTablesPost.');
            }
            const localVarPath = `/v1/localizationtable/tables`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tableId 
         * @param {string} [cursor] 
         * @param {number} [gameId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesTableIdEntriesGet: async (tableId: string, cursor?: string, gameId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            if (tableId === null || tableId === undefined) {
                throw new RequiredError('tableId','Required parameter tableId was null or undefined when calling v1LocalizationtableTablesTableIdEntriesGet.');
            }
            const localVarPath = `/v1/localizationtable/tables/{tableId}/entries`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (gameId !== undefined) {
                localVarQueryParameter['gameId'] = gameId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the translation history for each entry passed in.
         * @param {string} tableId The entries\&#39; tableId.
         * @param {RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest} request A request body containing all relevant data for entry history lookup.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesTableIdEntriesTranslationHistoryPost: async (tableId: string, request: RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest, gameId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            if (tableId === null || tableId === undefined) {
                throw new RequiredError('tableId','Required parameter tableId was null or undefined when calling v1LocalizationtableTablesTableIdEntriesTranslationHistoryPost.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1LocalizationtableTablesTableIdEntriesTranslationHistoryPost.');
            }
            const localVarPath = `/v1/localizationtable/tables/{tableId}/entries/translation-history`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gameId !== undefined) {
                localVarQueryParameter['gameId'] = gameId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the number of entries in the specified table
         * @param {string} tableId The table id
         * @param {number} [gameId] The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesTableIdEntryCountGet: async (tableId: string, gameId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            if (tableId === null || tableId === undefined) {
                throw new RequiredError('tableId','Required parameter tableId was null or undefined when calling v1LocalizationtableTablesTableIdEntryCountGet.');
            }
            const localVarPath = `/v1/localizationtable/tables/{tableId}/entry-count`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gameId !== undefined) {
                localVarQueryParameter['gameId'] = gameId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesTableIdGet: async (tableId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            if (tableId === null || tableId === undefined) {
                throw new RequiredError('tableId','Required parameter tableId was null or undefined when calling v1LocalizationtableTablesTableIdGet.');
            }
            const localVarPath = `/v1/localizationtable/tables/{tableId}`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the tables contents based on what is provided.
         * @param {string} tableId The table guid for the table to update.
         * @param {RobloxGameInternationalizationApiUpdateTableContentsRequest} request The metadata object is optional.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesTableIdPatch: async (tableId: string, request: RobloxGameInternationalizationApiUpdateTableContentsRequest, gameId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tableId' is not null or undefined
            if (tableId === null || tableId === undefined) {
                throw new RequiredError('tableId','Required parameter tableId was null or undefined when calling v1LocalizationtableTablesTableIdPatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1LocalizationtableTablesTableIdPatch.');
            }
            const localVarPath = `/v1/localizationtable/tables/{tableId}`
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gameId !== undefined) {
                localVarQueryParameter['gameId'] = gameId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalizationTableApi - functional programming interface
 * @export
 */
export const LocalizationTableApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the supported language codes that can be used by localization tables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationtableAvailableLanguagesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguage>> {
            const localVarAxiosArgs = await LocalizationTableApiAxiosParamCreator(configuration).v1LocalizationtableAvailableLanguagesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Generates localization asset of a game
         * @param {number} gameId The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationtableGamesGameIdAssetsGenerationRequestPost(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await LocalizationTableApiAxiosParamCreator(configuration).v1LocalizationtableGamesGameIdAssetsGenerationRequestPost(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} gameId 
         * @param {RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationtableGametablesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiAssociateLocalizationTablesToGameResponse>> {
            const localVarAxiosArgs = await LocalizationTableApiAxiosParamCreator(configuration).v1LocalizationtableGametablesGameIdPatch(gameId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationtableTablesGet(assetId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetTableResponse>> {
            const localVarAxiosArgs = await LocalizationTableApiAxiosParamCreator(configuration).v1LocalizationtableTablesGet(assetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RobloxGameInternationalizationApiCreateTableRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationtableTablesPost(request: RobloxGameInternationalizationApiCreateTableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiCreateTableResponse>> {
            const localVarAxiosArgs = await LocalizationTableApiAxiosParamCreator(configuration).v1LocalizationtableTablesPost(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tableId 
         * @param {string} [cursor] 
         * @param {number} [gameId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationtableTablesTableIdEntriesGet(tableId: string, cursor?: string, gameId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetTableEntriesPagedResponse>> {
            const localVarAxiosArgs = await LocalizationTableApiAxiosParamCreator(configuration).v1LocalizationtableTablesTableIdEntriesGet(tableId, cursor, gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the translation history for each entry passed in.
         * @param {string} tableId The entries\&#39; tableId.
         * @param {RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest} request A request body containing all relevant data for entry history lookup.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationtableTablesTableIdEntriesTranslationHistoryPost(tableId: string, request: RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest, gameId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryResponse>> {
            const localVarAxiosArgs = await LocalizationTableApiAxiosParamCreator(configuration).v1LocalizationtableTablesTableIdEntriesTranslationHistoryPost(tableId, request, gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the number of entries in the specified table
         * @param {string} tableId The table id
         * @param {number} [gameId] The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationtableTablesTableIdEntryCountGet(tableId: string, gameId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetTableEntryCountResponse>> {
            const localVarAxiosArgs = await LocalizationTableApiAxiosParamCreator(configuration).v1LocalizationtableTablesTableIdEntryCountGet(tableId, gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationtableTablesTableIdGet(tableId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetTableResponse>> {
            const localVarAxiosArgs = await LocalizationTableApiAxiosParamCreator(configuration).v1LocalizationtableTablesTableIdGet(tableId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates the tables contents based on what is provided.
         * @param {string} tableId The table guid for the table to update.
         * @param {RobloxGameInternationalizationApiUpdateTableContentsRequest} request The metadata object is optional.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1LocalizationtableTablesTableIdPatch(tableId: string, request: RobloxGameInternationalizationApiUpdateTableContentsRequest, gameId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateTableContentsResponse>> {
            const localVarAxiosArgs = await LocalizationTableApiAxiosParamCreator(configuration).v1LocalizationtableTablesTableIdPatch(tableId, request, gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocalizationTableApi - factory interface
 * @export
 */
export const LocalizationTableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets the supported language codes that can be used by localization tables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableAvailableLanguagesGet(options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguage> {
            return LocalizationTableApiFp(configuration).v1LocalizationtableAvailableLanguagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates localization asset of a game
         * @param {number} gameId The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableGamesGameIdAssetsGenerationRequestPost(gameId: number, options?: any): AxiosPromise<object> {
            return LocalizationTableApiFp(configuration).v1LocalizationtableGamesGameIdAssetsGenerationRequestPost(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} gameId 
         * @param {RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableGametablesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiAssociateLocalizationTablesToGameResponse> {
            return LocalizationTableApiFp(configuration).v1LocalizationtableGametablesGameIdPatch(gameId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesGet(assetId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTableResponse> {
            return LocalizationTableApiFp(configuration).v1LocalizationtableTablesGet(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RobloxGameInternationalizationApiCreateTableRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesPost(request: RobloxGameInternationalizationApiCreateTableRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiCreateTableResponse> {
            return LocalizationTableApiFp(configuration).v1LocalizationtableTablesPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tableId 
         * @param {string} [cursor] 
         * @param {number} [gameId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesTableIdEntriesGet(tableId: string, cursor?: string, gameId?: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTableEntriesPagedResponse> {
            return LocalizationTableApiFp(configuration).v1LocalizationtableTablesTableIdEntriesGet(tableId, cursor, gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the translation history for each entry passed in.
         * @param {string} tableId The entries\&#39; tableId.
         * @param {RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest} request A request body containing all relevant data for entry history lookup.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesTableIdEntriesTranslationHistoryPost(tableId: string, request: RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest, gameId?: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryResponse> {
            return LocalizationTableApiFp(configuration).v1LocalizationtableTablesTableIdEntriesTranslationHistoryPost(tableId, request, gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the number of entries in the specified table
         * @param {string} tableId The table id
         * @param {number} [gameId] The game id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesTableIdEntryCountGet(tableId: string, gameId?: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTableEntryCountResponse> {
            return LocalizationTableApiFp(configuration).v1LocalizationtableTablesTableIdEntryCountGet(tableId, gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesTableIdGet(tableId: string, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTableResponse> {
            return LocalizationTableApiFp(configuration).v1LocalizationtableTablesTableIdGet(tableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the tables contents based on what is provided.
         * @param {string} tableId The table guid for the table to update.
         * @param {RobloxGameInternationalizationApiUpdateTableContentsRequest} request The metadata object is optional.
         * @param {number} [gameId] The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalizationtableTablesTableIdPatch(tableId: string, request: RobloxGameInternationalizationApiUpdateTableContentsRequest, gameId?: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateTableContentsResponse> {
            return LocalizationTableApiFp(configuration).v1LocalizationtableTablesTableIdPatch(tableId, request, gameId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalizationTableApi - interface
 * @export
 * @interface LocalizationTableApi
 */
export interface LocalizationTableApiInterface {
    /**
     * 
     * @summary Gets the supported language codes that can be used by localization tables
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationtableAvailableLanguagesGet(options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguage>;

    /**
     * 
     * @summary Generates localization asset of a game
     * @param {number} gameId The game id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationtableGamesGameIdAssetsGenerationRequestPost(gameId: number, options?: any): AxiosPromise<object>;

    /**
     * 
     * @param {number} gameId 
     * @param {RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationtableGametablesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiAssociateLocalizationTablesToGameResponse>;

    /**
     * 
     * @param {number} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationtableTablesGet(assetId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTableResponse>;

    /**
     * 
     * @param {RobloxGameInternationalizationApiCreateTableRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationtableTablesPost(request: RobloxGameInternationalizationApiCreateTableRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiCreateTableResponse>;

    /**
     * 
     * @param {string} tableId 
     * @param {string} [cursor] 
     * @param {number} [gameId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationtableTablesTableIdEntriesGet(tableId: string, cursor?: string, gameId?: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTableEntriesPagedResponse>;

    /**
     * 
     * @summary Gets the translation history for each entry passed in.
     * @param {string} tableId The entries\&#39; tableId.
     * @param {RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest} request A request body containing all relevant data for entry history lookup.
     * @param {number} [gameId] The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationtableTablesTableIdEntriesTranslationHistoryPost(tableId: string, request: RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest, gameId?: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryResponse>;

    /**
     * 
     * @summary Gets the number of entries in the specified table
     * @param {string} tableId The table id
     * @param {number} [gameId] The game id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationtableTablesTableIdEntryCountGet(tableId: string, gameId?: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTableEntryCountResponse>;

    /**
     * 
     * @param {string} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationtableTablesTableIdGet(tableId: string, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetTableResponse>;

    /**
     * 
     * @summary Updates the tables contents based on what is provided.
     * @param {string} tableId The table guid for the table to update.
     * @param {RobloxGameInternationalizationApiUpdateTableContentsRequest} request The metadata object is optional.
     * @param {number} [gameId] The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApiInterface
     */
    v1LocalizationtableTablesTableIdPatch(tableId: string, request: RobloxGameInternationalizationApiUpdateTableContentsRequest, gameId?: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateTableContentsResponse>;

}

/**
 * LocalizationTableApi - object-oriented interface
 * @export
 * @class LocalizationTableApi
 * @extends {BaseAPI}
 */
export class LocalizationTableApi extends BaseAPI implements LocalizationTableApiInterface {
    /**
     * 
     * @summary Gets the supported language codes that can be used by localization tables
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationtableAvailableLanguagesGet(options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationtableAvailableLanguagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates localization asset of a game
     * @param {number} gameId The game id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationtableGamesGameIdAssetsGenerationRequestPost(gameId: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationtableGamesGameIdAssetsGenerationRequestPost(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} gameId 
     * @param {RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationtableGametablesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiAssociateLocalizationTablesToGameRequest, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationtableGametablesGameIdPatch(gameId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationtableTablesGet(assetId: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationtableTablesGet(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobloxGameInternationalizationApiCreateTableRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationtableTablesPost(request: RobloxGameInternationalizationApiCreateTableRequest, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationtableTablesPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tableId 
     * @param {string} [cursor] 
     * @param {number} [gameId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationtableTablesTableIdEntriesGet(tableId: string, cursor?: string, gameId?: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationtableTablesTableIdEntriesGet(tableId, cursor, gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the translation history for each entry passed in.
     * @param {string} tableId The entries\&#39; tableId.
     * @param {RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest} request A request body containing all relevant data for entry history lookup.
     * @param {number} [gameId] The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationtableTablesTableIdEntriesTranslationHistoryPost(tableId: string, request: RobloxGameInternationalizationApiGetTableEntriesTranslationHistoryRequest, gameId?: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationtableTablesTableIdEntriesTranslationHistoryPost(tableId, request, gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the number of entries in the specified table
     * @param {string} tableId The table id
     * @param {number} [gameId] The game id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationtableTablesTableIdEntryCountGet(tableId: string, gameId?: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationtableTablesTableIdEntryCountGet(tableId, gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tableId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationtableTablesTableIdGet(tableId: string, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationtableTablesTableIdGet(tableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the tables contents based on what is provided.
     * @param {string} tableId The table guid for the table to update.
     * @param {RobloxGameInternationalizationApiUpdateTableContentsRequest} request The metadata object is optional.
     * @param {number} [gameId] The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationTableApi
     */
    public v1LocalizationtableTablesTableIdPatch(tableId: string, request: RobloxGameInternationalizationApiUpdateTableContentsRequest, gameId?: number, options?: any) {
        return LocalizationTableApiFp(this.configuration).v1LocalizationtableTablesTableIdPatch(tableId, request, gameId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * NameDescriptionApi - axios parameter creator
 * @export
 */
export const NameDescriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a game\'s name and description in all supported languages
         * @param {number} gameId The id of the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NameDescriptionGamesGameIdGet: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1NameDescriptionGamesGameIdGet.');
            }
            const localVarPath = `/v1/name-description/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the history for name or description in a provided language.
         * @param {number} gameId The id of the game
         * @param {RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest} request The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NameDescriptionGamesGameIdHistoryPost: async (gameId: number, request: RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1NameDescriptionGamesGameIdHistoryPost.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1NameDescriptionGamesGameIdHistoryPost.');
            }
            const localVarPath = `/v1/name-description/games/{gameId}/history`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a game\'s name and description in multiple languages.
         * @param {number} gameId The id of the game.
         * @param {RobloxGameInternationalizationApiUpdateNameDescriptionsRequest} request The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NameDescriptionGamesGameIdPatch: async (gameId: number, request: RobloxGameInternationalizationApiUpdateNameDescriptionsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1NameDescriptionGamesGameIdPatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1NameDescriptionGamesGameIdPatch.');
            }
            const localVarPath = `/v1/name-description/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rollout settings for name/description migration to new page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NameDescriptionMetadataGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/name-description/metadata`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NameDescriptionApi - functional programming interface
 * @export
 */
export const NameDescriptionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a game\'s name and description in all supported languages
         * @param {number} gameId The id of the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NameDescriptionGamesGameIdGet(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription>> {
            const localVarAxiosArgs = await NameDescriptionApiAxiosParamCreator(configuration).v1NameDescriptionGamesGameIdGet(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the history for name or description in a provided language.
         * @param {number} gameId The id of the game
         * @param {RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest} request The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NameDescriptionGamesGameIdHistoryPost(gameId: number, request: RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetNameDescriptionHistoryResponse>> {
            const localVarAxiosArgs = await NameDescriptionApiAxiosParamCreator(configuration).v1NameDescriptionGamesGameIdHistoryPost(gameId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a game\'s name and description in multiple languages.
         * @param {number} gameId The id of the game.
         * @param {RobloxGameInternationalizationApiUpdateNameDescriptionsRequest} request The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NameDescriptionGamesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiUpdateNameDescriptionsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateNameDescriptionsResponse>> {
            const localVarAxiosArgs = await NameDescriptionApiAxiosParamCreator(configuration).v1NameDescriptionGamesGameIdPatch(gameId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Rollout settings for name/description migration to new page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1NameDescriptionMetadataGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiModelsResponseGameNameDescriptionMetadataResponse>> {
            const localVarAxiosArgs = await NameDescriptionApiAxiosParamCreator(configuration).v1NameDescriptionMetadataGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NameDescriptionApi - factory interface
 * @export
 */
export const NameDescriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets a game\'s name and description in all supported languages
         * @param {number} gameId The id of the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NameDescriptionGamesGameIdGet(gameId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription> {
            return NameDescriptionApiFp(configuration).v1NameDescriptionGamesGameIdGet(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the history for name or description in a provided language.
         * @param {number} gameId The id of the game
         * @param {RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest} request The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NameDescriptionGamesGameIdHistoryPost(gameId: number, request: RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetNameDescriptionHistoryResponse> {
            return NameDescriptionApiFp(configuration).v1NameDescriptionGamesGameIdHistoryPost(gameId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a game\'s name and description in multiple languages.
         * @param {number} gameId The id of the game.
         * @param {RobloxGameInternationalizationApiUpdateNameDescriptionsRequest} request The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NameDescriptionGamesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiUpdateNameDescriptionsRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateNameDescriptionsResponse> {
            return NameDescriptionApiFp(configuration).v1NameDescriptionGamesGameIdPatch(gameId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rollout settings for name/description migration to new page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1NameDescriptionMetadataGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiModelsResponseGameNameDescriptionMetadataResponse> {
            return NameDescriptionApiFp(configuration).v1NameDescriptionMetadataGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NameDescriptionApi - interface
 * @export
 * @interface NameDescriptionApi
 */
export interface NameDescriptionApiInterface {
    /**
     * 
     * @summary Gets a game\'s name and description in all supported languages
     * @param {number} gameId The id of the game
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NameDescriptionApiInterface
     */
    v1NameDescriptionGamesGameIdGet(gameId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiNameDescription>;

    /**
     * 
     * @summary Gets the history for name or description in a provided language.
     * @param {number} gameId The id of the game
     * @param {RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest} request The request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NameDescriptionApiInterface
     */
    v1NameDescriptionGamesGameIdHistoryPost(gameId: number, request: RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetNameDescriptionHistoryResponse>;

    /**
     * 
     * @summary Updates a game\'s name and description in multiple languages.
     * @param {number} gameId The id of the game.
     * @param {RobloxGameInternationalizationApiUpdateNameDescriptionsRequest} request The request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NameDescriptionApiInterface
     */
    v1NameDescriptionGamesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiUpdateNameDescriptionsRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateNameDescriptionsResponse>;

    /**
     * 
     * @summary Rollout settings for name/description migration to new page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NameDescriptionApiInterface
     */
    v1NameDescriptionMetadataGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiModelsResponseGameNameDescriptionMetadataResponse>;

}

/**
 * NameDescriptionApi - object-oriented interface
 * @export
 * @class NameDescriptionApi
 * @extends {BaseAPI}
 */
export class NameDescriptionApi extends BaseAPI implements NameDescriptionApiInterface {
    /**
     * 
     * @summary Gets a game\'s name and description in all supported languages
     * @param {number} gameId The id of the game
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NameDescriptionApi
     */
    public v1NameDescriptionGamesGameIdGet(gameId: number, options?: any) {
        return NameDescriptionApiFp(this.configuration).v1NameDescriptionGamesGameIdGet(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the history for name or description in a provided language.
     * @param {number} gameId The id of the game
     * @param {RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest} request The request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NameDescriptionApi
     */
    public v1NameDescriptionGamesGameIdHistoryPost(gameId: number, request: RobloxGameInternationalizationApiGetNameDescriptionHistoryRequest, options?: any) {
        return NameDescriptionApiFp(this.configuration).v1NameDescriptionGamesGameIdHistoryPost(gameId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a game\'s name and description in multiple languages.
     * @param {number} gameId The id of the game.
     * @param {RobloxGameInternationalizationApiUpdateNameDescriptionsRequest} request The request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NameDescriptionApi
     */
    public v1NameDescriptionGamesGameIdPatch(gameId: number, request: RobloxGameInternationalizationApiUpdateNameDescriptionsRequest, options?: any) {
        return NameDescriptionApiFp(this.configuration).v1NameDescriptionGamesGameIdPatch(gameId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rollout settings for name/description migration to new page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NameDescriptionApi
     */
    public v1NameDescriptionMetadataGet(options?: any) {
        return NameDescriptionApiFp(this.configuration).v1NameDescriptionMetadataGet(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PlayerPolicyApi - axios parameter creator
 * @export
 */
export const PlayerPolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PlayerPoliciesAllValuesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/player-policies/all-values`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PlayerPoliciesClientGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/player-policies-client`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} ipAddress 
         * @param {string} userAgent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PlayerPoliciesRccGet: async (userId: number, ipAddress: string, userAgent: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling v1PlayerPoliciesRccGet.');
            }
            // verify required parameter 'ipAddress' is not null or undefined
            if (ipAddress === null || ipAddress === undefined) {
                throw new RequiredError('ipAddress','Required parameter ipAddress was null or undefined when calling v1PlayerPoliciesRccGet.');
            }
            // verify required parameter 'userAgent' is not null or undefined
            if (userAgent === null || userAgent === undefined) {
                throw new RequiredError('userAgent','Required parameter userAgent was null or undefined when calling v1PlayerPoliciesRccGet.');
            }
            const localVarPath = `/v1/player-policies-rcc`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (ipAddress !== undefined) {
                localVarQueryParameter['ipAddress'] = ipAddress;
            }

            if (userAgent !== undefined) {
                localVarQueryParameter['userAgent'] = userAgent;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlayerPolicyApi - functional programming interface
 * @export
 */
export const PlayerPolicyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PlayerPoliciesAllValuesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetPlayerPoliciesResponse>> {
            const localVarAxiosArgs = await PlayerPolicyApiAxiosParamCreator(configuration).v1PlayerPoliciesAllValuesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PlayerPoliciesClientGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetPlayerPoliciesResponse>> {
            const localVarAxiosArgs = await PlayerPolicyApiAxiosParamCreator(configuration).v1PlayerPoliciesClientGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} ipAddress 
         * @param {string} userAgent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PlayerPoliciesRccGet(userId: number, ipAddress: string, userAgent: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetPlayerPoliciesResponse>> {
            const localVarAxiosArgs = await PlayerPolicyApiAxiosParamCreator(configuration).v1PlayerPoliciesRccGet(userId, ipAddress, userAgent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PlayerPolicyApi - factory interface
 * @export
 */
export const PlayerPolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PlayerPoliciesAllValuesGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiGetPlayerPoliciesResponse> {
            return PlayerPolicyApiFp(configuration).v1PlayerPoliciesAllValuesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PlayerPoliciesClientGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiGetPlayerPoliciesResponse> {
            return PlayerPolicyApiFp(configuration).v1PlayerPoliciesClientGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} ipAddress 
         * @param {string} userAgent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PlayerPoliciesRccGet(userId: number, ipAddress: string, userAgent: string, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetPlayerPoliciesResponse> {
            return PlayerPolicyApiFp(configuration).v1PlayerPoliciesRccGet(userId, ipAddress, userAgent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlayerPolicyApi - interface
 * @export
 * @interface PlayerPolicyApi
 */
export interface PlayerPolicyApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerPolicyApiInterface
     */
    v1PlayerPoliciesAllValuesGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiGetPlayerPoliciesResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerPolicyApiInterface
     */
    v1PlayerPoliciesClientGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiGetPlayerPoliciesResponse>;

    /**
     * 
     * @param {number} userId 
     * @param {string} ipAddress 
     * @param {string} userAgent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerPolicyApiInterface
     */
    v1PlayerPoliciesRccGet(userId: number, ipAddress: string, userAgent: string, options?: any): AxiosPromise<RobloxGameInternationalizationApiGetPlayerPoliciesResponse>;

}

/**
 * PlayerPolicyApi - object-oriented interface
 * @export
 * @class PlayerPolicyApi
 * @extends {BaseAPI}
 */
export class PlayerPolicyApi extends BaseAPI implements PlayerPolicyApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerPolicyApi
     */
    public v1PlayerPoliciesAllValuesGet(options?: any) {
        return PlayerPolicyApiFp(this.configuration).v1PlayerPoliciesAllValuesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerPolicyApi
     */
    public v1PlayerPoliciesClientGet(options?: any) {
        return PlayerPolicyApiFp(this.configuration).v1PlayerPoliciesClientGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {string} ipAddress 
     * @param {string} userAgent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlayerPolicyApi
     */
    public v1PlayerPoliciesRccGet(userId: number, ipAddress: string, userAgent: string, options?: any) {
        return PlayerPolicyApiFp(this.configuration).v1PlayerPoliciesRccGet(userId, ipAddress, userAgent, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SourceLanguageApi - axios parameter creator
 * @export
 */
export const SourceLanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the source language of a game
         * @param {number} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SourceLanguageGamesGameIdGet: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1SourceLanguageGamesGameIdGet.');
            }
            const localVarPath = `/v1/source-language/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the source language of a game
         * @param {number} gameId 
         * @param {string} languageCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SourceLanguageGamesGameIdPatch: async (gameId: number, languageCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1SourceLanguageGamesGameIdPatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1SourceLanguageGamesGameIdPatch.');
            }
            const localVarPath = `/v1/source-language/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SourceLanguageApi - functional programming interface
 * @export
 */
export const SourceLanguageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the source language of a game
         * @param {number} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SourceLanguageGamesGameIdGet(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiLanguage>> {
            const localVarAxiosArgs = await SourceLanguageApiAxiosParamCreator(configuration).v1SourceLanguageGamesGameIdGet(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets the source language of a game
         * @param {number} gameId 
         * @param {string} languageCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SourceLanguageGamesGameIdPatch(gameId: number, languageCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await SourceLanguageApiAxiosParamCreator(configuration).v1SourceLanguageGamesGameIdPatch(gameId, languageCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SourceLanguageApi - factory interface
 * @export
 */
export const SourceLanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets the source language of a game
         * @param {number} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SourceLanguageGamesGameIdGet(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiLanguage> {
            return SourceLanguageApiFp(configuration).v1SourceLanguageGamesGameIdGet(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the source language of a game
         * @param {number} gameId 
         * @param {string} languageCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SourceLanguageGamesGameIdPatch(gameId: number, languageCode: string, options?: any): AxiosPromise<object> {
            return SourceLanguageApiFp(configuration).v1SourceLanguageGamesGameIdPatch(gameId, languageCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SourceLanguageApi - interface
 * @export
 * @interface SourceLanguageApi
 */
export interface SourceLanguageApiInterface {
    /**
     * 
     * @summary Gets the source language of a game
     * @param {number} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourceLanguageApiInterface
     */
    v1SourceLanguageGamesGameIdGet(gameId: number, options?: any): AxiosPromise<RobloxGameInternationalizationApiLanguage>;

    /**
     * 
     * @summary Sets the source language of a game
     * @param {number} gameId 
     * @param {string} languageCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourceLanguageApiInterface
     */
    v1SourceLanguageGamesGameIdPatch(gameId: number, languageCode: string, options?: any): AxiosPromise<object>;

}

/**
 * SourceLanguageApi - object-oriented interface
 * @export
 * @class SourceLanguageApi
 * @extends {BaseAPI}
 */
export class SourceLanguageApi extends BaseAPI implements SourceLanguageApiInterface {
    /**
     * 
     * @summary Gets the source language of a game
     * @param {number} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourceLanguageApi
     */
    public v1SourceLanguageGamesGameIdGet(gameId: number, options?: any) {
        return SourceLanguageApiFp(this.configuration).v1SourceLanguageGamesGameIdGet(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the source language of a game
     * @param {number} gameId 
     * @param {string} languageCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourceLanguageApi
     */
    public v1SourceLanguageGamesGameIdPatch(gameId: number, languageCode: string, options?: any) {
        return SourceLanguageApiFp(this.configuration).v1SourceLanguageGamesGameIdPatch(gameId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SupportedLanguagesApi - axios parameter creator
 * @export
 */
export const SupportedLanguagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the automatic translation status of supported languages for a game.
         * @param {number} gameId The id of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdAutomaticTranslationStatusGet: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1SupportedLanguagesGamesGameIdAutomaticTranslationStatusGet.');
            }
            const localVarPath = `/v1/supported-languages/games/{gameId}/automatic-translation-status`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the supported languages for a game.
         * @param {number} gameId The id of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdGet: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1SupportedLanguagesGamesGameIdGet.');
            }
            const localVarPath = `/v1/supported-languages/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable or disable automatic translation for a game and language.
         * @param {number} gameId The id of the game.
         * @param {string} languageCode The language to enable or disable for automatic translation.
         * @param {boolean} enableAutomaticTranslation Flag to indicate if automatic translation should be enabled or disabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch: async (gameId: number, languageCode: string, enableAutomaticTranslation: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch.');
            }
            // verify required parameter 'enableAutomaticTranslation' is not null or undefined
            if (enableAutomaticTranslation === null || enableAutomaticTranslation === undefined) {
                throw new RequiredError('enableAutomaticTranslation','Required parameter enableAutomaticTranslation was null or undefined when calling v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch.');
            }
            const localVarPath = `/v1/supported-languages/games/{gameId}/languages/{languageCode}/automatic-translation-status`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof enableAutomaticTranslation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(enableAutomaticTranslation !== undefined ? enableAutomaticTranslation : {}) : (enableAutomaticTranslation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the switch which controls if the UniverseDisplayInformation should be automatically translated.
         * @param {number} gameId The game id.
         * @param {string} languageCode 
         * @param {boolean} enableUniverseDisplayInfoAutomaticTranslation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch: async (gameId: number, languageCode: string, enableUniverseDisplayInfoAutomaticTranslation: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch.');
            }
            // verify required parameter 'languageCode' is not null or undefined
            if (languageCode === null || languageCode === undefined) {
                throw new RequiredError('languageCode','Required parameter languageCode was null or undefined when calling v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch.');
            }
            // verify required parameter 'enableUniverseDisplayInfoAutomaticTranslation' is not null or undefined
            if (enableUniverseDisplayInfoAutomaticTranslation === null || enableUniverseDisplayInfoAutomaticTranslation === undefined) {
                throw new RequiredError('enableUniverseDisplayInfoAutomaticTranslation','Required parameter enableUniverseDisplayInfoAutomaticTranslation was null or undefined when calling v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch.');
            }
            const localVarPath = `/v1/supported-languages/games/{gameId}/languages/{languageCode}/universe-display-info-automatic-translation-settings`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)))
                .replace(`{${"languageCode"}}`, encodeURIComponent(String(languageCode)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof enableUniverseDisplayInfoAutomaticTranslation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(enableUniverseDisplayInfoAutomaticTranslation !== undefined ? enableUniverseDisplayInfoAutomaticTranslation : {}) : (enableUniverseDisplayInfoAutomaticTranslation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add or remove supported languages for a game.
         * @param {number} gameId The id of the game.
         * @param {Array<RobloxGameInternationalizationApiPatchLanguage>} languages The languages to add or remove. LanguageCodeType can be \&quot;Language\&quot; or \&quot;Locale\&quot;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdPatch: async (gameId: number, languages: Array<RobloxGameInternationalizationApiPatchLanguage>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1SupportedLanguagesGamesGameIdPatch.');
            }
            // verify required parameter 'languages' is not null or undefined
            if (languages === null || languages === undefined) {
                throw new RequiredError('languages','Required parameter languages was null or undefined when calling v1SupportedLanguagesGamesGameIdPatch.');
            }
            const localVarPath = `/v1/supported-languages/games/{gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof languages !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(languages !== undefined ? languages : {}) : (languages || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get UniverseDisplayInfo automatic translation settings..
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdUniverseDisplayInfoAutomaticTranslationSettingsGet: async (gameId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1SupportedLanguagesGamesGameIdUniverseDisplayInfoAutomaticTranslationSettingsGet.');
            }
            const localVarPath = `/v1/supported-languages/games/{gameId}/universe-display-info-automatic-translation-settings`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rollout settings for supported languages of a game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesMetadataGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/supported-languages/metadata`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportedLanguagesApi - functional programming interface
 * @export
 */
export const SupportedLanguagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the automatic translation status of supported languages for a game.
         * @param {number} gameId The id of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SupportedLanguagesGamesGameIdAutomaticTranslationStatusGet(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocaleSettings>> {
            const localVarAxiosArgs = await SupportedLanguagesApiAxiosParamCreator(configuration).v1SupportedLanguagesGamesGameIdAutomaticTranslationStatusGet(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the supported languages for a game.
         * @param {number} gameId The id of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SupportedLanguagesGamesGameIdGet(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocale>> {
            const localVarAxiosArgs = await SupportedLanguagesApiAxiosParamCreator(configuration).v1SupportedLanguagesGamesGameIdGet(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Enable or disable automatic translation for a game and language.
         * @param {number} gameId The id of the game.
         * @param {string} languageCode The language to enable or disable for automatic translation.
         * @param {boolean} enableAutomaticTranslation Flag to indicate if automatic translation should be enabled or disabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch(gameId: number, languageCode: string, enableAutomaticTranslation: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiEditAutomaticTranslationStatusForGameAndLanguageResponse>> {
            const localVarAxiosArgs = await SupportedLanguagesApiAxiosParamCreator(configuration).v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch(gameId, languageCode, enableAutomaticTranslation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the switch which controls if the UniverseDisplayInformation should be automatically translated.
         * @param {number} gameId The game id.
         * @param {string} languageCode 
         * @param {boolean} enableUniverseDisplayInfoAutomaticTranslation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch(gameId: number, languageCode: string, enableUniverseDisplayInfoAutomaticTranslation: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiUpdateUniverseDisplayInfoAutomaticTranslationSettingsResponse>> {
            const localVarAxiosArgs = await SupportedLanguagesApiAxiosParamCreator(configuration).v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch(gameId, languageCode, enableUniverseDisplayInfoAutomaticTranslation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add or remove supported languages for a game.
         * @param {number} gameId The id of the game.
         * @param {Array<RobloxGameInternationalizationApiPatchLanguage>} languages The languages to add or remove. LanguageCodeType can be \&quot;Language\&quot; or \&quot;Locale\&quot;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SupportedLanguagesGamesGameIdPatch(gameId: number, languages: Array<RobloxGameInternationalizationApiPatchLanguage>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await SupportedLanguagesApiAxiosParamCreator(configuration).v1SupportedLanguagesGamesGameIdPatch(gameId, languages, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get UniverseDisplayInfo automatic translation settings..
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SupportedLanguagesGamesGameIdUniverseDisplayInfoAutomaticTranslationSettingsGet(gameId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings>> {
            const localVarAxiosArgs = await SupportedLanguagesApiAxiosParamCreator(configuration).v1SupportedLanguagesGamesGameIdUniverseDisplayInfoAutomaticTranslationSettingsGet(gameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Rollout settings for supported languages of a game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1SupportedLanguagesMetadataGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse>> {
            const localVarAxiosArgs = await SupportedLanguagesApiAxiosParamCreator(configuration).v1SupportedLanguagesMetadataGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SupportedLanguagesApi - factory interface
 * @export
 */
export const SupportedLanguagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get the automatic translation status of supported languages for a game.
         * @param {number} gameId The id of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdAutomaticTranslationStatusGet(gameId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocaleSettings> {
            return SupportedLanguagesApiFp(configuration).v1SupportedLanguagesGamesGameIdAutomaticTranslationStatusGet(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the supported languages for a game.
         * @param {number} gameId The id of the game.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdGet(gameId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocale> {
            return SupportedLanguagesApiFp(configuration).v1SupportedLanguagesGamesGameIdGet(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable or disable automatic translation for a game and language.
         * @param {number} gameId The id of the game.
         * @param {string} languageCode The language to enable or disable for automatic translation.
         * @param {boolean} enableAutomaticTranslation Flag to indicate if automatic translation should be enabled or disabled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch(gameId: number, languageCode: string, enableAutomaticTranslation: boolean, options?: any): AxiosPromise<RobloxGameInternationalizationApiEditAutomaticTranslationStatusForGameAndLanguageResponse> {
            return SupportedLanguagesApiFp(configuration).v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch(gameId, languageCode, enableAutomaticTranslation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the switch which controls if the UniverseDisplayInformation should be automatically translated.
         * @param {number} gameId The game id.
         * @param {string} languageCode 
         * @param {boolean} enableUniverseDisplayInfoAutomaticTranslation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch(gameId: number, languageCode: string, enableUniverseDisplayInfoAutomaticTranslation: boolean, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateUniverseDisplayInfoAutomaticTranslationSettingsResponse> {
            return SupportedLanguagesApiFp(configuration).v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch(gameId, languageCode, enableUniverseDisplayInfoAutomaticTranslation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add or remove supported languages for a game.
         * @param {number} gameId The id of the game.
         * @param {Array<RobloxGameInternationalizationApiPatchLanguage>} languages The languages to add or remove. LanguageCodeType can be \&quot;Language\&quot; or \&quot;Locale\&quot;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdPatch(gameId: number, languages: Array<RobloxGameInternationalizationApiPatchLanguage>, options?: any): AxiosPromise<object> {
            return SupportedLanguagesApiFp(configuration).v1SupportedLanguagesGamesGameIdPatch(gameId, languages, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get UniverseDisplayInfo automatic translation settings..
         * @param {number} gameId The game id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesGamesGameIdUniverseDisplayInfoAutomaticTranslationSettingsGet(gameId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings> {
            return SupportedLanguagesApiFp(configuration).v1SupportedLanguagesGamesGameIdUniverseDisplayInfoAutomaticTranslationSettingsGet(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rollout settings for supported languages of a game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1SupportedLanguagesMetadataGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse> {
            return SupportedLanguagesApiFp(configuration).v1SupportedLanguagesMetadataGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportedLanguagesApi - interface
 * @export
 * @interface SupportedLanguagesApi
 */
export interface SupportedLanguagesApiInterface {
    /**
     * 
     * @summary Get the automatic translation status of supported languages for a game.
     * @param {number} gameId The id of the game.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApiInterface
     */
    v1SupportedLanguagesGamesGameIdAutomaticTranslationStatusGet(gameId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocaleSettings>;

    /**
     * 
     * @summary Get the supported languages for a game.
     * @param {number} gameId The id of the game.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApiInterface
     */
    v1SupportedLanguagesGamesGameIdGet(gameId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiLanguageOrLocale>;

    /**
     * 
     * @summary Enable or disable automatic translation for a game and language.
     * @param {number} gameId The id of the game.
     * @param {string} languageCode The language to enable or disable for automatic translation.
     * @param {boolean} enableAutomaticTranslation Flag to indicate if automatic translation should be enabled or disabled.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApiInterface
     */
    v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch(gameId: number, languageCode: string, enableAutomaticTranslation: boolean, options?: any): AxiosPromise<RobloxGameInternationalizationApiEditAutomaticTranslationStatusForGameAndLanguageResponse>;

    /**
     * 
     * @summary Update the switch which controls if the UniverseDisplayInformation should be automatically translated.
     * @param {number} gameId The game id.
     * @param {string} languageCode 
     * @param {boolean} enableUniverseDisplayInfoAutomaticTranslation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApiInterface
     */
    v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch(gameId: number, languageCode: string, enableUniverseDisplayInfoAutomaticTranslation: boolean, options?: any): AxiosPromise<RobloxGameInternationalizationApiUpdateUniverseDisplayInfoAutomaticTranslationSettingsResponse>;

    /**
     * 
     * @summary Add or remove supported languages for a game.
     * @param {number} gameId The id of the game.
     * @param {Array<RobloxGameInternationalizationApiPatchLanguage>} languages The languages to add or remove. LanguageCodeType can be \&quot;Language\&quot; or \&quot;Locale\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApiInterface
     */
    v1SupportedLanguagesGamesGameIdPatch(gameId: number, languages: Array<RobloxGameInternationalizationApiPatchLanguage>, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Get UniverseDisplayInfo automatic translation settings..
     * @param {number} gameId The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApiInterface
     */
    v1SupportedLanguagesGamesGameIdUniverseDisplayInfoAutomaticTranslationSettingsGet(gameId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGameInternationalizationApiUniverseDisplayInfoAutomaticTranslationSettings>;

    /**
     * 
     * @summary Rollout settings for supported languages of a game
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApiInterface
     */
    v1SupportedLanguagesMetadataGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiSupportedLanguagesMetadataResponse>;

}

/**
 * SupportedLanguagesApi - object-oriented interface
 * @export
 * @class SupportedLanguagesApi
 * @extends {BaseAPI}
 */
export class SupportedLanguagesApi extends BaseAPI implements SupportedLanguagesApiInterface {
    /**
     * 
     * @summary Get the automatic translation status of supported languages for a game.
     * @param {number} gameId The id of the game.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApi
     */
    public v1SupportedLanguagesGamesGameIdAutomaticTranslationStatusGet(gameId: number, options?: any) {
        return SupportedLanguagesApiFp(this.configuration).v1SupportedLanguagesGamesGameIdAutomaticTranslationStatusGet(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the supported languages for a game.
     * @param {number} gameId The id of the game.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApi
     */
    public v1SupportedLanguagesGamesGameIdGet(gameId: number, options?: any) {
        return SupportedLanguagesApiFp(this.configuration).v1SupportedLanguagesGamesGameIdGet(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable or disable automatic translation for a game and language.
     * @param {number} gameId The id of the game.
     * @param {string} languageCode The language to enable or disable for automatic translation.
     * @param {boolean} enableAutomaticTranslation Flag to indicate if automatic translation should be enabled or disabled.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApi
     */
    public v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch(gameId: number, languageCode: string, enableAutomaticTranslation: boolean, options?: any) {
        return SupportedLanguagesApiFp(this.configuration).v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeAutomaticTranslationStatusPatch(gameId, languageCode, enableAutomaticTranslation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the switch which controls if the UniverseDisplayInformation should be automatically translated.
     * @param {number} gameId The game id.
     * @param {string} languageCode 
     * @param {boolean} enableUniverseDisplayInfoAutomaticTranslation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApi
     */
    public v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch(gameId: number, languageCode: string, enableUniverseDisplayInfoAutomaticTranslation: boolean, options?: any) {
        return SupportedLanguagesApiFp(this.configuration).v1SupportedLanguagesGamesGameIdLanguagesLanguageCodeUniverseDisplayInfoAutomaticTranslationSettingsPatch(gameId, languageCode, enableUniverseDisplayInfoAutomaticTranslation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add or remove supported languages for a game.
     * @param {number} gameId The id of the game.
     * @param {Array<RobloxGameInternationalizationApiPatchLanguage>} languages The languages to add or remove. LanguageCodeType can be \&quot;Language\&quot; or \&quot;Locale\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApi
     */
    public v1SupportedLanguagesGamesGameIdPatch(gameId: number, languages: Array<RobloxGameInternationalizationApiPatchLanguage>, options?: any) {
        return SupportedLanguagesApiFp(this.configuration).v1SupportedLanguagesGamesGameIdPatch(gameId, languages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get UniverseDisplayInfo automatic translation settings..
     * @param {number} gameId The game id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApi
     */
    public v1SupportedLanguagesGamesGameIdUniverseDisplayInfoAutomaticTranslationSettingsGet(gameId: number, options?: any) {
        return SupportedLanguagesApiFp(this.configuration).v1SupportedLanguagesGamesGameIdUniverseDisplayInfoAutomaticTranslationSettingsGet(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rollout settings for supported languages of a game
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportedLanguagesApi
     */
    public v1SupportedLanguagesMetadataGet(options?: any) {
        return SupportedLanguagesApiFp(this.configuration).v1SupportedLanguagesMetadataGet(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * TranslationAnalyticsApi - axios parameter creator
 * @export
 */
export const TranslationAnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download translation analytics report after the report is ready
         * @param {number} gameId The game\&#39;s id
         * @param {string} startDateTime The inclusive start dateTime of report in UTC
         * @param {string} endDateTime The exclusive end dateTime of report in UTC
         * @param {'GameTranslationStatus' | 'GameTranslationStatusForTranslatorGroup' | 'GameTranslationStatusForTranslator' | 'Test'} reportType The report type
         * @param {number} reportSubjectTargetId The translator group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet: async (gameId: number, startDateTime: string, endDateTime: string, reportType: 'GameTranslationStatus' | 'GameTranslationStatusForTranslatorGroup' | 'GameTranslationStatusForTranslator' | 'Test', reportSubjectTargetId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet.');
            }
            // verify required parameter 'startDateTime' is not null or undefined
            if (startDateTime === null || startDateTime === undefined) {
                throw new RequiredError('startDateTime','Required parameter startDateTime was null or undefined when calling v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet.');
            }
            // verify required parameter 'endDateTime' is not null or undefined
            if (endDateTime === null || endDateTime === undefined) {
                throw new RequiredError('endDateTime','Required parameter endDateTime was null or undefined when calling v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet.');
            }
            // verify required parameter 'reportType' is not null or undefined
            if (reportType === null || reportType === undefined) {
                throw new RequiredError('reportType','Required parameter reportType was null or undefined when calling v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet.');
            }
            // verify required parameter 'reportSubjectTargetId' is not null or undefined
            if (reportSubjectTargetId === null || reportSubjectTargetId === undefined) {
                throw new RequiredError('reportSubjectTargetId','Required parameter reportSubjectTargetId was null or undefined when calling v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet.');
            }
            const localVarPath = `/v1/translation-analytics/games/{gameId}/download-translation-analytics-report`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDateTime !== undefined) {
                localVarQueryParameter['startDateTime'] = startDateTime;
            }

            if (endDateTime !== undefined) {
                localVarQueryParameter['endDateTime'] = endDateTime;
            }

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }

            if (reportSubjectTargetId !== undefined) {
                localVarQueryParameter['reportSubjectTargetId'] = reportSubjectTargetId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request translation analytics report to be generated
         * @param {number} gameId The game\&#39;s id
         * @param {RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest} request The request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TranslationAnalyticsGamesGameIdRequestTranslationAnalyticsReportPost: async (gameId: number, request: RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            if (gameId === null || gameId === undefined) {
                throw new RequiredError('gameId','Required parameter gameId was null or undefined when calling v1TranslationAnalyticsGamesGameIdRequestTranslationAnalyticsReportPost.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1TranslationAnalyticsGamesGameIdRequestTranslationAnalyticsReportPost.');
            }
            const localVarPath = `/v1/translation-analytics/games/{gameId}/request-translation-analytics-report`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metadata related to UI and rollout settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TranslationAnalyticsMetadataGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/translation-analytics/metadata`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationAnalyticsApi - functional programming interface
 * @export
 */
export const TranslationAnalyticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download translation analytics report after the report is ready
         * @param {number} gameId The game\&#39;s id
         * @param {string} startDateTime The inclusive start dateTime of report in UTC
         * @param {string} endDateTime The exclusive end dateTime of report in UTC
         * @param {'GameTranslationStatus' | 'GameTranslationStatusForTranslatorGroup' | 'GameTranslationStatusForTranslator' | 'Test'} reportType The report type
         * @param {number} reportSubjectTargetId The translator group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet(gameId: number, startDateTime: string, endDateTime: string, reportType: 'GameTranslationStatus' | 'GameTranslationStatusForTranslatorGroup' | 'GameTranslationStatusForTranslator' | 'Test', reportSubjectTargetId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await TranslationAnalyticsApiAxiosParamCreator(configuration).v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet(gameId, startDateTime, endDateTime, reportType, reportSubjectTargetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Request translation analytics report to be generated
         * @param {number} gameId The game\&#39;s id
         * @param {RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest} request The request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TranslationAnalyticsGamesGameIdRequestTranslationAnalyticsReportPost(gameId: number, request: RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiRequestTranslationAnalyticsReportResponse>> {
            const localVarAxiosArgs = await TranslationAnalyticsApiAxiosParamCreator(configuration).v1TranslationAnalyticsGamesGameIdRequestTranslationAnalyticsReportPost(gameId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get metadata related to UI and rollout settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1TranslationAnalyticsMetadataGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiTranslationAnalyticsMetadataResponse>> {
            const localVarAxiosArgs = await TranslationAnalyticsApiAxiosParamCreator(configuration).v1TranslationAnalyticsMetadataGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TranslationAnalyticsApi - factory interface
 * @export
 */
export const TranslationAnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Download translation analytics report after the report is ready
         * @param {number} gameId The game\&#39;s id
         * @param {string} startDateTime The inclusive start dateTime of report in UTC
         * @param {string} endDateTime The exclusive end dateTime of report in UTC
         * @param {'GameTranslationStatus' | 'GameTranslationStatusForTranslatorGroup' | 'GameTranslationStatusForTranslator' | 'Test'} reportType The report type
         * @param {number} reportSubjectTargetId The translator group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet(gameId: number, startDateTime: string, endDateTime: string, reportType: 'GameTranslationStatus' | 'GameTranslationStatusForTranslatorGroup' | 'GameTranslationStatusForTranslator' | 'Test', reportSubjectTargetId: number, options?: any): AxiosPromise<object> {
            return TranslationAnalyticsApiFp(configuration).v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet(gameId, startDateTime, endDateTime, reportType, reportSubjectTargetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request translation analytics report to be generated
         * @param {number} gameId The game\&#39;s id
         * @param {RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest} request The request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TranslationAnalyticsGamesGameIdRequestTranslationAnalyticsReportPost(gameId: number, request: RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiRequestTranslationAnalyticsReportResponse> {
            return TranslationAnalyticsApiFp(configuration).v1TranslationAnalyticsGamesGameIdRequestTranslationAnalyticsReportPost(gameId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metadata related to UI and rollout settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1TranslationAnalyticsMetadataGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiTranslationAnalyticsMetadataResponse> {
            return TranslationAnalyticsApiFp(configuration).v1TranslationAnalyticsMetadataGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationAnalyticsApi - interface
 * @export
 * @interface TranslationAnalyticsApi
 */
export interface TranslationAnalyticsApiInterface {
    /**
     * 
     * @summary Download translation analytics report after the report is ready
     * @param {number} gameId The game\&#39;s id
     * @param {string} startDateTime The inclusive start dateTime of report in UTC
     * @param {string} endDateTime The exclusive end dateTime of report in UTC
     * @param {'GameTranslationStatus' | 'GameTranslationStatusForTranslatorGroup' | 'GameTranslationStatusForTranslator' | 'Test'} reportType The report type
     * @param {number} reportSubjectTargetId The translator group id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationAnalyticsApiInterface
     */
    v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet(gameId: number, startDateTime: string, endDateTime: string, reportType: 'GameTranslationStatus' | 'GameTranslationStatusForTranslatorGroup' | 'GameTranslationStatusForTranslator' | 'Test', reportSubjectTargetId: number, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Request translation analytics report to be generated
     * @param {number} gameId The game\&#39;s id
     * @param {RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest} request The request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationAnalyticsApiInterface
     */
    v1TranslationAnalyticsGamesGameIdRequestTranslationAnalyticsReportPost(gameId: number, request: RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest, options?: any): AxiosPromise<RobloxGameInternationalizationApiRequestTranslationAnalyticsReportResponse>;

    /**
     * 
     * @summary Get metadata related to UI and rollout settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationAnalyticsApiInterface
     */
    v1TranslationAnalyticsMetadataGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiTranslationAnalyticsMetadataResponse>;

}

/**
 * TranslationAnalyticsApi - object-oriented interface
 * @export
 * @class TranslationAnalyticsApi
 * @extends {BaseAPI}
 */
export class TranslationAnalyticsApi extends BaseAPI implements TranslationAnalyticsApiInterface {
    /**
     * 
     * @summary Download translation analytics report after the report is ready
     * @param {number} gameId The game\&#39;s id
     * @param {string} startDateTime The inclusive start dateTime of report in UTC
     * @param {string} endDateTime The exclusive end dateTime of report in UTC
     * @param {'GameTranslationStatus' | 'GameTranslationStatusForTranslatorGroup' | 'GameTranslationStatusForTranslator' | 'Test'} reportType The report type
     * @param {number} reportSubjectTargetId The translator group id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationAnalyticsApi
     */
    public v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet(gameId: number, startDateTime: string, endDateTime: string, reportType: 'GameTranslationStatus' | 'GameTranslationStatusForTranslatorGroup' | 'GameTranslationStatusForTranslator' | 'Test', reportSubjectTargetId: number, options?: any) {
        return TranslationAnalyticsApiFp(this.configuration).v1TranslationAnalyticsGamesGameIdDownloadTranslationAnalyticsReportGet(gameId, startDateTime, endDateTime, reportType, reportSubjectTargetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request translation analytics report to be generated
     * @param {number} gameId The game\&#39;s id
     * @param {RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest} request The request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationAnalyticsApi
     */
    public v1TranslationAnalyticsGamesGameIdRequestTranslationAnalyticsReportPost(gameId: number, request: RobloxGameInternationalizationApiRequestTranslationAnalyticsReportRequest, options?: any) {
        return TranslationAnalyticsApiFp(this.configuration).v1TranslationAnalyticsGamesGameIdRequestTranslationAnalyticsReportPost(gameId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metadata related to UI and rollout settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationAnalyticsApi
     */
    public v1TranslationAnalyticsMetadataGet(options?: any) {
        return TranslationAnalyticsApiFp(this.configuration).v1TranslationAnalyticsMetadataGet(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UiConfigurationsApi - axios parameter creator
 * @export
 */
export const UiConfigurationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get ui configurations for frontend to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UiConfigurationsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ui-configurations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UiConfigurationsApi - functional programming interface
 * @export
 */
export const UiConfigurationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get ui configurations for frontend to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1UiConfigurationsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGameInternationalizationApiGetUiConfigurationsResponse>> {
            const localVarAxiosArgs = await UiConfigurationsApiAxiosParamCreator(configuration).v1UiConfigurationsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UiConfigurationsApi - factory interface
 * @export
 */
export const UiConfigurationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get ui configurations for frontend to use.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1UiConfigurationsGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiGetUiConfigurationsResponse> {
            return UiConfigurationsApiFp(configuration).v1UiConfigurationsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UiConfigurationsApi - interface
 * @export
 * @interface UiConfigurationsApi
 */
export interface UiConfigurationsApiInterface {
    /**
     * 
     * @summary Get ui configurations for frontend to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UiConfigurationsApiInterface
     */
    v1UiConfigurationsGet(options?: any): AxiosPromise<RobloxGameInternationalizationApiGetUiConfigurationsResponse>;

}

/**
 * UiConfigurationsApi - object-oriented interface
 * @export
 * @class UiConfigurationsApi
 * @extends {BaseAPI}
 */
export class UiConfigurationsApi extends BaseAPI implements UiConfigurationsApiInterface {
    /**
     * 
     * @summary Get ui configurations for frontend to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UiConfigurationsApi
     */
    public v1UiConfigurationsGet(options?: any) {
        return UiConfigurationsApiFp(this.configuration).v1UiConfigurationsGet(options).then((request) => request(this.axios, this.basePath));
    }

}
