import { AxiosPromise } from 'axios';
import { EnvironmentUrls } from 'Roblox';
import { httpService } from '../../../http/http';
import * as ThumbnailsProvider from '../../providers/thumbnails/thumbnailsProvider';

const { thumbnailsApi } = EnvironmentUrls;

const gameThumbnailsApiInstance = new ThumbnailsProvider.GamesApi();
const gamePassesApiInstance = new ThumbnailsProvider.GamePassesApi();

enum ReturnPolicy {
  PlaceHolder = 'PlaceHolder',
  AutoGenerated = 'AutoGenerated',
  ForceAutoGenerated = 'ForceAutoGenerated'
}

enum Size {
  Large = '150x150',
  Default = '50x50'
}

enum GameThumbnailSize {
  width768 = '768x432',
  width576 = '576x324',
  width480 = '480x270',
  width384 = '384x216',
  width256 = '256x144'
}

enum FileType {
  Png = 'Png'
}

const getIcons = (
  universeIds: Array<number>,
  returnPolicy?: ReturnPolicy,
  size?: Size,
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return gameThumbnailsApiInstance.v1GamesIconsGet(
    universeIds,
    returnPolicy,
    size,
    format,
    isCircular,
    { withCredentials: true }
  );
};
// deprecated remove after rollout
const getUniverseThumbnails = (
  universeIds: Array<number>,
  size?: GameThumbnailSize,
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return gameThumbnailsApiInstance
    .v1GamesMultigetThumbnailsGet(universeIds, 1, true, size, format, isCircular, {
      withCredentials: true
    })
    .then(({ data, status, statusText, headers, request }) => {
      const formattedData = data?.data?.map(item => {
        const thumbnail = item.thumbnails?.[0];
        return {
          targetId: item.universeId,
          state: thumbnail?.state,
          imageUrl: thumbnail?.imageUrl
        };
      });
      return {
        data: {
          data: formattedData
        },
        status,
        statusText,
        headers,
        request
      };
    }) as AxiosPromise<
    ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;
};

const getAllUniverseThumbnails = (
  universeIds: Array<number>,
  size?: GameThumbnailSize,
  format?: number,
  isCircular?: boolean,
  limit = 10
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse
> => {
  return gameThumbnailsApiInstance.v1GamesMultigetThumbnailsGet(
    universeIds,
    limit,
    true,
    size,
    format,
    isCircular,
    { withCredentials: true }
  );
};

const getGamePassIcons = (
  gamePassIds: Array<number>,
  size?: '150x150',
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return gamePassesApiInstance.v1GamePassesGet(gamePassIds, size, format, isCircular, {
    withCredentials: true
  });
};

const getThumbnailsByThumbnailIds = (
  universeId: number,
  thumbnailIds: Array<number>,
  size?: GameThumbnailSize,
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return gameThumbnailsApiInstance.v1GamesUniverseIdThumbnailsGet(
    universeId,
    thumbnailIds,
    size,
    format,
    isCircular,
    {
      withCredentials: true
    }
  );
};

const getPlacesGameIcons = (
  placeIds: Array<number>,
  returnPolicy?: ReturnPolicy,
  size?: Size,
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  const urlConfig = {
    url: `${thumbnailsApi}/v1/places/gameicons`,
    withCredentials: true,
    retryable: true
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return httpService.get(urlConfig, {
    placeIds,
    returnPolicy,
    size,
    format,
    isCircular
  });
};

export default {
  getIcons,
  getUniverseThumbnails,
  getAllUniverseThumbnails,
  getGamePassIcons,
  getThumbnailsByThumbnailIds,
  getPlacesGameIcons,
  ReturnPolicy,
  Size,
  GameThumbnailSize,
  FileType
};
