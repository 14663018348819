import { AxiosPromise } from 'axios';
import * as ThumbnailsProvider from '../../providers/thumbnails/thumbnailsProvider';

const assetsApiInstance = new ThumbnailsProvider.AssetsApi();
const bundlesApiInstance = new ThumbnailsProvider.BundlesApi();
const avatarApiInstance = new ThumbnailsProvider.AvatarApi();
const groupEmblemApiInstance = new ThumbnailsProvider.GroupEmblemApi();
const badgesApiInstance = new ThumbnailsProvider.BadgesApi();
const developerProductsApiInstance = new ThumbnailsProvider.DeveloperProductsApi();
const outfitApiInstance = new ThumbnailsProvider.OutfitsApi();
const batchApiInstance = new ThumbnailsProvider.BatchApi();

const getAssets = (
  assetIds: Array<number>,
  returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
  size?: '150x150' | '420x420' | '768x432',
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return assetsApiInstance.v1AssetsGet(assetIds, returnPolicy, size, format, isCircular, {
    withCredentials: true
  });
};
const getAvatars = (
  userIds: Array<number>,
  size?: '100x100' | '352x352' | '720x720',
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return avatarApiInstance.v1UsersAvatarGet(userIds, size, format, isCircular, {
    withCredentials: true
  });
};

const getAvatarHeadshots = (
  userIds: Array<number>,
  size?: '48x48' | '60x60' | '150x150',
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return avatarApiInstance.v1UsersAvatarHeadshotGet(userIds, size, format, isCircular, {
    withCredentials: true
  });
};

const getBundles = (
  bundleIds: Array<number>,
  size?: '150x150' | '420x420',
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return bundlesApiInstance.v1BundlesThumbnailsGet(bundleIds, size, format, isCircular, {
    withCredentials: true
  });
};

const getGroupIcons = (
  groupIds: Array<number>,
  size?: '150x150' | '420x420',
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return groupEmblemApiInstance.v1GroupsIconsGet(groupIds, size, format, isCircular, {
    withCredentials: true
  });
};

const getBadgeIcons = (
  badgeIds: Array<number>,
  size?: '150x150',
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return badgesApiInstance.v1BadgesIconsGet(badgeIds, size, format, isCircular, {
    withCredentials: true
  });
};

const getDeveloperProductIcons = (
  developerProductIds: Array<number>,
  size?: '150x150',
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return developerProductsApiInstance.v1DeveloperProductsIconsGet(
    developerProductIds,
    size,
    format,
    isCircular,
    { withCredentials: true }
  );
};

const getUserOutfits = (
  userOutfitIds: Array<number>,
  size?: '150x150' | '420x420',
  format?: number,
  isCircular?: boolean
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
> => {
  return outfitApiInstance.v1UsersOutfitsGet(userOutfitIds, size, format, isCircular, {
    withCredentials: true
  });
};

const getBatchThumbnails = (
  requests: Array<ThumbnailsProvider.RobloxThumbnailsApisModelsThumbnailBatchRequest>
): AxiosPromise<
  ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse
> => {
  return batchApiInstance.v1BatchPost(requests, { withCredentials: true });
};
export default {
  getAssets,
  getAvatars,
  getAvatarHeadshots,
  getGroupIcons,
  getBadgeIcons,
  getDeveloperProductIcons,
  getBundles,
  getUserOutfits,
  getBatchThumbnails
};
