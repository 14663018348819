// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Locale Api v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from 'url';
import { Configuration } from './configuration';
import { httpClient as globalAxios } from '../../../http/http';
import { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiArrayResponseLanguage
 */
export interface ApiArrayResponseLanguage {
    /**
     * 
     * @type {Array<Language>}
     * @memberof ApiArrayResponseLanguage
     */
    data?: Array<Language>;
}

/**
 * 
 * @export
 * @interface ApiArrayResponseSupportedLocaleLocus
 */
export interface ApiArrayResponseSupportedLocaleLocus {
    /**
     * 
     * @type {Array<SupportedLocaleLocus>}
     * @memberof ApiArrayResponseSupportedLocaleLocus
     */
    data?: Array<SupportedLocaleLocus>;
}

/**
 * Model for Country Regions
 * @export
 * @interface CountryRegion
 */
export interface CountryRegion {
    /**
     * id of country region
     * @type {number}
     * @memberof CountryRegion
     */
    id?: number;
    /**
     * name of country region. Example \"Afghanistan\"
     * @type {string}
     * @memberof CountryRegion
     */
    displayName?: string;
}

/**
 * Returns list of supported country/regions
 * @export
 * @interface CountryRegionListResponse
 */
export interface CountryRegionListResponse {
    /**
     * List of supported country/regions. Will be empty on error.
     * @type {Array<CountryRegion>}
     * @memberof CountryRegionListResponse
     */
    countryRegionList?: Array<CountryRegion>;
}

/**
 * Model for Language
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * id of language
     * @type {number}
     * @memberof Language
     */
    id?: number;
    /**
     * name of language
     * @type {string}
     * @memberof Language
     */
    name?: string;
    /**
     * native name of language
     * @type {string}
     * @memberof Language
     */
    nativeName?: string;
    /**
     * language code of language
     * @type {string}
     * @memberof Language
     */
    languageCode?: string;
}

/**
 * Request entity to set Supported Locale for user
 * @export
 * @interface SetSupportedLocaleForUserRequest
 */
export interface SetSupportedLocaleForUserRequest {
    /**
     * SupportedLocale code
     * @type {string}
     * @memberof SetSupportedLocaleForUserRequest
     */
    supportedLocaleCode?: string;
}

/**
 * Request entity to set country for user
 * @export
 * @interface SetUserCountryRegionRequest
 */
export interface SetUserCountryRegionRequest {
    /**
     * Country id
     * @type {number}
     * @memberof SetUserCountryRegionRequest
     */
    countryId?: number;
}

/**
 * Tells the operation is successful or not
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * Returns true on success otherwise false
     * @type {boolean}
     * @memberof SuccessResponse
     */
    success?: boolean;
}

/**
 * Model for Supported locale
 * @export
 * @interface SupportedLocale
 */
export interface SupportedLocale {
    /**
     * id of supported locale
     * @type {number}
     * @memberof SupportedLocale
     */
    id?: number;
    /**
     * locale of supported locale. Example \"en-us\"
     * @type {string}
     * @memberof SupportedLocale
     */
    locale?: string;
    /**
     * name of supported locale. Example \"English(US)\"
     * @type {string}
     * @memberof SupportedLocale
     */
    name?: string;
    /**
     * Name of supported locale in native language. Example \"English\"
     * @type {string}
     * @memberof SupportedLocale
     */
    nativeName?: string;
    /**
     * 
     * @type {Language}
     * @memberof SupportedLocale
     */
    language?: Language;
}

/**
 * Model for Supported locale with user locus information
 * @export
 * @interface SupportedLocaleLocus
 */
export interface SupportedLocaleLocus {
    /**
     * 
     * @type {SupportedLocale}
     * @memberof SupportedLocaleLocus
     */
    locale?: SupportedLocale;
    /**
     * Is locale enabled for full experience
     * @type {boolean}
     * @memberof SupportedLocaleLocus
     */
    isEnabledForFullExperience?: boolean;
    /**
     * Is locale enabled for signup and login
     * @type {boolean}
     * @memberof SupportedLocaleLocus
     */
    isEnabledForSignupAndLogin?: boolean;
}

/**
 * Returns list of supported locales
 * @export
 * @interface SupportedLocalesResponse
 */
export interface SupportedLocalesResponse {
    /**
     * List of supported locales. Will be empty on error.
     * @type {Array<SupportedLocale>}
     * @memberof SupportedLocalesResponse
     */
    supportedLocales?: Array<SupportedLocale>;
}

/**
 * Returns user's Country/region
 * @export
 * @interface UserCountryRegionResponse
 */
export interface UserCountryRegionResponse {
    /**
     * 
     * @type {CountryRegion}
     * @memberof UserCountryRegionResponse
     */
    userCountryRegion?: CountryRegion;
}

/**
 * 
 * @export
 * @interface UserLocaleResponse
 */
export interface UserLocaleResponse {
    /**
     * 
     * @type {SupportedLocale}
     * @memberof UserLocaleResponse
     */
    supportedLocale?: SupportedLocale;
    /**
     * 
     * @type {Language}
     * @memberof UserLocaleResponse
     */
    nativeLanguage?: Language;
}

/**
 * Returns available {Roblox.Locale.Api.SupportedLocale} models.
 * @export
 * @interface UserLocalizationLocusLocalesResponse
 */
export interface UserLocalizationLocusLocalesResponse {
    /**
     * 
     * @type {SupportedLocale}
     * @memberof UserLocalizationLocusLocalesResponse
     */
    signupAndLogin?: SupportedLocale;
    /**
     * 
     * @type {SupportedLocale}
     * @memberof UserLocalizationLocusLocalesResponse
     */
    generalExperience?: SupportedLocale;
    /**
     * 
     * @type {SupportedLocale}
     * @memberof UserLocalizationLocusLocalesResponse
     */
    ugc?: SupportedLocale;
}


/**
 * CountryRegionsApi - axios parameter creator
 * @export
 */
export const CountryRegionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of country regions sorted by Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountryRegionsGet(options: any = {}): RequestArgs {
            const localVarPath = `/v1/country-regions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets user's country region.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountryRegionsUserCountryRegionGet(options: any = {}): RequestArgs {
            const localVarPath = `/v1/country-regions/user-country-region`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets user's countrt region.
         * @param {SetUserCountryRegionRequest} userRequest Country ID that needs to be set for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountryRegionsUserCountryRegionPatch(userRequest: SetUserCountryRegionRequest, options: any = {}): RequestArgs {
            // verify required parameter 'userRequest' is not null or undefined
            if (userRequest === null || userRequest === undefined) {
                throw new RequiredError('userRequest','Required parameter userRequest was null or undefined when calling v1CountryRegionsUserCountryRegionPatch.');
            }
            const localVarPath = `/v1/country-regions/user-country-region`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SetUserCountryRegionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userRequest !== undefined ? userRequest : {}) : (userRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryRegionsApi - functional programming interface
 * @export
 */
export const CountryRegionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of country regions sorted by Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountryRegionsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryRegionListResponse> {
            const localVarAxiosArgs = CountryRegionsApiAxiosParamCreator(configuration).v1CountryRegionsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets user's country region.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountryRegionsUserCountryRegionGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCountryRegionResponse> {
            const localVarAxiosArgs = CountryRegionsApiAxiosParamCreator(configuration).v1CountryRegionsUserCountryRegionGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets user's countrt region.
         * @param {SetUserCountryRegionRequest} userRequest Country ID that needs to be set for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountryRegionsUserCountryRegionPatch(userRequest: SetUserCountryRegionRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse> {
            const localVarAxiosArgs = CountryRegionsApiAxiosParamCreator(configuration).v1CountryRegionsUserCountryRegionPatch(userRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CountryRegionsApi - factory interface
 * @export
 */
export const CountryRegionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get list of country regions sorted by Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountryRegionsGet(options?: any) {
            return CountryRegionsApiFp(configuration).v1CountryRegionsGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets user's country region.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountryRegionsUserCountryRegionGet(options?: any) {
            return CountryRegionsApiFp(configuration).v1CountryRegionsUserCountryRegionGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Sets user's countrt region.
         * @param {SetUserCountryRegionRequest} userRequest Country ID that needs to be set for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1CountryRegionsUserCountryRegionPatch(userRequest: SetUserCountryRegionRequest, options?: any) {
            return CountryRegionsApiFp(configuration).v1CountryRegionsUserCountryRegionPatch(userRequest, options)(axios, basePath);
        },
    };
};

/**
 * CountryRegionsApi - interface
 * @export
 * @interface CountryRegionsApi
 */
export interface CountryRegionsApiInterface {
    /**
     * 
     * @summary Get list of country regions sorted by Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryRegionsApiInterface
     */
    v1CountryRegionsGet(options?: any): AxiosPromise<CountryRegionListResponse>;

    /**
     * 
     * @summary Gets user's country region.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryRegionsApiInterface
     */
    v1CountryRegionsUserCountryRegionGet(options?: any): AxiosPromise<UserCountryRegionResponse>;

    /**
     * 
     * @summary Sets user's countrt region.
     * @param {SetUserCountryRegionRequest} userRequest Country ID that needs to be set for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryRegionsApiInterface
     */
    v1CountryRegionsUserCountryRegionPatch(userRequest: SetUserCountryRegionRequest, options?: any): AxiosPromise<SuccessResponse>;

}

/**
 * CountryRegionsApi - object-oriented interface
 * @export
 * @class CountryRegionsApi
 * @extends {BaseAPI}
 */
export class CountryRegionsApi extends BaseAPI implements CountryRegionsApiInterface {
    /**
     * 
     * @summary Get list of country regions sorted by Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryRegionsApi
     */
    public v1CountryRegionsGet(options?: any) {
        return CountryRegionsApiFp(this.configuration).v1CountryRegionsGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets user's country region.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryRegionsApi
     */
    public v1CountryRegionsUserCountryRegionGet(options?: any) {
        return CountryRegionsApiFp(this.configuration).v1CountryRegionsUserCountryRegionGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Sets user's countrt region.
     * @param {SetUserCountryRegionRequest} userRequest Country ID that needs to be set for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryRegionsApi
     */
    public v1CountryRegionsUserCountryRegionPatch(userRequest: SetUserCountryRegionRequest, options?: any) {
        return CountryRegionsApiFp(this.configuration).v1CountryRegionsUserCountryRegionPatch(userRequest, options)(this.axios, this.basePath);
    }

}


/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the list of languages available on Roblox.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LanguagesGet(options: any = {}): RequestArgs {
            const localVarPath = `/v1/languages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of languages available on Roblox for user generated content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LanguagesUserGeneratedContentGet(options: any = {}): RequestArgs {
            const localVarPath = `/v1/languages/user-generated-content`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the list of languages available on Roblox.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LanguagesGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiArrayResponseLanguage> {
            const localVarAxiosArgs = LanguageApiAxiosParamCreator(configuration).v1LanguagesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the list of languages available on Roblox for user generated content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LanguagesUserGeneratedContentGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiArrayResponseLanguage> {
            const localVarAxiosArgs = LanguageApiAxiosParamCreator(configuration).v1LanguagesUserGeneratedContentGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets the list of languages available on Roblox.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LanguagesGet(options?: any) {
            return LanguageApiFp(configuration).v1LanguagesGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets the list of languages available on Roblox for user generated content.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LanguagesUserGeneratedContentGet(options?: any) {
            return LanguageApiFp(configuration).v1LanguagesUserGeneratedContentGet(options)(axios, basePath);
        },
    };
};

/**
 * LanguageApi - interface
 * @export
 * @interface LanguageApi
 */
export interface LanguageApiInterface {
    /**
     * 
     * @summary Gets the list of languages available on Roblox.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApiInterface
     */
    v1LanguagesGet(options?: any): AxiosPromise<ApiArrayResponseLanguage>;

    /**
     * 
     * @summary Gets the list of languages available on Roblox for user generated content.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApiInterface
     */
    v1LanguagesUserGeneratedContentGet(options?: any): AxiosPromise<ApiArrayResponseLanguage>;

}

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI implements LanguageApiInterface {
    /**
     * 
     * @summary Gets the list of languages available on Roblox.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public v1LanguagesGet(options?: any) {
        return LanguageApiFp(this.configuration).v1LanguagesGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets the list of languages available on Roblox for user generated content.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public v1LanguagesUserGeneratedContentGet(options?: any) {
        return LanguageApiFp(this.configuration).v1LanguagesUserGeneratedContentGet(options)(this.axios, this.basePath);
    }

}


/**
 * LocaleApi - axios parameter creator
 * @export
 */
export const LocaleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of Supported locales with user locus information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesGet(options: any = {}): RequestArgs {
            const localVarPath = `/v1/locales`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets user's supported locale.  Null supported locale will clear out user's supported locale (set users' supported locale to null)
         * @param {SetSupportedLocaleForUserRequest} userRequest Supported locale code that needs to be set for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesSetUserSupportedLocalePost(userRequest: SetSupportedLocaleForUserRequest, options: any = {}): RequestArgs {
            // verify required parameter 'userRequest' is not null or undefined
            if (userRequest === null || userRequest === undefined) {
                throw new RequiredError('userRequest','Required parameter userRequest was null or undefined when calling v1LocalesSetUserSupportedLocalePost.');
            }
            const localVarPath = `/v1/locales/set-user-supported-locale`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"SetSupportedLocaleForUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userRequest !== undefined ? userRequest : {}) : (userRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of supported locales sorted by the Native Name property.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesSupportedLocalesGet(options: any = {}): RequestArgs {
            const localVarPath = `/v1/locales/supported-locales`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets user locale. If user is absent returns, locale from http request object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesUserLocaleGet(options: any = {}): RequestArgs {
            const localVarPath = `/v1/locales/user-locale`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets each of a user's localization locus supported locales. A localization locus supported locale is a page (or group of pages) that  have been defined by the International team which need independent locale support.  If the user is null we will attempt to return the locales appropriate for the user's device language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesUserLocalizationLocusSupportedLocalesGet(options: any = {}): RequestArgs {
            const localVarPath = `/v1/locales/user-localization-locus-supported-locales`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocaleApi - functional programming interface
 * @export
 */
export const LocaleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of Supported locales with user locus information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiArrayResponseSupportedLocaleLocus> {
            const localVarAxiosArgs = LocaleApiAxiosParamCreator(configuration).v1LocalesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets user's supported locale.  Null supported locale will clear out user's supported locale (set users' supported locale to null)
         * @param {SetSupportedLocaleForUserRequest} userRequest Supported locale code that needs to be set for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesSetUserSupportedLocalePost(userRequest: SetSupportedLocaleForUserRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse> {
            const localVarAxiosArgs = LocaleApiAxiosParamCreator(configuration).v1LocalesSetUserSupportedLocalePost(userRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of supported locales sorted by the Native Name property.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesSupportedLocalesGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportedLocalesResponse> {
            const localVarAxiosArgs = LocaleApiAxiosParamCreator(configuration).v1LocalesSupportedLocalesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets user locale. If user is absent returns, locale from http request object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesUserLocaleGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLocaleResponse> {
            const localVarAxiosArgs = LocaleApiAxiosParamCreator(configuration).v1LocalesUserLocaleGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets each of a user's localization locus supported locales. A localization locus supported locale is a page (or group of pages) that  have been defined by the International team which need independent locale support.  If the user is null we will attempt to return the locales appropriate for the user's device language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesUserLocalizationLocusSupportedLocalesGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLocalizationLocusLocalesResponse> {
            const localVarAxiosArgs = LocaleApiAxiosParamCreator(configuration).v1LocalesUserLocalizationLocusSupportedLocalesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LocaleApi - factory interface
 * @export
 */
export const LocaleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get list of Supported locales with user locus information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesGet(options?: any) {
            return LocaleApiFp(configuration).v1LocalesGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Sets user's supported locale.  Null supported locale will clear out user's supported locale (set users' supported locale to null)
         * @param {SetSupportedLocaleForUserRequest} userRequest Supported locale code that needs to be set for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesSetUserSupportedLocalePost(userRequest: SetSupportedLocaleForUserRequest, options?: any) {
            return LocaleApiFp(configuration).v1LocalesSetUserSupportedLocalePost(userRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of supported locales sorted by the Native Name property.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesSupportedLocalesGet(options?: any) {
            return LocaleApiFp(configuration).v1LocalesSupportedLocalesGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets user locale. If user is absent returns, locale from http request object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesUserLocaleGet(options?: any) {
            return LocaleApiFp(configuration).v1LocalesUserLocaleGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets each of a user's localization locus supported locales. A localization locus supported locale is a page (or group of pages) that  have been defined by the International team which need independent locale support.  If the user is null we will attempt to return the locales appropriate for the user's device language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1LocalesUserLocalizationLocusSupportedLocalesGet(options?: any) {
            return LocaleApiFp(configuration).v1LocalesUserLocalizationLocusSupportedLocalesGet(options)(axios, basePath);
        },
    };
};

/**
 * LocaleApi - interface
 * @export
 * @interface LocaleApi
 */
export interface LocaleApiInterface {
    /**
     * 
     * @summary Get list of Supported locales with user locus information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApiInterface
     */
    v1LocalesGet(options?: any): AxiosPromise<ApiArrayResponseSupportedLocaleLocus>;

    /**
     * 
     * @summary Sets user's supported locale.  Null supported locale will clear out user's supported locale (set users' supported locale to null)
     * @param {SetSupportedLocaleForUserRequest} userRequest Supported locale code that needs to be set for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApiInterface
     */
    v1LocalesSetUserSupportedLocalePost(userRequest: SetSupportedLocaleForUserRequest, options?: any): AxiosPromise<SuccessResponse>;

    /**
     * 
     * @summary Get list of supported locales sorted by the Native Name property.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApiInterface
     */
    v1LocalesSupportedLocalesGet(options?: any): AxiosPromise<SupportedLocalesResponse>;

    /**
     * 
     * @summary Gets user locale. If user is absent returns, locale from http request object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApiInterface
     */
    v1LocalesUserLocaleGet(options?: any): AxiosPromise<UserLocaleResponse>;

    /**
     * 
     * @summary Gets each of a user's localization locus supported locales. A localization locus supported locale is a page (or group of pages) that  have been defined by the International team which need independent locale support.  If the user is null we will attempt to return the locales appropriate for the user's device language.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApiInterface
     */
    v1LocalesUserLocalizationLocusSupportedLocalesGet(options?: any): AxiosPromise<UserLocalizationLocusLocalesResponse>;

}

/**
 * LocaleApi - object-oriented interface
 * @export
 * @class LocaleApi
 * @extends {BaseAPI}
 */
export class LocaleApi extends BaseAPI implements LocaleApiInterface {
    /**
     * 
     * @summary Get list of Supported locales with user locus information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public v1LocalesGet(options?: any) {
        return LocaleApiFp(this.configuration).v1LocalesGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Sets user's supported locale.  Null supported locale will clear out user's supported locale (set users' supported locale to null)
     * @param {SetSupportedLocaleForUserRequest} userRequest Supported locale code that needs to be set for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public v1LocalesSetUserSupportedLocalePost(userRequest: SetSupportedLocaleForUserRequest, options?: any) {
        return LocaleApiFp(this.configuration).v1LocalesSetUserSupportedLocalePost(userRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of supported locales sorted by the Native Name property.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public v1LocalesSupportedLocalesGet(options?: any) {
        return LocaleApiFp(this.configuration).v1LocalesSupportedLocalesGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets user locale. If user is absent returns, locale from http request object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public v1LocalesUserLocaleGet(options?: any) {
        return LocaleApiFp(this.configuration).v1LocalesUserLocaleGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets each of a user's localization locus supported locales. A localization locus supported locale is a page (or group of pages) that  have been defined by the International team which need independent locale support.  If the user is null we will attempt to return the locales appropriate for the user's device language.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocaleApi
     */
    public v1LocalesUserLocalizationLocusSupportedLocalesGet(options?: any) {
        return LocaleApiFp(this.configuration).v1LocalesUserLocalizationLocusSupportedLocalesGet(options)(this.axios, this.basePath);
    }

}


